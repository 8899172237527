import React, { useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// Variables
import { lineChartOptionsOverview } from 'variables/charts';
import fieldTypes from '../../../../variables/fieldTypes';
// Utils
import { format, parseISO } from 'date-fns';
import { formatNumberCharts, formatNumber } from 'utils/dataFormatter';
import { aggregateDataByColumnV2 } from 'utils/dataFunctions';
import { Box, Checkbox, useColorModeValue, Text, Flex } from '@chakra-ui/react';
import EditableText from 'components/text/EditableText';

const LineChart = ({ XAxisField, YAxisField, displayData }) => {
    const [isLabelShown, setIsLabelShown] = useState(false);
    const backgroundColor = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const labelColor = useColorModeValue("gray", "#A3AED0");

    const aggregatedData = useMemo(() => {
        const aggregatedData = aggregateDataByColumnV2(displayData, XAxisField, [YAxisField], 'sum');
        
        const sortedData = aggregatedData.sort((a, b) => new Date(a[XAxisField]) - new Date(b[XAxisField]));
      
        return sortedData;
    }, [displayData, XAxisField, YAxisField]);

    const xAxisCategories = aggregatedData
        .filter(item => item[XAxisField] !== undefined && item[YAxisField] !== undefined);
  
    const yAxisData = aggregatedData
        .filter(item => item[XAxisField] !== undefined && item[YAxisField] !== undefined)
        .map(item => item[YAxisField]);

    return (
        <Box direction="column" flex="1" overflow="scroll" borderRadius="20px" boxShadow="md" backgroundColor={backgroundColor} p={5}>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={4}
            >
                <EditableText defaultValue={`${XAxisField.replace(/_/g, ' ').toUpperCase()} x ${YAxisField.replace(/_/g, ' ').toUpperCase()}`} />
                <Checkbox
                    isChecked={isLabelShown}
                    onChange={() => setIsLabelShown(!isLabelShown)}
                    mr={3}
                    sx={{
                        '& .chakra-checkbox__control': {
                          borderColor: 'gray.400',
                          _checked: {
                            bg: '#00E5D1',
                            borderColor: '#00E5D1',
                            color: 'black',
                          },
                        },
                        '&:hover .chakra-checkbox__control': {
                          borderColor: '#00E5D1',
                        },
                      }}
                >
                    <Text fontSize="sm">Show label</Text>
                </Checkbox>
            </Flex>
            <ReactApexChart
                type="line"
                series={[
                    {
                        name: YAxisField,
                        data: yAxisData
                    },
                ]}
                options={{
                    ...lineChartOptionsOverview,
                    chart: {
                        ...lineChartOptionsOverview.chart,
                        id: 'line-chart'
                    },
                    xaxis: {
                        categories: xAxisCategories
                            .map(item => {
                                const xValue = item[XAxisField];
                                if (xValue && xValue !== 'undefined' && fieldTypes[XAxisField] === 'date') {
                                    return format(parseISO(xValue), 'MM/dd/yy');
                                }
                                if (fieldTypes[XAxisField] === 'money') {
                                    return formatNumberCharts(xValue, 'dollar', true);
                                }
                                if (fieldTypes[XAxisField] === 'number') {
                                    return formatNumberCharts(xValue, 'integer', true);
                                }
                                return xValue;
                            }),
                        title: {
                            text: XAxisField,
                            style: {
                                color: textColor,
                                fontSize: "12px",
                                fontWeight: "bold",
                            }
                        },
                        labels: {
                            style: {
                                colors: labelColor,
                                fontSize: '12px',
                                fontWeight: '500',
                                rotate: -45,
                            },
                            rotateAlways: true,
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: labelColor,
                                fontSize: "12px",
                            },
                            formatter: (yValue) => {
                                if (yValue && fieldTypes[YAxisField] === 'date') {
                                    return format(parseISO(yValue), 'MM/dd/yy');
                                }
                                if (fieldTypes[YAxisField] === 'money') {
                                    return formatNumberCharts(yValue, 'dollar', true);
                                }
                                if (fieldTypes[YAxisField] === 'number') {
                                    return formatNumberCharts(yValue, 'integer', true);
                                }
                                return yValue;
                            }
                        },
                        title: {
                            text: YAxisField,
                            style: {
                                color: textColor,
                                fontSize: "12px",
                                fontWeight: "bold",
                            },
                            offsetX: 5
                        }
                    },
                    stroke: {
                        curve: 'smooth',
                    },
                    markers: {
                        size: 5,
                    },
                    dataLabels: {
                        enabled: isLabelShown,
                        style: {
                            colors: ['gray'],
                            fontSize: '12px',
                        },
                        offsetY: -10,
                        formatter: (value) => {
                            if (fieldTypes[YAxisField] === 'money') {
                                return formatNumber(value, 'dollar', true);
                            }
                            if (fieldTypes[YAxisField] === 'number') {
                                return formatNumber(value, 'integer', true);
                            }
                            return value;
                        }
                    },
                    tooltip: {
                        theme: 'dark'
                    },
                }}
                height={350}
            />
        </Box>
    );
};

export default LineChart;
