import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { useDateFormat } from 'contexts/DateFormatContext';
import { useLocation } from 'contexts/LocationContext';
import { useAuth } from 'AuthContext';
import { Table, Text, Thead, Tbody, Tr, Th, Td, Box, useColorModeValue, Flex, Button } from '@chakra-ui/react';
import { FaDownload } from 'react-icons/fa';
import { formatNumber } from 'utils/dataFormatter';
import { aggregateDataByDate } from 'utils/dataFunctions';
import fieldTypes from 'variables/fieldTypes';
import DateFormatDropdown from "components/filters/DateFormatDropdown";
import EditableText from 'components/text/EditableText';

const LightningTable = ({ selectedFields = [], displayData = [], dataset }) => {
    const { dateFormat } = useDateFormat();
    const { localLocation } = useLocation();
    const { company } = useAuth();

    const finalData = useMemo(() => {
        const aggregatedData = aggregateDataByDate(displayData, dateFormat, selectedFields, 'sum');
      
        const parseDate = (date) => {
          switch (dateFormat) {
            case 'week':
                const startDate = date.split(' - ')[0].split(', ')[1];
                return new Date(startDate + ', ' + new Date().getFullYear());
            case 'month':
                return new Date(date.replace(',', ''));
            default:
                return new Date(date);
          }
        };
      
        const sortedData = aggregatedData.sort((a, b) => parseDate(b.date) - parseDate(a.date));
    
        return sortedData;
    }, [displayData, dateFormat, selectedFields]);

    const backgroundColor = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const columns = useMemo(() => {
        const dateColumn = {
            Header: 'DATE',
            accessor: 'date',
        };
    
        const otherColumns = selectedFields
            .filter((key) => key !== 'date')
            .map((key) => ({
                Header: key.replace(/_/g, ' ').toUpperCase(),
                accessor: key,
                Cell: ({ value }) => {
                    if (fieldTypes[key] === 'money') {
                        return value ? formatNumber(value, 'dollar', true) : '-';
                    } else if (fieldTypes[key] === 'number') {
                        return value ? formatNumber(value, 'integer', true) : '-';
                    }
                    return value || '-';
                },
            }));
    
        return [dateColumn, ...otherColumns];
    }, [selectedFields]);

    const exportToCSV = () => {
        const headerRow = columns.map(column => column.Header).join(",");
    
        const dataRows = rows.map(row => 
            row.cells.map(cell => {
                const cellValue = cell.value || '';
                return typeof cellValue === 'string' && (cellValue.includes(',') || cellValue.includes('\n')) 
                    ? `"${cellValue}"` 
                    : cellValue;
            }).join(",")
        ).join("\n");
    
        const csvData = `${headerRow}\n${dataRows}`;
    
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute(
            'download',
            `XEUS__lightning__${company.replaceAll(' ', '_')}__${localLocation.replaceAll(' ', '_')}__${dataset.replaceAll(' ', '_')}.csv`
        );
        link.click();
    };    

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data: finalData,
        });

    return (
        <Box direction="column" width="100%" borderRadius="20px" boxShadow="md" backgroundColor={backgroundColor} p={5}>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={4}
            >
                <EditableText defaultValue={company.toUpperCase()} width={"60%"}/>
                <Flex direction={{ base: "column", md: "row" }} align="center">
                    <Button
                        onClick={exportToCSV}
                        leftIcon={<FaDownload />}
                        backgroundColor="transparent"
                        color="#00E5D1"
                        fontSize="sm"
                        _hover={{
                            color: "white",
                        }}
                    >
                        Download as CSV
                    </Button>
                    <DateFormatDropdown />
                </Flex>
            </Flex>
            <Box overflow="scroll" width='100%'>
                <Table {...getTableProps()} variant='simple' color='gray.500'>
                    <Thead>
                        {headerGroups.map((headerGroup) => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <Th
                                        {...column.getHeaderProps()}
                                        sx={
                                            index === 0
                                                ? {
                                                    position: "sticky",
                                                    left: 0,
                                                    zIndex: 1,
                                                    backgroundColor
                                                }
                                                : {}
                                        }
                                        borderBottom="1px solid"
                                        borderBottomColor="secondaryGray.800"
                                    >
                                        <Text
                                            fontSize={{ sm: "12px", lg: "14px" }}
                                            textAlign={index === 0 ? 'left' : 'center'}
                                            color='gray.400'
                                            w='100%'
                                        >
                                            {column.render('Header')}
                                        </Text>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => (
                                        <Td
                                            {...cell.getCellProps()}
                                            borderColor='transparent'
                                            sx={
                                                index === 0
                                                    ? {
                                                        position: "sticky",
                                                        left: 0,
                                                        zIndex: 1,
                                                        backgroundColor
                                                    }
                                                    : {}
                                            }
                                        >
                                            <Text
                                                color={textColor}
                                                fontSize='md'
                                                fontWeight='400'
                                                textAlign={index === 0 ? 'left' : 'center'}
                                                w='100%'
                                            >
                                                {cell.render('Cell')}
                                            </Text>
                                        </Td>
                                    ))}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
};

export default LightningTable;
