// map.jsx
import { Spinner, Flex, useToast } from "@chakra-ui/react";
import { useDateRange } from "contexts/DateRangeContext";
import { useLocation } from "contexts/LocationContext";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useFilteredData } from "utils/dataHooks";
import { getGeolocationByLocation, stateGeoJSONMap } from "utils/MapsUtils";
import { MapContainer, TileLayer, GeoJSON, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import markerIconBlue1 from "../../../assets/img/marker/blue-1.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { formatNumber } from "utils/dataFormatter";
import locationDetails from "variables/locationDetails";

const DefaultIcon = L.icon({
  iconUrl: markerIconBlue1,
  shadowUrl: markerShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const Default = () => {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_location_insights", location, dateRange);
  const [currentLocationCoordinates, setCurrentLocationCoordinates] = useState(null);
  const [geoJSONData, setGeoJSONData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const geoJSONCache = useRef({}); // Cache stored in a ref to persist between renders

  useEffect(() => {
    const getCurrentLocationCoordinates = async () => {
      if (!location) return;

      try {
        const locationDetail = locationDetails.filter((detail) => detail.City === location);
        const { Latitude = null, Longitude = null } = locationDetail.length > 0 ? locationDetail[0] : {};
        setCurrentLocationCoordinates([Latitude, Longitude]);
        console.log('xx', [Latitude, Longitude])
      } catch (error) {
        console.error(error);
        toast({
          title: 'Something went wrong',
          description: 'Please reload the page',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top'
        });
      }
    };

    getCurrentLocationCoordinates();
  }, [location, toast]);

  useEffect(() => {
    const fetchGeoJSONData = async () => {
      if (!currentPeriodData) return;

      setIsLoading(true);
      const dataWithZipCodeAndState = currentPeriodData.filter(data => data.zipcode && data.state);

      const groupedData = dataWithZipCodeAndState.reduce((acc, item) => {
        const existingEntry = acc.find(entry => entry.zipcode === item.zipcode);
        if (existingEntry) {
          Object.keys(existingEntry).forEach(key => {
            if (key.startsWith("tk_")) {
              existingEntry[key] += item[key];
            }
          });
        } else {
          acc.push({ ...item });
        }
        return acc;
      }, []);

      const stateZipCodeMap = groupedData.reduce((acc, { state, zipcode }) => {
        acc[state] = acc[state] || [];
        acc[state].push(zipcode);
        return acc;
      }, {});

      const fetchPromises = Object.entries(stateZipCodeMap).map(async ([state, zipcodes]) => {
        // Check if data for this state is in the cache
        if (geoJSONCache.current[state]) {
          return geoJSONCache.current[state].features.filter(feature => {
            const zipcode = feature.properties.ZCTA5CE10 || feature.properties.ZIPCODE;
            return zipcodes.includes(zipcode);
          }).map(feature => {
            const matchedData = groupedData.find(item => item.zipcode === (feature.properties.ZCTA5CE10 || feature.properties.ZIPCODE));
            return {
              ...feature,
              properties: {
                ...feature.properties,
                ...matchedData,
              }
            };
          });
        }

        const url = stateGeoJSONMap[state.toUpperCase()];
        if (!url) {
          console.log(`No GeoJSON file mapped for state: ${state}`);
          return null;
        }

        const response = await fetch(url);
        if (!response.ok) {
          console.log(`Failed to fetch GeoJSON for state: ${state}`);
          return null;
        }

        const data = await response.json();
        // Store fetched data in the cache
        geoJSONCache.current[state] = data;
        return data.features.filter(feature => {
          const zipcode = feature.properties.ZCTA5CE10 || feature.properties.ZIPCODE;
          return zipcodes.includes(zipcode);
        }).map(feature => {
          const matchedData = groupedData.find(item => item.zipcode === (feature.properties.ZCTA5CE10 || feature.properties.ZIPCODE));
          return {
            ...feature,
            properties: {
              ...feature.properties,
              ...matchedData,
            }
          };
        });
      });

      const geoJSONDataArray = await Promise.all(fetchPromises);
      setGeoJSONData({ type: 'FeatureCollection', features: geoJSONDataArray.flat().filter(Boolean) });
      setIsLoading(false);
    };

    fetchGeoJSONData();
  }, [currentPeriodData]);

  const maxNetRevenue = useMemo(() => {
    if (!geoJSONData || !geoJSONData.features || !geoJSONData.features.length) return null;
    const netRevenues = geoJSONData.features.map(feature => feature.properties.tk_ticket_net_revenues || 0);
    return Math.max(...netRevenues);
  }, [geoJSONData]);

  const colors = ["#A3ADFF", "#5158FF", "#3802D0", "#1C008C", "#13005D" , "#FFCBAE", "#FF9F81", "#FF7354", "#F2330A"];
  const getPolygonColor = (netRevenue) => {
    if (netRevenue <= maxNetRevenue * 0.075) return colors[0];
    if (netRevenue <= maxNetRevenue * 0.1) return colors[1];
    if (netRevenue <= maxNetRevenue * 0.15) return colors[2];
    if (netRevenue <= maxNetRevenue * 0.35) return colors[5];
    if (netRevenue <= maxNetRevenue * 0.5) return colors[6];
    if (netRevenue <= maxNetRevenue * 0.7) return colors[7];
    return colors[8];
  };

  const onEachFeature = (feature, layer) => {
    layer.setStyle({
      fillColor: getPolygonColor(feature.properties.tk_ticket_net_revenues),
      weight: 1,
      opacity: 1,
      color: 'gray',
      fillOpacity: 0.7,
    });

    const tooltipContent = `
      <strong>${feature.properties.zipcode} (${feature.properties.city}, ${feature.properties.state.toUpperCase()})</strong><br>
      <strong>Total Orders:</strong> ${feature.properties.tk_total_orders}<br>
      <strong>Total Tickets:</strong> ${feature.properties.tk_total_tickets}<br>
      <strong>Gross Revenues:</strong> ${formatNumber(feature.properties.tk_ticket_gross_revenues, 'dollar', true)}<br>
      <strong>Net Revenues:</strong> ${formatNumber(feature.properties.tk_ticket_net_revenues, 'dollar', true)}
    `;

    layer.on({
      mouseover: (e) => {
        layer.bindTooltip(tooltipContent, {
          permanent: false,
          direction: 'top',
          offset: [0, -10],
        }).openTooltip();
        e.target.setStyle({ weight: 3 });
      },
      mouseout: (e) => {
        layer.closeTooltip();
        e.target.setStyle({ weight: 1 });
      }
    });
  };

  return (
    <Flex direction="column" align="center" justify="center" height="100vh">
      {isLoading ? (
        <Spinner size="xl" />
      ) : (
        <MapContainer center={currentLocationCoordinates} zoom={10} style={{ height: "80%", width: "100%", zIndex: 0 }}>
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {geoJSONData && <GeoJSON data={geoJSONData} onEachFeature={onEachFeature} />}
          {currentLocationCoordinates && <Marker position={currentLocationCoordinates} />}
        </MapContainer>
      )}
    </Flex>
  );
};

export default Default;
