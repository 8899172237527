import { Box, Button, Flex, Icon, Select, SimpleGrid, Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { HSeparator } from "components/separator/Separator";
import { useState } from "react";
import { FaMeta, FaTicket } from "react-icons/fa6";
import { MdOutlineCollections, MdApps, MdList } from "react-icons/md";
import { SiGoogleads } from "react-icons/si";
import SummaryGridItem from "./SummaryGridItem";
import SummaryListItem from "./SummaryListItem";

const LocationDetails = (props) => {
  const { data, setSelectedLocation } = props;
  const [tabState, setTabState] = useState("ticketing");
  const [sortOrder, setSortOrder] = useState("newest");
  const [viewMode, setViewMode] = useState("list");

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const buttonBg = useColorModeValue("transparent", "navy.800");
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  const sortSummaries = (summary) => {
    let sortedSummaries = [...summary];

    sortedSummaries.sort((a, b) => {
        // Sort by dateAdded first
        let dateComparison = new Date(b.dateAdded) - new Date(a.dateAdded);
        if (sortOrder === "oldest") {
            dateComparison = new Date(a.dateAdded) - new Date(b.dateAdded);
        }

        return dateComparison;
    });

    return sortedSummaries;
  };

  const renderSummaryGrids = () => {
    const summaries = sortSummaries(data[tabState]);
  
    return summaries.map((summary, index) => (
      <SummaryGridItem
        key={index}
        tabKey={summary.tabKey}
        clientFilter={summary.clientFilter}
        locationFilter={summary.locationFilter}
        currentDateRangeFilter={summary.currentDateRangeFilter}
        currentDateRange={summary.currentDateRange}
        dateAdded={summary.dateAdded}
      />
    ));
  };

  const renderSummaryList = () => {
    const summaries = sortSummaries(data[tabState]);
    return summaries.map((summary, index) => (
      <SummaryListItem
        key={index}
        tabKey={summary.tabKey}
        clientFilter={summary.clientFilter}
        locationFilter={summary.locationFilter}
        currentDateRangeFilter={summary.currentDateRangeFilter}
        currentDateRange={summary.currentDateRange}
        dateAdded={summary.dateAdded}
      />
    ));

  };
  
  const iconMapping = {
    ticketing: FaTicket,
    meta: FaMeta,
    google: SiGoogleads,
    default: MdOutlineCollections,
  };

  return (
    <Tabs variant='soft-rounded' colorScheme='brandTabs'>
      <Text
        color={textColor}
        fontSize='xl'
        fontWeight='500'
        cursor='pointer'
        onClick={() => setSelectedLocation(null)}
        me='20px'
        mt='20px'
        _hover={{ color: '#5158FF' }}
      >
        &lt; All Locations
      </Text>
      <TabList mx={{ base: "10px", lg: "30px" }} overflowX={{ sm: "scroll", lg: "unset" }}>
        <Flex justify={{ base: "start", md: "center" }} w='100%'>
          {Object.keys(data).map((key) => {
            const IconComponent = iconMapping[key] || iconMapping.default;
            
            return (
              <Tab
                key={key}
                pb='0px'
                flexDirection='column'
                onClick={() => setTabState(key)}
                me='50px'
                bg='unset'
                _selected={{ bg: "none" }}
                _focus={{ border: "none" }}
                minW='max-content'>
                <Flex align='center'>
                  <Icon color={textColor} as={IconComponent} w='20px' h='20px' me='8px' />
                  <Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Text>
                  <Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
                    {data[key].length}
                  </Text>
                </Flex>
                <Box
                  height='4px'
                  w='100%'
                  transition='0.1s linear'
                  bg={tabState === key ? "brand.500" : "transparent"}
                  mt='15px'
                  borderRadius='20px'
                />
              </Tab>
            );
          })}
        </Flex>
      </TabList>
      <HSeparator mb='30px' bg={paleGray} mt='0px' />
      <Flex w='100%' mb='25px'>
      <Select
          fontSize='sm'
          variant='main'
          h='44px'
          maxh='44px'
          me='20px'
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value='newest'>Newest to Oldest</option>
          <option value='oldest'>Oldest to Newest</option>
        </Select>
        <Button
          bg={viewMode === "list" ? '#5158FF' : buttonBg}
          border='1px solid'
          color='secondaryGray.600'
          borderColor={useColorModeValue("secondaryGray.100", "whiteAlpha.100")}
          borderRadius='16px'
          _placeholder={{ color: "secondaryGray.600" }}
          _hover={{bg: '#4B21F3' }}
          _active={{bg: '#5158FF'}}
          _focus={{bg: '#5158FF'}}
          onClick={() => setViewMode("list")}
          me='20px'
        >
          <Icon color={viewMode === "list" ? 'white' : textColor} as={MdList} />
        </Button>
        <Button
          bg={viewMode === "grid" ? '#5158FF' : buttonBg}
          border='1px solid'
          color='secondaryGray.600'
          borderColor={useColorModeValue("secondaryGray.100", "whiteAlpha.100")}
          borderRadius='16px'
          _placeholder={{ color: "secondaryGray.600" }}
          _hover={{bg: '#4B21F3'}}
          _active={{bg: '#5158FF'}}
          _focus={{bg: '#5158FF'}}
          onClick={() => setViewMode("grid")}
        >
          <Icon color={viewMode === "grid" ? 'white' : textColor} as={MdApps} />
        </Button>
      </Flex>
      <Text mt='25px' mb='15px' color={textColor} fontSize='xl' fontWeight='700'>
        {data[tabState].length} Results
      </Text>
      <Text mb='15px' color={textColor} fontSize='3xl' fontWeight='700'>
        {data[tabState][0].locationFilter}
      </Text>
      <SimpleGrid columns={{ sm: 1, md: viewMode === "grid" ? 2 : 1, xl: viewMode === "grid" ? 4 : 1 }} spacing='24px' ms='20px'>
        {
          viewMode === 'grid'
          ? renderSummaryGrids()
          : renderSummaryList()
        }
      </SimpleGrid>
    </Tabs>
  );
};

export default LocationDetails;