// OverviewTableViz.js
// React / Chakra Imports
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,

} from "@chakra-ui/react";
import React, { useMemo, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
// Components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
import DateFormatDropdown from "components/filters/DateFormatDropdown";
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useDateFormat } from "contexts/DateFormatContext";
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, aggregateDataByDate } from 'utils/dataFunctions';
import { formatNumber } from "utils/dataFormatter";
// Motion
import { motion } from "framer-motion";
const MotionFlex = motion(Flex);

export default function TableViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { dateFormat } = useDateFormat();
  const { currentPeriodData } = useFilteredData("overview", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const tableStickyColumnBackgroundColor = useColorModeValue("white", "navy.800");

  const columnsToKeep = useMemo(() => ['period', 'date', 'tk_ticket_gross_revenues', 'tk_ticket_net_revenues', 'tk_total_tickets', 'tk_total_orders'], []);
  const columnsToSum = useMemo(() => ['tk_ticket_gross_revenues', 'tk_ticket_net_revenues', 'tk_total_tickets', 'tk_total_orders'], []);

  const currentPeriodDataByColumns = useMemo(() => filterColumns(currentPeriodData, columnsToKeep), [currentPeriodData, columnsToKeep]);
  
  // const finalData = useMemo(() => {
  //   const aggregatedData = aggregateDataByDate(currentPeriodDataByColumns, dateFormat, columnsToSum, 'sum');
  //   const sortedData = aggregatedData.sort((a, b) => new Date(b.date) - new Date(a.date));
  //   return sortedData.map(item => ({
  //     ...item,
  //     avg_order_value: item.tk_ticket_gross_revenues && item.tk_total_orders ? (item.tk_ticket_gross_revenues / item.tk_total_orders) : 0,
  //     avg_tickets_per_order: item.tk_tickets && item.tk_total_orders ? (item.tk_tickets / item.tk_total_orders) : 0,
  //   }));
  // }, [currentPeriodDataByColumns, dateFormat, columnsToSum]);

  const finalData = useMemo(() => {
    const aggregatedData = aggregateDataByDate(currentPeriodDataByColumns, dateFormat, columnsToSum, 'sum');
  
    const parseDate = (date) => {
      switch (dateFormat) {
        case 'week':
          // Assuming the date is in the format 'Sunday, July 7 - Saturday, July 13'
          const startDate = date.split(' - ')[0].split(', ')[1]; // Extracting 'July 7'
          return new Date(startDate + ', ' + new Date().getFullYear()); // Add current year
        case 'month':
          // Assuming the date is in the format 'July, 2024'
          return new Date(date.replace(',', ''));
        default:
          return new Date(date);
      }
    };
  
    const sortedData = aggregatedData.sort((a, b) => parseDate(b.date) - parseDate(a.date));
  
    return sortedData.map(item => ({
      ...item,
      avg_order_value: item.tk_ticket_gross_revenues && item.tk_total_orders ? (item.tk_ticket_gross_revenues / item.tk_total_orders) : 0,
      avg_tickets_per_order: item.tk_total_tickets && item.tk_total_orders ? (item.tk_total_tickets / item.tk_total_orders) : 0,
    }));
  }, [currentPeriodDataByColumns, dateFormat, columnsToSum]);
  

  const totals = useMemo(() => {
    const sum = (arr, accessor) => arr.reduce((acc, item) => acc + (item[accessor] ?? 0), 0);
  
    const total_tk_ticket_gross_revenues = sum(finalData, 'tk_ticket_gross_revenues');
    const total_tk_ticket_net_revenues = sum(finalData, 'tk_ticket_net_revenues');
    const total_tk_tickets = sum(finalData, 'tk_total_tickets');
    const total_tk_total_orders = sum(finalData, 'tk_total_orders');
    const total_avg_order_value = total_tk_ticket_gross_revenues / total_tk_total_orders;
    const total_avg_tickets_per_order = total_tk_tickets / total_tk_total_orders;

    return {
      date: 'Total',
      tk_ticket_gross_revenues: total_tk_ticket_gross_revenues,
      tk_ticket_net_revenues: total_tk_ticket_net_revenues,
      tk_total_tickets: total_tk_tickets,
      tk_total_orders: total_tk_total_orders,
      avg_order_value: total_avg_order_value,
      avg_tickets_per_order: total_avg_tickets_per_order,
    };
  }, [finalData]);
  
  const data = useMemo(() => finalData, [finalData]);
  const dataWithTotals = useMemo(() => [totals, ...finalData], [totals, finalData]);

  useEffect(() => {
    console.log("Date Format Changed:", dateFormat);
  }, [dateFormat]);

  const tableColumns = useMemo(() => [
    { Header: "Date", accessor: "date" },
    {
      Header: "Ticket Gross Sales",
      accessor: "tk_ticket_gross_revenues",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Ticket Net Sales",
      accessor: "tk_ticket_net_revenues",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Total Tickets Sold",
      accessor: "tk_total_tickets",
      Cell: ({ value }) => formatNumber(value, 'integer')
    },
    {
      Header: "Total Orders",
      accessor: "tk_total_orders",
      Cell: ({ value }) => formatNumber(value, 'integer')
    },
    {
      Header: "Avg. Order Value",
      accessor: "avg_order_value",
      Cell: ({ value }) => formatNumber(value, 'dollar')
    },
    {
      Header: "Avg. Tickets per Order",
      accessor: "avg_tickets_per_order",
      Cell: ({ value }) => formatNumber(value, 'float')
    },
  ], []);

  const tableInstance = useTable({ columns: tableColumns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <Card mb={{ base: "20px", xl: "0px" }}>
      <MotionFlex direction='column' w='100%' overflowX={{ sm: "scroll" }}  initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Flex align='center' justify='space-between' w='100%' px='10px' mb='20px'>
          <CardHeader mt='10px' mb='10px' ps='22px'>
            <Flex direction='column' alignSelf='flex-start'>
              <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
                Key Metrics
              </Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
								by {dateFormat}
              </Text>
            </Flex>
          </CardHeader>
          <DateFormatDropdown />
        </Flex>
        <Box overflow="scroll" width='100%'>
          <Table {...getTableProps()} variant='simple' color='gray.500'>
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      borderColor='transparent'
                      textAlign='center'
                      sx={
                        index === 0
                          ? {
                              position: "sticky",
                              left: 0,
                              backgroundColor: tableStickyColumnBackgroundColor,
                              zIndex: 1,
                            }
                          : {}
                      }
                      >
                      <Text
                        fontSize={{ sm: "12px", lg: "14px" }}
                        textAlign={index === 0 ? 'left' : 'center'}
                        color='gray.400'
                        w='100%'
                      >
                        {column.render("Header")}
                      </Text>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              <Tr>
                {tableColumns.map((column, index) => (
                  <Td
                    key={index}
                    fontSize={{ sm: "16px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    textAlign={['tk_ticket_gross_revenues', 'tk_ticket_net_revenues', 'tk_total_tickets', 'tk_total_orders', 'avg_order_value', 'avg_tickets_per_order'].includes(column.accessor) ? 'center' : 'left'}
                    borderTop="1.5px solid"
                    borderBottom="1px solid"
                    borderTopColor="secondaryGray.500"
                    borderBottomColor="secondaryGray.800"
                    sx={
                      index === 0
                        ? {
                            position: "sticky",
                            left: 0,
                            backgroundColor: tableStickyColumnBackgroundColor,
                            zIndex: 1,
                          }
                        : {}
                    }
                    >
                    <Text
                      color={textColor}
                      fontSize='md'
                      fontWeight='600'
                      w='100%'
                    >
                      {formatNumber(totals[column.accessor], column.accessor === 'avg_tickets_per_order' ? 'float' : column.accessor === 'tk_total_orders' || column.accessor === 'tk_total_tickets' ? 'integer' : 'dollar')}
                    </Text>
                  </Td>
                  
                ))}
              </Tr>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "16px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'
                        
                        mt='20px !important'
                        py='10px !important'
                        textAlign={['tk_ticket_gross_revenues', 'tk_ticket_net_revenues', 'tk_total_tickets', 'tk_total_orders', 'avg_order_value', 'avg_tickets_per_order'].includes(cell.column.id) ? 'center' : 'left'}
                        sx={
                          index === 0
                            ? {
                                position: "sticky",
                                left: 0,
                                backgroundColor: tableStickyColumnBackgroundColor,
                                zIndex: 1,
                              }
                            : {}
                        }
                        >
                        <Text
                          color={textColor}
                          fontSize='md'
                          fontWeight='400'
                          w='100%'
                        >
                          {cell.render('Cell')}
                        </Text>
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </MotionFlex>
    </Card>
  );
}
