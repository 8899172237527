// // Chakra / React imports
import React from "react";
import { Box, Flex, Grid, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { TicketGrossSales, TicketNetSales, TotalTicketsSold, TotalOrders, AvgOrderValue, AvgTicketsPerOrder } from "./components/insights/InsightsHighLevelMetrics";
import TicketsInsightsTreeMapChartViz from "./components/insights/InsightsTreeMapChartViz";
import TicketsInsightsColumnChartViz1 from "./components/insights/InsightsColumnChartViz1";
import TicketsInsightsColumnChartViz2 from "./components/insights/InsightsColumnChartViz2";
import TicketsInsightsHeatMapChartViz from "./components/insights/InsightsHeatMapChartViz";

// Custom Functions
import { DateFormatProvider } from "contexts/DateFormatContext";

// import { tableColumnsMostVisited } from "views/admin/dashboards/default/variables/tableColumnsMostVisited";
// import tableDataMostVisited from "views/admin/dashboards/default/variables/tableDataMostVisited.json";

export default function Default() {
  // Chakra Color Mode
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Flex
      direction={{ base: "column", xl: "row" }}
      pt={{ base: "50px", md: "80px", xl: "80px" }}
      width="100%" // Ensuring the container takes the ful/l widths
      mx="auto" // Centering the container horizontally
    >
      <Flex direction='column' width='stretch' >
        <Grid
          mb='20px'
          gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "repeat(6, 1fr)" }}
          gap='20px'
          display={{ base: "block", lg: "grid" }}
          width='100%'
        >
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 1 / 2 / 2" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <TicketGrossSales />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 2 / 2 / 3" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <TicketNetSales />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 3 / 2 / 4" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <TotalTicketsSold />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 4 / 2 / 5" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <TotalOrders />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 5 / 2 / 6" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <AvgOrderValue />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 6 / 2 / 7" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <AvgTicketsPerOrder />
          </Flex>
        </Grid>
        <Grid
          mb='20px'
          gridTemplateColumns={{ base: "repeat(3, 1fr)", "2xl": "1.75fr 1.125fr 1.125fr" }}
          gap='20px'
          display={{ base: "block", lg: "grid" }}
          width='100%'
        >
          <Flex 
            gridArea={{ base: "1 / 1 / 2 / 4", "2xl": "1 / 1 / 2 / 2" }}
          >
              <TicketsInsightsTreeMapChartViz width='100%'/>
          </Flex>
          <Flex 
            gridArea={{ base: "2 / 1 / 3 / 4", "2xl": "1 / 2 / 2 / 3" }} width='100%'
            mt={{ base: "20px", lg: "0px" }}
          >
            <TicketsInsightsColumnChartViz1 width='100%'/>
          </Flex>
          <Flex 
            gridArea={{ base: "3 / 1 / 4 / 4", "2xl": "1 / 3 / 2 / 4" }} width='100%'
            mt={{ base: "20px", lg: "0px" }}
          >
            <TicketsInsightsColumnChartViz2 width='100%'/>
          </Flex>
        </Grid>
        {/* <Grid
          gap='20px'
          gridTemplateColumns={{ md: "repeat(2, 1fr)", "2xl": "repeat(3, 1fr)" }}
          gridTemplateRows={{ md: "repeat(2, 1fr)", "2xl": "1fr" }}
          mb='20px'
          width='100%'
        >
          <Flex gridArea={{ md: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }}>
            <DailyTraffic />
          </Flex>
          <Flex gridArea={{ md: "1 / 2 / 2 / 3", "2xl": "1 / 2 / 2 / 3" }}>
            <ProjectStatus />
          </Flex>
          <Flex gridArea={{ md: " 2 / 1 / 3 / 3", "2xl": "1 / 3 / 2 / 4" }}>
            <ProfitEstimation />
          </Flex>
        </Grid> */}
        <Grid
          mb='20px'
          gridTemplateColumns={{ base: "1fr" }}
          width='100%'
        >
          <Flex gridArea={{ base: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }}>
            {/* <DateFormatProvider> */}
              <TicketsInsightsHeatMapChartViz
                width='100%'/>
            {/* </DateFormatProvider> */}
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
}