// // spark.jsx
// import {
//   Grid,
// } from "@chakra-ui/react";
// // Custom components
// import Card from "components/card/Card.js";
// import React, { useState } from "react";
// import Messages from "./components/Messages";
// import DataSidebar from "./components/DataSidebar";

// export default function ChatPage() {
//   // Chakra Color Mode
//   const initialMessage = {
//     "content": "Hi! Welcome to Spark Chat. This is a space to discover information and spark new ideas. To begin, select the data, and time period you would like to chat about today",
//     "loading": false
//   };
//   const [messages, setMessages] = useState([initialMessage]);
//   const [dataConfirmationMessage, setDataConfirmationMessage] = useState('');
//   const [modelConfirmationMessage, setModelConfirmationMessage] = useState('');

//   const handleDataConfirmSelection = (dataset, location, dateRange) => {

//     const formatDate = (date) => {
//       const options = { year: 'numeric', month: 'long', day: 'numeric' };
//       return date.toLocaleDateString(undefined, options);
//     };
  
//     const formattedDateRange = `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`;

//     const message = `Dataset: ${dataset || 'None'}
//       Location: ${location || 'None'}
//       Date Range: ${dateRange ? formattedDateRange : 'None'}`;

//     setDataConfirmationMessage(message); // Set the confirmation message
//   };

//   const handleModelConfirmSelection = (model, system, audience) => {

//     // const message = `You have selected:
//     //   Model: ${model || 'None'}
//     //   System: ${system || 'None'}
//     //   Audience: ${audience || 'None'}`;

//       const message = `Model: ${model || 'None'}`;

//     setModelConfirmationMessage(message); // Set the confirmation message
//   };

//   return (
//     <Grid
//       display={{ base: "block", md: "grid" }}
//       gridTemplateColumns={{ base: "1fr 3fr", md: "1fr 3fr" }}
//       gap={{ base: "20px", xl: "20px" }}
//       pt={{ base: "130px", md: "80px", xl: "80px" }}
//       h="calc(100vh - 80px)"
//     >
//       {/* DataSidebar Card */}
//         <DataSidebar 
//           onDataConfirmSelection={handleDataConfirmSelection}
//           onModelConfirmSelection={handleModelConfirmSelection} // Pass the model confirmation handler
//           style={{ flex: 1, height: '100%', width: '100%' }} // Ensure full width and height
//         />

//       {/* Messages Card */}
//       <Card
//         display={{ base: "none", md: "flex" }}
//         h='100%'
//         gridArea='1 / 2 / 2 / 3'
//         px='0px'
//         flexDirection="column"
//       >
//         <Messages
//           status='active'
//           name='Spark AI Chat'
//           messageData={messages}
//           dataConfirmationMessage={dataConfirmationMessage} 
//           modelConfirmationMessage={modelConfirmationMessage}
//           style={{ flex: 1 }} // Flex grow to fill entire card space
//         />
//       </Card>

//     </Grid>
//   );
// }


// spark.jsx
import {
  Box,
  Grid,
  Text,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useState } from "react";
import Messages from "./components/Messages";
import { useLocation } from "contexts/LocationContext";
import { useDateRange } from "contexts/DateRangeContext";
import { usePrompt } from "contexts/PromptContext";
import { useDataV2 } from 'utils/dataHooks';

export default function ChatPage() {
  const initialMessage = {
    "content": "Hi! Welcome to Spark Chat. This is your space to explore information and spark new ideas. Data, time period, and location are pre-selected for you, but you can always customize them using the filter icon at the top right of this chat window.",
    "loading": false
  };
  const defaultDataset = "meta";
  const { defaultLocation } = useLocation();
  const { defaultDateRange } = useDateRange();
  const [messages, setMessages] = useState([initialMessage]);
  const [dataConfirmationMessage, setDataConfirmationMessage] = useState('');
  const [modelConfirmationMessage, setModelConfirmationMessage] = useState('');
  const [isRestartTriggered, setIsRestartTriggered] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { setDatasetPrompt } = usePrompt();
  const defaultData = useDataV2(defaultDataset, defaultLocation, defaultDateRange);
  const toast = useToast();

  // const handleDataConfirmSelection = (dataset, location, dateRange) => {
  //   const formatDate = (date) => {
  //     const options = { year: 'numeric', month: 'long', day: 'numeric' };
  //     return date.toLocaleDateString(undefined, options);
  //   };
  
  //   const formattedDateRange = `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`;

  //   const message = `Dataset: ${dataset || 'None'}
  //     Location: ${location || 'None'}
  //     Date Range: ${dateRange ? formattedDateRange : 'None'}`;

  //   setDataConfirmationMessage(message); // Set the confirmation message
  // };

  const handleResetDataSelection = () => {
    const datasetPrompt = JSON.stringify(defaultData, null, 2);
    setDatasetPrompt(datasetPrompt);
    // handleDataConfirmSelection(defaultDataset, defaultLocation, defaultDateRange);
    setIsRestartTriggered(true);
    setTimeout(() => setIsRestartTriggered(false), 500);
    toast({
      position: "top-right",
      duration: 3000,
      render: () => (
        <Box color="white" p={5} bg="#262e54" borderRadius="md">
          <Text fontWeight="bold">Chat restarted</Text>
          <Text>Your chat session has been restarted.</Text>
        </Box>
      ),
    });
  };

  const handleModelConfirmSelection = (model, system, audience, levelOfDetail) => {
      const message = `Model: ${model || 'None'}
        Level of Detail: ${levelOfDetail || 'None'}`;

    setModelConfirmationMessage(message); // Set the confirmation message
  };

  return (
    <Grid
      display={{ base: "block", md: "grid" }}
      gridTemplateColumns={{ base: "1fr 3fr", md: "1fr 3fr" }}
      gap={{ base: "20px", xl: "20px" }}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      h="calc(100vh - 80px)"
    >
      <Card
        display={{ base: "none", md: "flex" }}
        h="100%"
        gridArea="1 / 1 / 2 / 3"  // Updated to span the full width
        px="0px"
        flexDirection="column"
      >
        <Messages
          status="active"
          name="AI Chat"
          messageData={messages}
          dataConfirmationMessage={dataConfirmationMessage}
          modelConfirmationMessage={modelConfirmationMessage}
          style={{ flex: 1 }}
          onModelConfirmSelection={handleModelConfirmSelection}
          onResetDataSelection={handleResetDataSelection}
          isDataLoading={isDataLoading}
          isRestartTriggered={isRestartTriggered}
          // onDataConfirmSelection={handleDataConfirmSelection}
          setIsDataLoading={setIsDataLoading}
        />
      </Card>
    </Grid>
  );
}
