// signinTwoColumns.jsx
// React / Chakra Imports
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Components
import { HSeparator } from 'components/separator/Separator';
// Layouts
import AuthLayout from 'layouts/auth';
// Icons
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
// AuthContext
import { useAuth } from 'AuthContext';
import '../../assets/css/Login.css';
import { initiatePasswordReset } from 'utils/AuthUtils';
import { confirmPasswordReset } from 'utils/AuthUtils';
import { useToast } from '@chakra-ui/react'

export default function SignIn() {
  const { login } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordResetCode, setPasswordResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [show, setShow] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPasswordShown, setIsForgotPasswordShown] = useState(false);
  const [isPasswordResetCodeSent, setIsPasswordResetCodeSent] = useState(false);

  const handleClick = () => setShow(!show);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { success, error } = await login(email, password, keepSignedIn);
    setIsLoading(false);
    if (success) {
      navigate('/dashboard/overview');
    } else {
      setError(error.message || error);
    }
  };

  const handleSendPasswordResetCode = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      await initiatePasswordReset(email);
      toast({
        title: 'Please check you email',
        description: 'If your email is in our system, we\'ve sent a reset code to it.',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top'
      });
      setIsForgotPasswordShown(false);
      setIsPasswordResetCodeSent(true);
    } catch (error) {
      setError(`Error initiating password reset: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordResetCodeSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      await confirmPasswordReset(email, passwordResetCode, newPassword);
      setIsPasswordResetCodeSent(false);
      toast({
        title: 'Your password is changed',
        description: 'Please login with your new password',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top'
      });
    } catch (error) {
      setError(`Error verifying password reset code: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('secondaryGray.600', 'navy.700');

  const getPasswordResetCodeForm = () => {
    return (
      <form onSubmit={handlePasswordResetCodeSubmit}>
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color="black"
            mb="8px"
          >
            Password Reset Code
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            color={textColorDetails}
            ms={{ base: '0px', md: '0px' }}
            type="text"
            placeholder="Password reset code"
            mb="24px"
            fontWeight="500"
            size="lg"
            value={passwordResetCode}
            onChange={(e) => setPasswordResetCode(e.target.value)}
          />
          <FormLabel
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color="black"
            isRequired={true}
            display="flex"
          >
          New Password
          </FormLabel>
          <InputGroup size="md">
            <Input
              isRequired={true}
              fontSize="sm"
              color={textColorDetails}
              placeholder="Your new password"
              mb="24px"
              size="lg"
              type={show ? 'text' : 'password'}
              variant="auth"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputRightElement display="flex" alignItems="center" mt="4px">
              <Icon
                color={textColorSecondary}
                _hover={{ cursor: 'pointer' }}
                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                onClick={handleClick}
              />
            </InputRightElement>
          </InputGroup>
          {error && (
            <Text color="red.500" mb="24px">
              {error}
            </Text>
          )}
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            type="submit"
            borderRadius="2rem"
            className="login-button"
            isLoading={isLoading}
          >
            Reset Password
          </Button>
        </FormControl>
      </form>
    );
  };

  const getSignInForm = () => {
    return (
      <form onSubmit={isForgotPasswordShown ? handleSendPasswordResetCode : handleSubmit}>
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color="black"
            mb="8px"
          >
            Email
          </FormLabel>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            color={textColorDetails}
            ms={{ base: '0px', md: '0px' }}
            type="text"
            placeholder="Your email"
            mb="24px"
            fontWeight="500"
            size="lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {
            !isForgotPasswordShown &&
            <>
              <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color="black"
              isRequired={true}
              display="flex"
            >
              Password
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  color={textColorDetails}
                  placeholder="Your password"
                  mb="24px"
                  size="lg"
                  type={show ? 'text' : 'password'}
                  variant="auth"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
            </>
          }
          {error && (
            <Text color="red.500" mb="24px">
              {error}
            </Text>
          )}
          {
            !isForgotPasswordShown &&
            <>
              <Flex justifyContent="space-between" align="center">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  iconColor="blue.400"
                  colorScheme="brand"
                  me="10px"
                  sx={{
                    "span.chakra-checkbox__control": {
                      borderColor: "gray.400",
                    },
                  }}
                  isChecked={keepSignedIn}
                  onChange={(e) => setKeepSignedIn(e.target.checked)}
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color="black"
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
            </Flex>
            <Text
              ms="4px"
              mb="20px"
              color='var(--chakra-colors-brand-400)'
              fontWeight="400"
              fontSize="sm"
              textAlign="right"
              onClick={() => {
                setIsForgotPasswordShown(true);
                setError("");
              }}
              className="forgot-password"
            >
              Forgot your password?
            </Text>
          </>
          }
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb={isForgotPasswordShown ? '5gpx' : '24px'}
            type="submit"
            borderRadius="2rem"
            className="login-button"
            isLoading={isLoading}
          >
            {isForgotPasswordShown ? 'Send code' : 'Sign In'}
          </Button>
          {
            isForgotPasswordShown && !isLoading &&
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={() =>setIsForgotPasswordShown(false)}
              borderRadius="2rem"
              className="cancel-button"
            >
              Cancel
            </Button>
          }
        </FormControl>
      </form>
    );
  };

  return (
    <AuthLayout>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h={{ base: '100%', xs: 'auto' }}
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color="black" fontSize="36px" mb="10px">
            {
              isForgotPasswordShown
                ? 'Reset Your Password'
                : isPasswordResetCodeSent
                ? 'Enter Password Reset Code'
                : 'Sign In'
            }
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            {
              isForgotPasswordShown
                ? 'Enter your email to send password reset code'
                : isPasswordResetCodeSent
                ? 'Enter the password reset code in your email'
                : 'Enter your email and password to sign in!'}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Flex align="center" mb="25px">
            <HSeparator />            
          </Flex>
          { isPasswordResetCodeSent ? getPasswordResetCodeForm() : getSignInForm() }
        </Flex>
      </Flex>
    </AuthLayout>
  );
}
