// InsightsHighLevelMetrics.js
// React / Chakra Imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
// Components
import Card from 'components/card/Card';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, sumColumns, useAggregatedData} from 'utils/dataFunctions';
import { formatNumber, calculateChange, renderChange } from 'utils/dataFormatter';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

// Total Spend
export function TotalSpend() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'fb_spend', 'gg_spend'];
  const columnsToSum = ['fb_spend', 'gg_spend'];

  const currentPeriodDataFiltered = sumColumns(filterColumns(currentPeriodData, columnsToKeep), columnsToSum, 'total_spend');
  const pastPeriodDataFiltered = sumColumns(filterColumns(pastPeriodData, columnsToKeep), columnsToSum, 'total_spend');

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'total_spend', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'total_spend', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
    >
      {/* <Flex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
      > */}
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            Total Spend
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(currentPeriodValue, 'dollar')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        {/* </Flex> */}
        </MotionFlex>
        {/* <Flex
          direction="column"
          align="center"
          w="100%"
          h="5px"
        > */}
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
            {renderChange(valueChange)}
          </Text>
        {/* </Flex> */}
        </MotionFlex>
      </Card>
  );
};

// Total Purchases Value
export function TotalPurchasesValue() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view', 'gg_purchases_value'];
  const columnsToSum = ['fb_purchases_value_7d_click', 'fb_purchases_value_1d_view', 'gg_purchases_value'];

  const currentPeriodDataFiltered = sumColumns(filterColumns(currentPeriodData, columnsToKeep), columnsToSum, 'total_purchases_value');
  const pastPeriodDataFiltered = sumColumns(filterColumns(pastPeriodData, columnsToKeep), columnsToSum, 'total_purchases_value');

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'total_purchases_value', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'total_purchases_value', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
    >
      {/* <Flex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
      > */}
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Total Purchases Value
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      {/* </Flex> */}
      </MotionFlex>
      {/* <Flex
        direction="column"
        align="center"
        w="100%"
        h="5px"
      > */}
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      {/* </Flex> */}
      </MotionFlex>
    </Card>
);
};

// Total Attributed ROAS
export function TotalAttributedROAS() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeepNum = ['period', 'date', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view', 'gg_purchases_value'];
  const columnsToSumNum = ['fb_purchases_value_7d_click', 'fb_purchases_value_1d_view', 'gg_purchases_value'];

  const currentPeriodDataFilteredNum = sumColumns(filterColumns(currentPeriodData, columnsToKeepNum), columnsToSumNum, 'total_purchases_value');
  const pastPeriodDataFilteredNum = sumColumns(filterColumns(pastPeriodData, columnsToKeepNum), columnsToSumNum, 'total_purchases_value');

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'total_purchases_value', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'total_purchases_value', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'fb_spend', 'gg_spend'];
  const columnsToSumDen = ['fb_spend', 'gg_spend'];

  const currentPeriodDataFilteredDen = sumColumns(filterColumns(currentPeriodData, columnsToKeepDen), columnsToSumDen, 'total_spend');
  const pastPeriodDataFilteredDen = sumColumns(filterColumns(pastPeriodData, columnsToKeepDen), columnsToSumDen, 'total_spend');

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'total_spend', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'total_spend', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = currentPeriodValueNum / currentPeriodValueDen
  const pastPeriodValue = pastPeriodValueNum / pastPeriodValueDen

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
    >
      {/* <Flex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
      > */}
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Total Attributed ROAS
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'float')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      {/* </Flex> */}
      </MotionFlex>
      {/* <Flex
        direction="column"
        align="center"
        w="100%"
        h="5px"
      > */}
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      {/* </Flex> */}
      </MotionFlex>
    </Card>
);
};

// Ticket Gross Sales
export function TicketGrossSales() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'tk_ticket_gross_revenues'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
    >
      {/* <Flex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
      > */}
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Ticket Gross Sales
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      {/* </Flex> */}
      </MotionFlex>
      {/* <Flex
        direction="column"
        align="center"
        w="100%"
        h="5px"
      > */}
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      {/* </Flex> */}
      </MotionFlex>
    </Card>
);
};

// Total ROAS
export function TotalROAS() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeepNum = ['period', 'date', 'tk_ticket_gross_revenues'];

  const currentPeriodDataFilteredNum = filterColumns(currentPeriodData, columnsToKeepNum);
  const pastPeriodDataFilteredNum = filterColumns(pastPeriodData, columnsToKeepNum);

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'tk_ticket_gross_revenues', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'tk_ticket_gross_revenues', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'fb_spend', 'gg_spend'];
  const columnsToSumDen = ['fb_spend', 'gg_spend'];

  const currentPeriodDataFilteredDen = sumColumns(filterColumns(currentPeriodData, columnsToKeepDen), columnsToSumDen, 'total_spend');
  const pastPeriodDataFilteredDen = sumColumns(filterColumns(pastPeriodData, columnsToKeepDen), columnsToSumDen, 'total_spend');

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'total_spend', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'total_spend', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = currentPeriodValueNum / currentPeriodValueDen
  const pastPeriodValue = pastPeriodValueNum / pastPeriodValueDen

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
    >
      {/* <Flex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
      > */}
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Topline ROAS
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'float')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      {/* </Flex> */}
      </MotionFlex>
      {/* <Flex
        direction="column"
        align="center"
        w="100%"
        h="5px"
      > */}
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      {/* </Flex> */}
      </MotionFlex>
    </Card>
);
};

// Total Impressions
export function TotalImpressions() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'fb_impressions', 'gg_impressions'];
  const columnsToSum = ['fb_impressions', 'gg_impressions'];

  const currentPeriodDataFiltered = sumColumns(filterColumns(currentPeriodData, columnsToKeep), columnsToSum, 'total_impressions');
  const pastPeriodDataFiltered = sumColumns(filterColumns(pastPeriodData, columnsToKeep), columnsToSum, 'total_impressions');

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'total_impressions', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'total_impressions', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100%"
  >
    {/* <Flex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
    > */}
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Total Impressions
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      {/* </Flex> */}
      </MotionFlex>
      {/* <Flex
        direction="column"
        align="center"
        w="100%"
        h="5px"
      > */}
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      {/* </Flex> */}
      </MotionFlex>
    </Card>
);
};

// Total Clicks
export function TotalClicks() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'fb_clicks', 'gg_clicks'];
  const columnsToSum = ['fb_clicks', 'gg_clicks'];

  const currentPeriodDataFiltered = sumColumns(filterColumns(currentPeriodData, columnsToKeep), columnsToSum, 'total_clicks');
  const pastPeriodDataFiltered = sumColumns(filterColumns(pastPeriodData, columnsToKeep), columnsToSum, 'total_clicks');

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'total_clicks', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'total_clicks', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
    >
      {/* <Flex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
      > */}
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Total Clicks
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      {/* </Flex> */}
      </MotionFlex>
      {/* <Flex
        direction="column"
        align="center"
        w="100%"
        h="5px"
      > */}
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      {/* </Flex> */}
      </MotionFlex>
    </Card>
);
};

// Total CPC
export function TotalCPC() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeepNum = ['period', 'date', 'fb_spend', 'gg_spend'];
  const columnsToSumNum = ['fb_spend', 'gg_spend'];

  const currentPeriodDataFilteredNum = sumColumns(filterColumns(currentPeriodData, columnsToKeepNum), columnsToSumNum, 'total_spend');
  const pastPeriodDataFilteredNum = sumColumns(filterColumns(pastPeriodData, columnsToKeepNum), columnsToSumNum, 'total_spend');

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'total_spend', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'total_spend', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'fb_clicks', 'gg_clicks'];
  const columnsToSumDen = ['fb_clicks', 'gg_clicks'];

  const currentPeriodDataFilteredDen = sumColumns(filterColumns(currentPeriodData, columnsToKeepDen), columnsToSumDen, 'total_spend');
  const pastPeriodDataFilteredDen = sumColumns(filterColumns(pastPeriodData, columnsToKeepDen), columnsToSumDen, 'total_spend');

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'total_spend', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'total_spend', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = currentPeriodValueNum / currentPeriodValueDen
  const pastPeriodValue = pastPeriodValueNum / pastPeriodValueDen

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    w="100%"
  >
    {/* <Flex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
    > */}
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="5px"
        >
          Total CPC
        </Text>
        <Text fontSize="3xl" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      {/* </Flex> */}
      </MotionFlex>
      {/* <Flex
        direction="column"
        align="center"
        w="100%"
        h="5px"
      > */}
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      {/* </Flex> */}
      </MotionFlex>
    </Card>
);
};