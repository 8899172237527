// BarChart.js
// React Imports
import React from "react";
// React Apex Charts
import ReactApexChart from "react-apexcharts";

class BarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData,
      chartOptions: props.chartOptions,
      height: '100%'
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.chartOptions !== this.props.chartOptions
    ) {
      this.setState({
        chartData: this.props.chartData,
        chartOptions: this.props.chartOptions,
        height: this.props.height || '100%'
      });
    }
  }

  
  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type='bar'
        width='100%'
        height={this.state.height}
      />
    );
  }
}

export default BarChart;