// Configurator.js
// React / Chakra Imports
import { useEffect, useState } from 'react';
import {
  Button,
  Badge,
  Box,
  Flex,
  Icon,
  Text,
  Image,
  useColorModeValue,
  useColorMode,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  useDisclosure,
  SimpleGrid,
  DrawerOverlay,
} from '@chakra-ui/react';
// Assets
import Light from 'assets/img/layout/Light.png';
import Dark from 'assets/img/layout/Dark.png';
import ConfiguratorLogo from 'assets/img/layout/xeus_lightning_bolt_white.png';
// Icons
import {
  MdSettings,
  MdFullscreen,
  MdOutlineFullscreenExit,
} from 'react-icons/md';
// Components
import ConfiguratorRadio from './ConfiguratorRadio';
import { HSeparator } from 'components/separator/Separator';

export default function HeaderLinks(props) {
  const { theme, setTheme } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [active, setActive] = useState('Purple');
  const [contrast, setContrast] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  const navbarIcon = useColorModeValue('gray.400', 'white');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const bgBadge = useColorModeValue('brand.50', 'whiteAlpha.100');
  const bgSeparator = useColorModeValue('secondaryGray.400', '#323B5D');
  const activeShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.22)',
    'none',
  );
  const activeBg = useColorModeValue('#F7F9FF', 'whiteAlpha.100');
  const Bg = useColorModeValue('white', 'navy.700');
  const drawerBg = useColorModeValue('white', 'navy.800');

  useEffect(() => {
    if (theme.colors.brand[500] === theme.colors.brandGreen[500]) {
      setActive('Green');
    } else if (theme.colors.brand[500] === theme.colors.brandOrange[500]) {
      setActive('Orange');
    } else if (theme.colors.brand[500] === theme.colors.brandRed[500]) {
      setActive('Red');
    } else if (theme.colors.brand[500] === theme.colors.brandBlue[500]) {
      setActive('Blue');
    } else if (theme.colors.brand[500] === theme.colors.brandTeal[500]) {
      setActive('Teal');
    } else {
      setActive('Purple');
    }
  }, [
    theme.colors.brand,
    theme.colors.brandGreen,
    theme.colors.brandOrange,
    theme.colors.brandRed,
    theme.colors.brandBlue,
    theme.colors.brandTeal,
  ]);
  const fullscreenBorder = useColorModeValue(
    'secondaryGray.100',
    'whiteAlpha.200',
  );
  const fullscreenBg = useColorModeValue(
    'rgba(11,11,11,0)',
    'rgba(11,11,11,0)',
  );
  const configuratorShadow = useColorModeValue(
    '-20px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '-22px 32px 51px 4px #0B1437',
  );
  useEffect(() => {
    if (theme.colors.background[100] === '#FFFFFF') {
      setContrast(false);
    } else {
      setContrast(true);
    }
  }, [theme.colors.background]);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () =>
      document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);
  return (
    <>
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={onOpen}
      >
        <Icon me="10px" h="18px" w="18px" color={navbarIcon} as={MdSettings} />
      </Button>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === 'rtl' ? 'left' : 'right'}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent
          boxShadow={configuratorShadow}
          w={{ base: 'calc(100vw - 32px)', md: '400px' }}
          h={{ base: 'calc(100vh - 32px)', md: '480px' }}
          maxW={{ base: 'calc(100vw - 32px)', md: '400px' }}
          maxH={{ base: 'calc(100vh - 32px)', md: '480px' }}
          ms={{
            base: '0px',
            sm: '16px',
          }}
          me={{
            base: '16px',
          }}
          my={{
            sm: '16px',
          }}
          borderRadius="16px"
          bg={drawerBg}
        >
          <DrawerHeader
            w={{ base: '100%', md: '400px' }}
            pt="24px"
            pb="0px"
            px="24px"
          >
            <DrawerCloseButton color={textColor} />
            <Flex alignItems="center">
              <Flex
                h="48px"
                w="48px"
                me="20px"
                borderRadius="999px"
                bgGradient="linear(to-b, #5158FF, #00E5D1)"
                alignItems="center"
                justifyContent="center"
              >
                <Image src={ConfiguratorLogo} boxSize="32px" objectFit="contain"/>
              </Flex>
              <Box>
                <Text color={textColor} fontSize="xl" fontWeight="700">
                  Xeus
                </Text>
                <Text
                  display={'flex'}
                  color="secondaryGray.600"
                  fontSize="md"
                  fontWeight="500"
                >
                  Dashboard
                  <Badge
                    display="flex"
                    colorScheme="brandPurple"
                    borderRadius="25px"
                    bg={bgBadge}
                    color={brandColor}
                    textTransform={'none'}
                    letterSpacing="0px"
                    px="4px"
                    ms="6px"
                  >
                    v1.0.0
                  </Badge>
                </Text>
              </Box>
            </Flex>
            <HSeparator my="30px" bg={bgSeparator} />
          </DrawerHeader>
          <DrawerBody
            pt="0px"
            pb="24px"
            w={{ base: '100%', md: '400px' }}
            maxW="unset"
          >
            <Flex flexDirection="column">
              <Text color={textColor} mb="12px" fontWeight={'700'}>
                Color Mode
              </Text>
              <SimpleGrid columns={2} gap="20px" mb="30px">
              <ConfiguratorRadio
                  onClick={colorMode === 'light' ? toggleColorMode : null}
                  active={colorMode === 'light' ? false : true}
                  label={<Text>Dark</Text>}
                >
                  <Image
                    src={Dark}
                    maxW={{ base: '100%', md: '130px' }}
                    borderRadius="8px"
                  />
                </ConfiguratorRadio>
                <ConfiguratorRadio
                  onClick={colorMode === 'dark' ? toggleColorMode : null}
                  active={colorMode === 'dark' ? false : true}
                  label={<Text>Light</Text>}
                >
                  <Image
                    src={Light}
                    maxW={{ base: '100%', md: '130px' }}
                    borderRadius="8px"
                  />
                </ConfiguratorRadio>
              </SimpleGrid>
            </Flex>
            <HSeparator my="30px" bg={bgSeparator} />
            <Button
              h="max-content"
              w="100%"
              py="16px"
              border="1px solid"
              display={'flex'}
              justifyContent="center"
              alignItems="center"
              bg={fullscreenBg}
              _hover={{ background: Bg, boxShadow: activeShadow }}
              _focus={{ background: Bg, boxShadow: activeShadow }}
              _active={{ background: activeBg, boxShadow: activeShadow }}
              borderColor={fullscreenBorder}
              onClick={() => {
                isFullscreen
                  ? document.exitFullscreen()
                  : document.body.requestFullscreen();
              }}
            >
              {isFullscreen ? 'Exit fullscreen' : 'Fullscreen mode'}
              <Icon
                ms="6px"
                h="18px"
                w="18px"
                color={textColor}
                as={isFullscreen ? MdOutlineFullscreenExit : MdFullscreen}
              />
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
