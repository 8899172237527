// // api.js
// export async function invokeModel(userPrompt, systemPrompt, clientId, sessionId, modelPrompt, audiencePrompt, datasetCurrent = null, datasetPast = null) {
//   // Clone the prompts to avoid mutating the original data
//   const clonedSystemPrompt = JSON.parse(JSON.stringify(systemPrompt));
//   const clonedUserPrompt = JSON.parse(JSON.stringify(userPrompt));
//   const clonedAudiencePrompt = JSON.parse(JSON.stringify(audiencePrompt));

//   console.log("clonedSystemPrompt:", clonedSystemPrompt);
//   console.log("clonedUserPrompt:", clonedUserPrompt);
//   console.log("clonedAudiencePrompt:", clonedAudiencePrompt);
//   console.log("modelPrompt:", modelPrompt);

//   const generateMessageId = () => {
//     const now = new Date();
//     const year = now.getFullYear();
//     const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
//     const day = String(now.getDate()).padStart(2, '0');
//     const hours = String(now.getHours()).padStart(2, '0');
//     const minutes = String(now.getMinutes()).padStart(2, '0');
//     const seconds = String(now.getSeconds()).padStart(2, '0');
  
//     return `${year}${month}${day}_${hours}${minutes}${seconds}`;
//   };

//   try {
//     // Step 1: Read existing conversation from the /read_conversation endpoint
//     let messages = [];  // Default to empty array if no previous conversation exists
//     try {
//       const conversationResponse = await fetch(`https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/read_conversation?client_id=${clientId}&session_id=${sessionId}`);
//       // const conversationResponse = await fetch(`http://localhost:8000/read_conversation?client_id=${clientId}&session_id=${sessionId}`);

//       if (conversationResponse.ok) {
//         const jsonResponse = await conversationResponse.json();
//         console.log("jsonResponse:", jsonResponse);

//         // Assuming the response is already a valid JSON array or object
//         messages = jsonResponse;
//         console.log("messages:", messages);

//       } else if (conversationResponse.status === 404) {
//         console.warn("No previous conversation found, proceeding with empty messages.");
//       } else {
//         console.warn(`Failed to read conversation. Status: ${conversationResponse.status}`);
//       }
//     } catch (error) {
//       console.warn("Error while reading conversation, proceeding with empty messages:", error.message);
//     }

//     // Step 2: Generate a prompt based on the model (Anthropic or OpenAI)
//     let promptResponse;
//     if (modelPrompt.includes("Anthropic")) {
//       promptResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_prompt_anthropic", {
//       // promptResponse = await fetch("http://localhost:8000/generate_prompt_anthropic", {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           user_prompt: clonedUserPrompt,
//           messages: messages,
//           guided: false,
//           audience_prompt: clonedAudiencePrompt,
//           dataset_current: datasetCurrent,
//           dataset_past: datasetPast,
//         }),
//       });
//     } else if (modelPrompt.includes("OpenAI")) {
//       promptResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_prompt_openai", {
//       // promptResponse = await fetch("http://localhost:8000/generate_prompt_openai", {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           user_prompt: clonedUserPrompt,
//           system_prompt: clonedSystemPrompt,
//           messages: messages,
//           guided: false,
//           dataset_current: datasetCurrent,
//           dataset_past: datasetPast,
//         }),
//       });
//     } else {
//       throw new Error(`Model not supported: ${modelPrompt}`);
//     }

//     // Check for errors in prompt generation
//     if (!promptResponse.ok) {
//       throw new Error(`Error generating prompt! Status: ${promptResponse.status}`);
//     }

//     const promptData = await promptResponse.json();
//     console.log("Generated prompt:", promptData);

//     // Step 3: Generate a message from the prompt using the correct model
//     let messageResponse;
//     if (modelPrompt.includes("Anthropic")) {
//       messageResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_message_anthropic", {
//       // messageResponse = await fetch("http://localhost:8000/generate_message_anthropic", { 
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           system_prompt: clonedSystemPrompt,
//           messages_prompt: promptData,
//           model: 'claude-3-haiku-20240307',
//         }),
//       });
//     } else if (modelPrompt.includes("OpenAI")) {
//       messageResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_message_openai", {
//       // messageResponse = await fetch("http://localhost:8000/generate_message_openai", {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           messages_prompt: promptData,
//           model: 'gpt-4o-mini',
//         }),
//       });
//     }

//     // Check for errors in message generation
//     if (!messageResponse.ok) {
//       throw new Error(`Error generating message! Status: ${messageResponse.status}`);
//     }

//     // Parse and log the full response to inspect its structure
//     const jsonResponse = await messageResponse.json();
//     console.log("Full API response:", jsonResponse);

//     // Try to extract original and modified responses based on the actual response structure
//     const original_response_json = jsonResponse[0];
//     const modified_response_json = jsonResponse[1];

//     console.log("Original message:", original_response_json);
//     console.log("Modified message:", modified_response_json);

//     // Step 4: Upload the generated message to the /upload_message endpoint
//     const uploadMessageResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/upload_message", {
//     // const uploadMessageResponse = await fetch("http://localhost:8000/upload_message", {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         response: original_response_json,  // Send the original message as a JSON object
//         client_id: clientId,
//         session_id: sessionId,
//         message_id: generateMessageId(),  // Generate a unique message ID
//       }),
//     });

//     if (!uploadMessageResponse.ok) {
//       throw new Error(`Error uploading message! Status: ${uploadMessageResponse.status}`);
//     }

//     console.log("Message uploaded successfully!");

//     // Step 5: Upload the conversation to the /upload_conversation endpoint
//     const uploadConversationResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/upload_conversation", {
//     // const uploadConversationResponse = await fetch("http://localhost:8000/upload_conversation", {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         response: JSON.stringify(modified_response_json),  // Send the modified conversation as a JSON array/object
//         client_id: clientId,
//         session_id: sessionId,
//       }),
//     });

//     if (!uploadConversationResponse.ok) {
//       throw new Error(`Error uploading conversation! Status: ${uploadConversationResponse.status}`);
//     }

//     console.log("Conversation uploaded successfully!");

//     return { original_response_json, modified_response_json };

//   } catch (error) {
//     console.error("Error during the API flow:", error.message);
//     throw error;
//   }
// }


// api.js
export async function invokeModel(userPrompt, systemPrompt, clientId, userId, sessionId, modelPrompt, audiencePrompt, levelOfDetail, datasetCurrent = null) {
  // Clone the prompts to avoid mutating the original data
  const clonedSystemPrompt = JSON.parse(JSON.stringify(systemPrompt));
  const clonedUserPrompt = JSON.parse(JSON.stringify(userPrompt));
  const clonedAudiencePrompt = JSON.parse(JSON.stringify(audiencePrompt));

const guidedMessage = levelOfDetail === "High-Level"
    ? ". Please be specific on your answer and do not over explain. You can provide dates and values, but avoid reference columns or specific parts of the provided dataset (if any)"
    : ". Please provide a very detailed answer based on your knowledge. You can provide dates and values, but avoid reference columns or specific parts of the provided dataset (if any)";

  console.log("clonedSystemPrompt:", clonedSystemPrompt);
  console.log("clonedUserPrompt:", clonedUserPrompt);
  console.log("clonedAudiencePrompt:", clonedAudiencePrompt);
  console.log("modelPrompt:", modelPrompt);
  console.log("levelOfDetail:", levelOfDetail);

  // Function to generate the current date in YYYYMMDD format
  const generateDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, '0');
  
    return `${year}${month}${day}`;
  };

  // Function to generate the current time in HHMMSS format
  const generateTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
    
      return `${hours}${minutes}${seconds}`;
  };

  // Function to generate the message ID by combining date and time
  const generateMessageId = () => {
      const date = generateDate();
      const time = generateTime();
      return `${date}_${time}`;
  };

  try {
    // Step 1: Read existing conversation from the /read_conversation endpoint
    let messages = [];  // Default to empty array if no previous conversation exists
    try {
      const conversationResponse = await fetch(`https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/read_conversation?client_id=${clientId}&user_id=${userId}&session_id=${sessionId}`);
      // const conversationResponse = await fetch(`http://localhost:8000/read_conversation?client_id=${clientId}&user_id=${userId}&session_id=${sessionId}`);

      if (conversationResponse.ok) {
        const jsonResponse = await conversationResponse.json();
        console.log("jsonResponse:", jsonResponse);

        // Assuming the response is already a valid JSON array or object
        messages = jsonResponse;
        console.log("messages:", messages);

      } else if (conversationResponse.status === 404) {
        console.warn("No previous conversation found, proceeding with empty messages.");
      } else {
        console.warn(`Failed to read conversation. Status: ${conversationResponse.status}`);
      }
    } catch (error) {
      console.warn("Error while reading conversation, proceeding with empty messages:", error.message);
    }

    // Step 2: Generate a prompt based on the model (Anthropic or OpenAI)
    let promptResponse;
    if (modelPrompt.includes("Sonnet")) {
      promptResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_prompt_anthropic", {
      // promptResponse = await fetch("http://localhost:8000/generate_prompt_anthropic", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_prompt: clonedUserPrompt,
          previous_messages: messages,
          guided_messages: guidedMessage,
          audience_prompt: null,
          dataset: datasetCurrent,
        }),
      });
    } else if (modelPrompt.includes("GPT")) {
      promptResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_prompt_openai", {
      // promptResponse = await fetch("http://localhost:8000/generate_prompt_openai", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_prompt: clonedUserPrompt,
          system_prompt: null,
          previous_messages: messages,
          guided_messages: guidedMessage,
          audience_prompt: null,
          dataset: datasetCurrent,
        }),
      });
    } else if (modelPrompt.includes("mini")) {
      promptResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_prompt_openai", {
      // promptResponse = await fetch("http://localhost:8000/generate_prompt_openai", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_prompt: clonedUserPrompt,
          system_prompt: null,
          previous_messages: messages,
          guided_messages: guidedMessage,
          audience_prompt: null,
          dataset: datasetCurrent,
        }),
      });
    } else {
      throw new Error(`Model not supported: ${modelPrompt}`);
    }

    // Check for errors in prompt generation
    if (!promptResponse.ok) {
      throw new Error(`Error generating prompt! Status: ${promptResponse.status}`);
    }

    const promptData = await promptResponse.json();
    console.log("Generated prompt:", promptData);

    // Step 3: Generate a message from the prompt using the correct model
    let messageResponse;
    if (modelPrompt.includes("Sonnet")) {
      messageResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_message_anthropic", {
      // messageResponse = await fetch("http://localhost:8000/generate_message_anthropic", { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          system_prompt: null,
          messages_prompt: promptData,
          model: 'claude-3-5-sonnet-20240620',
        }),
      });
    } else if (modelPrompt.includes("GPT")) {
      messageResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_message_openai", {
      // messageResponse = await fetch("http://localhost:8000/generate_message_openai", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages_prompt: promptData,
          model: 'gpt-4o',
        }),
      });
    } else if (modelPrompt.includes("mini")) {
      messageResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/generate_message_openai_o1", {
      // messageResponse = await fetch("http://localhost:8000/generate_message_openai_o1", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages_prompt: promptData,
          model: 'o1-mini',
        }),
      });
    }

    // Check for errors in message generation
    if (!messageResponse.ok) {
      throw new Error(`Error generating message! Status: ${messageResponse.status}`);
    }

    // Parse and log the full response to inspect its structure
    const jsonResponse = await messageResponse.json();
    console.log("Full API response:", jsonResponse);

    // Try to extract original and modified responses based on the actual response structure
    const original_response_json = jsonResponse[0];
    const modified_response_json = jsonResponse[1];
    const usage_json = jsonResponse[2];

    console.log("Original message:", original_response_json);
    console.log("Modified message:", modified_response_json);
    console.log("Usage:", usage_json);

    // Step 4: Upload the generated message to the /upload_message endpoint
    const uploadMessageResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/upload_message", {
    // const uploadMessageResponse = await fetch("http://localhost:8000/upload_message", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        response: original_response_json,  // Send the original message as a JSON object
        client_id: clientId,
        user_id: userId,
        session_id: sessionId,
        message_id: generateMessageId(),  // Generate a unique message ID
      }),
    });

    if (!uploadMessageResponse.ok) {
      throw new Error(`Error uploading message! Status: ${uploadMessageResponse.status}`);
    }

    console.log("Message uploaded successfully!");

    // Step 5: Upload the conversation to the /upload_conversation endpoint
    const uploadConversationResponse = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/upload_conversation", {
    // const uploadConversationResponse = await fetch("http://localhost:8000/upload_conversation", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        response: JSON.stringify(modified_response_json),  // Send the modified conversation as a JSON array/object
        client_id: clientId,
        user_id: userId,
        session_id: sessionId,
      }),
    });

    if (!uploadConversationResponse.ok) {
      throw new Error(`Error uploading conversation! Status: ${uploadConversationResponse.status}`);
    }

    console.log("Conversation uploaded successfully!");

    // Step 6: Upload/Update the usage to the /update_usage endpoint
    const updateUsage = await fetch("https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/update_usage", {
    // const updateUsage = await fetch("http://localhost:8000/update_usage", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        usage: JSON.stringify(usage_json),  // Send the modified conversation as a JSON array/object
        client_id: clientId,
        user_id: userId,
        usage_id: generateDate(),
      }),
    });

    if (!updateUsage.ok) {
      throw new Error(`Error updating usage! Status: ${updateUsage.status}`);
    }

    console.log("Usage updated successfully!");

    // Step 7: Read the usage
    let usageTotalTokens = 0;  // Default to empty array if no previous conversation exists
    try {
      const usageResponse = await fetch(`https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/sum_usage_total_tokens?client_id=${clientId}&user_id=${userId}&start_date=2024-10-01&end_date=2024-11-01`);
      // const usageResponse = await fetch(`http://localhost:8000/sum_usage_total_tokens?client_id=${clientId}&user_id=${userId}&start_date=2024-10-01&end_date=2024-11-01`);

      if (usageResponse.ok) {
        const usageJsonResponse = await usageResponse.json();
        console.log("Usage JSON Response:", usageJsonResponse);

        usageTotalTokens = usageJsonResponse.total_tokens_sum || 0;
        console.log("Total tokens used:", usageTotalTokens);

      } else if (usageResponse.status === 404) {
        console.warn("No usage data found within the specified date range, proceeding with usageTotalTokens set to 0.");
      } else {
        console.warn(`Failed to retrieve usage data. Status: ${usageResponse.status}`);
      }
    } catch (error) {
      console.warn("Error while retrieving usage data, proceeding with usageTotalTokens set to 0:", error.message);
    }
  
    console.log("Usage updated successfully!");

    return { original_response_json, modified_response_json, usage_json, usageTotalTokens };

  } catch (error) {
    console.error("Error during the API flow:", error.message);
    throw error;
  }
}
