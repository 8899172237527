// MetricsMetaContext.js
// React Imports
import React, { createContext, useState, useContext } from 'react';

const MetricsMetaContext = createContext();

export const MetricsMetaProvider = ({ children }) => {
  const [metrics, setMetricsMeta] = useState("ctr");

  return (
    <MetricsMetaContext.Provider value={{ metrics, setMetricsMeta }}>
      {children}
    </MetricsMetaContext.Provider>
  );
};

export const useMetricsMeta = () => useContext(MetricsMetaContext);
