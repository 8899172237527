// MiniCalendar.js
// React / Chakra Imports
import React, { useEffect, useState } from 'react';
import { useColorModeValue, Icon, Text, useColorMode, Button, Flex } from '@chakra-ui/react';
// Components
import Calendar from "react-calendar";
import Card from "components/card/Card.js";
// Icons
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// CSS
import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";
// Date Imports
import { subDays, format } from 'date-fns';
import { useDateRange } from 'contexts/DateRangeContext';

export default function MiniCalendar(props) {
  const { onChange, selectRange, dateRange, pastDateRange, currentMode, setCurrentMode, ...rest } = props;
  const { setSelectedDateRangeOption } = useDateRange();
  const [pastDateStart, pastDateEnd] = pastDateRange;
  const { colorMode } = useColorMode();
  const today = new Date();
  const yesterday = subDays(today, 1);
  const last7Days = [subDays(yesterday, 6), yesterday];
  const [value, setValue] = useState(last7Days);

  useEffect(() => {
    setValue(dateRange);
  }, [dateRange]);
  
  const handleDateChange = (date) => {
    setValue(date);
    setSelectedDateRangeOption('custom');
    if (selectRange && date.length === 2) {
      onChange(date); // Call the parent's onChange function to update the date range
    }
  };
  
  const borderColor = useColorModeValue("transparent", "whiteAlpha.100");

  return (
    <Card
      border='1px solid'
      borderColor={borderColor}
      align='center'
      direction='column'
      w='100%'
      maxW='max-content'
      p='20px 15px'
      h='max-content'
      minH="400px"
      {...rest}>
      <Calendar
        onChange={handleDateChange}
        value={value}
        selectRange={selectRange}
        returnValue="range"
        view={"month"}
        tileContent={<Text color='brand.500'></Text>}
        prevLabel={<Icon as={MdChevronLeft} w='24px' h='24px' mt='4px' />}
        nextLabel={<Icon as={MdChevronRight} w='24px' h='24px' mt='4px' />}
        tileClassName={({ date, view }) => {
          const isSameDate = (date1, date2) => {
            return (
              date1.getFullYear() === date2.getFullYear() &&
              date1.getMonth() === date2.getMonth() &&
              date1.getDate() === date2.getDate()
            );
          };
          if (view === 'month' && (isSameDate(date, pastDateStart))) {
            return colorMode === 'light' ? 'past-period-date-start' : 'past-period-date-start-dark';
          }
          if (view === 'month' && (isSameDate(date, pastDateEnd))) {
            return colorMode === 'light' ? 'past-period-date-end' : 'past-period-date-end-dark';
          }

          if (view === 'month' && date > pastDateStart && date < pastDateEnd) {
            return colorMode === 'light' ? 'past-period-date' : 'past-period-date-dark';
          }
        }}
      />
      <Flex display='flex' gap='5px'>
        <Button
          fontSize="xs"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="30"
          mb="10px"
          mt="10px"
          borderRadius="10px"
          onClick={() => setCurrentMode('days')}
          className={currentMode === 'week' && 'not-selected-mode'}
        >
          Last Days
        </Button>
        <Button
          fontSize="xs"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="30"
          mb="10px"
          mt="10px"
          borderRadius="10px"
          onClick={() => setCurrentMode('week')}
          className={currentMode === 'days' && 'not-selected-mode'}
        >
          Last Week
        </Button>
      </Flex>

      <Text fontSize="xs" color='#5158FF'>
        {`past period: ${format(pastDateStart, 'MMMM d, yyyy')} - ${format(pastDateEnd, 'MMMM d, yyyy')}`}
      </Text>
    </Card>
  );
}
