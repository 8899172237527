import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { differenceInDays, isValid, parse } from 'date-fns';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AvgOrderValue,
  AvgTicketsPerOrder,
  GoogleClicks,
  GoogleCPC,
  GoogleCTR,
  GooglePurchasesValue,
  GoogleROAS,
  GoogleSpend,
  MetaClicks,
  MetaCPC,
  MetaCTR,
  MetaPurchasesValue,
  MetaROAS,
  MetaSpend,
  TicketGrossSales,
  TicketNetSales,
  TotalOrders,
  TotalTicketsSold,
} from "./MetricsCards";
import SummarySection from "./SummarySection";

export default function SummaryListItem(props) {
  const { tabKey, clientFilter, locationFilter, currentDateRangeFilter, currentDateRange } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const bgColor = useColorModeValue("gray.100", "gray.900");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [summaryData, setSummaryData] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Initialize loading state
  const navigate = useNavigate();

  const parseDateRange = (dateRangeStr) => {
    if (Array.isArray(dateRangeStr)) {
      // If it's already an array, return it as-is
      return dateRangeStr;
    }
  
    // If it's a string, split it and parse the dates
    const [startDateStr, endDateStr] = dateRangeStr.split(' - ').map(date => date.trim());
    
    // Define the format of the input date strings
    const dateFormat = 'MMMM d, yyyy'; // e.g., 'September 2, 2024'
    
    // Convert both date strings to Date objects using date-fns parse
    const startDate = parse(startDateStr, dateFormat, new Date());
    const endDate = parse(endDateStr, dateFormat, new Date());
    
    // Check if parsing resulted in valid dates
    if (!isValid(startDate) || !isValid(endDate)) {
      console.error("Invalid dates after parsing:", startDateStr, endDateStr);
      return null;
    }
  
    // Return an array of two Date objects
    return [startDate, endDate];
  };

  const calculatePastDateRange = (dateRange) => {
    // Convert the start and end date from the dateRange to Date objects
    const currentStart = new Date(dateRange[0]);
    const currentEnd = new Date(dateRange[1]);
  
    // Set hours to zero for both start and end dates
    currentStart.setHours(0, 0, 0, 0);
    currentEnd.setHours(0, 0, 0, 0);
  
    // Calculate the difference in days between the start and end dates
    const rangeDays = differenceInDays(currentEnd, currentStart) + 1;
  
    // Calculate the past date range by subtracting the number of days from currentStart and currentEnd
    const previousStart = new Date(currentStart);
    previousStart.setDate(previousStart.getDate() - rangeDays);
  
    const previousEnd = new Date(currentEnd);
    previousEnd.setDate(previousEnd.getDate() - rangeDays);
  
    // Return the past date range in the same format as the input dateRange
    return [previousStart, previousEnd];
  };

  const formattedDateRange = parseDateRange(currentDateRange);
  const formattedPastDateRange = calculatePastDateRange(formattedDateRange);

  // Fetch summary data when modal opens
  useEffect(() => {
    if (isOpen) {
      const fetchSummaryData = async () => {
        setIsLoading(true); // Start loading
        try {
          const dateRange = currentDateRangeFilter.replace(/[\[\]"]/g, '').split(', ');
          const locationFilterReplaced = locationFilter.replace(" ", "_").replace(" ", "_").replace(" ", "_")
          // const summary_name = `${tabKey}__${encodeURIComponent(locationFilter)}__${dateRange[0]}__${dateRange[1]}`;
          const summary_name = `${tabKey}__${locationFilterReplaced}__${dateRange[0]}__${dateRange[1]}`;
          console.log("summary_name:", summary_name);
          const response = await fetch(
            `https://xeuscloud-reporting-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/fetch_summary?client_id=${clientFilter}&summary=${summary_name}`
          );
          console.log("Response status:", response.status);
          const responseText = await response.text();
          console.log("Response text:", responseText);
          const contentType = response.headers.get("content-type");
          if (!contentType || !contentType.includes("application/json")) {
            throw new Error("Response is not JSON");
          }
          const data = JSON.parse(responseText);
          setSummaryData(data);
        } catch (error) {
          console.error(`Error fetching data for ${locationFilter}:`, error);
          setSummaryData("Error fetching data");
        }
        setIsLoading(false); // Loading finished
      };
      fetchSummaryData();
    }
  }, [isOpen]); // Dependency on isOpen to fetch data when modal opens

  const handleSeeAnalysis = () => {
    onOpen();
    setSummaryData({}); // Set the fetched summary data
  };

  // Render metrics based on the key
  const renderMetrics = () => {

    // const formattedDateRange = parseDateRange(currentDateRangeFilter);
    // const formattedPastDateRange = calculatePastDateRange(formattedDateRange);

    switch (tabKey) {
      case "meta":
        return (
          <>
            <MetaSpend location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <MetaPurchasesValue location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <MetaROAS location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <MetaClicks location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <MetaCTR location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <MetaCPC location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
          </>
        );
      case "google":
        return (
          <>
            <GoogleSpend location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <GooglePurchasesValue location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <GoogleROAS location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <GoogleClicks location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <GoogleCTR location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <GoogleCPC location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
          </>
        );
      case "ticketing":
        return (
          <>
            <TicketGrossSales location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <TicketNetSales location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <TotalTicketsSold location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <TotalOrders location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <AvgOrderValue location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
            <AvgTicketsPerOrder location={locationFilter} dateRange={formattedDateRange} pastDateRange={formattedPastDateRange}/>
          </>
        );
      default:
        return <Text>No metrics available for this key.</Text>;
    }
  };

  return (
    <>
      <Card p="20px">
        <Flex flexDirection="row" justify="space-between" alignItems="center" h="100%">
        <Flex justify="start" flex="1">
          <Text
            color={textColor}
            fontSize={{ base: "xl", md: "lg", lg: "lg" }}
          >
            {currentDateRange}
          </Text>
          </Flex>
          <Flex justify="start" flex="1">
            <Text
              color={textColor}
              fontSize={{ base: "xl", md: "lg", lg: "lg" }}
            >
              {locationFilter}
            </Text>
          </Flex>
          <Button
            onClick={handleSeeAnalysis}
            variant="darkBrand"
            color="white"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px"
            ml="auto"
            _hover={{ bg: '#4B21F3' }}
          >
            See Summary
          </Button>
        </Flex>
      </Card>

      {/* Modal for displaying the analysis and metrics */}
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          {/* Keep the header section */}
          <Flex bgGradient="linear(to-r, blue.500, purple.500)" p={6} borderTopRadius="lg" flexDirection="column" alignItems="center">
            <Heading color="white" size="lg">
              {locationFilter}
            </Heading>
            <Text color="white" mt={2}>
              {currentDateRange}
            </Text>
          </Flex>

          <ModalCloseButton color="white" />
        <ModalBody bg={bgColor} color={textColor}>
          {isLoading ? (
            // Loading Spinner
            <Flex align="center" justify="center" height="300px">
              <Spinner size="xl" />
              <Text ml={4}>Loading summary...</Text>
            </Flex>
          ) : (
            // Content to display after loading
            <Flex flexDirection="column" justifyContent="center" mb={20}>
              {/* High-Level Metrics */}
              <Flex justify="center" width="100%">
                <Grid
                  mt="20px"
                  mb="20px"
                  gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
                  gap="20px"
                  width="80%"
                >
                  {renderMetrics()}
                </Grid>
              </Flex>

              {/* Grouped Bar Chart */}
              {/* <Box mt="20px" mb="20px">
                <MetricsGroupedBarChart 
                  location={locationFilter} 
                  dateRange={formattedDateRange} 
                  pastDateRange={formattedPastDateRange}
                />
              </Box> */}

              {/* Summary Sections */}
              {/* <Box p={4} bg="gray.800" borderRadius="md" mb={4} whiteSpace="pre-wrap">
                <Text color="white" mt={2} mb="5px" fontSize="18px" fontWeight="bold">
                  Key Trends
                </Text>
                {summaryData.key_trends}
              </Box>
              <Box p={4} bg="gray.800" borderRadius="md" mb={4} whiteSpace="pre-wrap">
                <Text color="white" mt={2} mb="5px" fontSize="18px" fontWeight="bold">
                  Changes and Patters
                </Text>
                {summaryData.changes_and_patterns}
              </Box>
              <Box p={4} bg="gray.800" borderRadius="md" mb={4} whiteSpace="pre-wrap">
                <Text color="white" mt={2} mb="5px" fontSize="18px" fontWeight="bold">
                  Comparison
                </Text>
                {summaryData.period_comparison}
              </Box>
              <Box p={4} bg="gray.800" borderRadius="md" mb={4} whiteSpace="pre-wrap">
                <Text color="white" mt={2} mb="5px" fontSize="18px" fontWeight="bold">
                  Performance Summary
                </Text>
                {summaryData.performance_summary}
              </Box> */}
              <Box width="80%" alignSelf="center">
                <SummarySection title="Performance Summary" data={summaryData.performance_summary} bgGradient="linear(to-r, #5062F299, #2E398C99)" />
                <SummarySection title="Key Trends" data={summaryData.key_trends} />
                <SummarySection title="Changes & Patterns" data={summaryData.changes_and_patterns} />
              </Box>

              <Box width="80%" alignSelf="center" textAlign="center" mt={10} mb={10}>
                <Text fontSize="lg" fontWeight="bold" color={textColor} mb={3}>Looking for additional insight?</Text>
                <Button
                  borderRadius="70px"
                  px="30px"
                  py="20px"
                  bgGradient="linear(to-r, blue.500, purple.500)"
                  fontSize="sm"
                  color="white"
                  onClick={() => navigate('/dashboard/lightning/spark')}
                >
                  Spark Chat AI
                </Button>
              </Box>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
      </Modal>
  </>
);
}