import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useAuth } from "AuthContext";
import Card from "components/card/Card.js";
import locationImages from "variables/locationImages";

export default function LocationCard(props) {
  const { locationFilter, setSelectedLocation } = props;
  const { company } = useAuth();
  console.log(company)
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <Card p="20px">
      <Flex direction={{ base: "column" }} justify="center">
        <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
          <Box
            w={{ base: "100%", "3xl": "100%" }}
            h={{ base: "200px", "3xl": "150px" }}
            borderRadius="20px"
            backgroundImage={company ? `url(${locationImages[company][locationFilter.replaceAll(' ', '_').toLowerCase()]})` : null}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"  
          ></Box>
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex justify="space-between" mb="auto">
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize={{ base: "2xl", md: "xl", lg: "xl" }}
                fontWeight="bold"
              >
                {locationFilter}
              </Text>
            </Flex>
          </Flex>
          <Flex align="start" mt="25px">
            <Button
              onClick={() => setSelectedLocation(locationFilter)}
              variant="darkBrand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              borderRadius="70px"
              px="24px"
              py="5px"
              ml="auto"
              _hover={{ bg: '#4B21F3' }}
            >
              Details
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}