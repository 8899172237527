import { useState } from 'react';
import { Text, Input, useColorModeValue, Tooltip } from '@chakra-ui/react';

function EditableText({ defaultValue, width = "80%" }) {
    const [isEditing, setIsEditing] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [title, setTitle] = useState(defaultValue);
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        if (isEmpty) {
            setTitle(defaultValue);
        }
        return setIsEditing(false);
    };

    const handleInputChange = (e) => {
        setTitle(e.target.value);
        if (!e.target.value) {
            return setIsEmpty(true);
        }
        setIsEmpty(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
        handleBlur();
        }
    };

    return (
        <>
        {isEditing ? (
            <Input
                value={title}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoFocus
                ml={3}
                fontSize="md"
                width={width}
                color={textColor}
                placeholder={isEmpty ? defaultValue : ''}
                borderColor='#5158FF'
                _focus={{ borderColor: '#5158FF' }}
                _hover={{ borderColor: '#5158FF' }} 
            />
        ) : (
            <Tooltip label="Change title" fontSize="sm">
                <Text fontWeight="bold" fontSize="lg" ml={3} onClick={handleEditClick} _hover={{ cursor: 'pointer' }}>
                    {title}
                </Text>
            </Tooltip>
        )}
        </>
  );
}

export default EditableText;
