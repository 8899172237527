// // Chakra imports
// import {
//   Box,
//   Flex,
//   Text,
//   useColorModeValue, 
// } from '@chakra-ui/react';
// import BarChart from 'components/charts/BarChart';

// // Custom components
// import Card from 'components/card/Card';
// import CardHeader from 'components/card/CardHeader';
// import {
//   columnChartDataOverview,
//   columnChartOptionsOverview,
// } from 'variables/charts';
// import { formatNumberCharts } from "utils/dataFormatter";
// import { useLocation } from 'utils/LocationContext';
// import { useDateRange } from 'utils/DateRangeContext';
// import { useFilteredData } from 'utils/dataHooks';
// import { filterColumns, aggregateDataByDate, transformDataToJSON} from 'utils/dataFunctions';

// export default function ColumnChartViz() {
//   const { location } = useLocation();
//   const { dateRange } = useDateRange();
//   const { currentPeriodData } = useFilteredData("overview", location, dateRange);

//   // Log the filtered data for debugging
//   console.log("Filtered Data:", currentPeriodData);

//   const columnsToKeep = ['period', 'date', 'tk_total_orders'];
//   const columnsToSum = ['tk_total_orders'];
//   const renameMap = {
//     'tk_total_orders': 'data',
//   };

//   const currentPeriodDataByColumns = filterColumns(currentPeriodData, columnsToKeep);

//   // Log the filtered data by columns
//   console.log("Filtered Data by Columns:", currentPeriodDataByColumns);

//   const finalData = aggregateDataByDate(currentPeriodDataByColumns, 'day_of_week', columnsToSum, 'sum');
//   const serializedData = transformDataToJSON(finalData, columnsToKeep)

//   const extractedDates = serializedData && serializedData.length > 0
//   ? serializedData.map(d => d.date)
//   : [];

//   console.log("extractedDates:", extractedDates);

//   const extractedData = serializedData && serializedData.length > 0
//   ? serializedData.map(d => d.data)
//   : [];

//   // Log the final aggregated data
//   console.log("Final Aggregated Data:", finalData);
//   console.log("Final Serialized Data:", serializedData);
//   // console.log("Final Dates:", extractedDates[0]);
//   // console.log("Final Data:", extractedData[0]);

//   // Find the maximum value from both series
//   // const maxValue = Math.max(...finalData[0].data);

//   // // console.log("maxOrders:", maxValue);

//   // // Calculate the new max value
//   // const newmaxValue = maxValue + (0.15 * maxValue);

//   const newOptions = {
//     chart: {
//       type: 'bar',
//       toolbar: {
//         show: false,
//       },
//     },
//     plotOptions: {
//       bar: {
//         horizontal: true,
//         borderRadius: 5,
//         columnWidth: '50px',
//         dataLabels: {
//           position: 'top',
//         },
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       style: {
//         colors: ['#FFF'],
//       },
//       offsetX: 50,
//       formatter: (val) => {
//         return formatNumberCharts(val, 'integer', false);
//       },
//     },
//     grid: {
//       show: false,
//     },
//     xaxis: {
//       categories: extractedDates,
//       show: false,
//       min: 0,
//       // max: newmaxValue,
//       labels: {
//         show: false,
//         },
//         axisBorder: {
//           show: false,
//         },
//         axisTicks: {
//           show: false,
//         },
//     },
//     yaxis: {
//       show: true,
//       labels: {
//           show: true,
//           style: {
//               colors: ['#FFF'], // Set the color of the labels to white
//               fontSize: '14px', // You can adjust the font size as needed
//           },
//           // formatter: function (val, index) {
//           //   return newOptions.xaxis.categories[index]; // Ensure the labels are properly formatted
//           // },
//       },
//       axisBorder: {
//         show: false,
//       },
//       axisTicks: {
//         show: false,
//       },
//     },
//     fill: {
//       colors: ['#00E5D1'],
//     },
//     tooltip: {
//       enabled: false,
//     }
//   };


//   // Chakra Color Mode
//   // const textColor = useColorModeValue('secondaryGray.900', 'white');
//   return (
//     <Card
//       flexDirection="column"
//       w="100%"
//       // {...rest}
//     >
//       <CardHeader mt='10px' mb='10px' ps='22px'>
// 						<Flex direction='column' alignSelf='flex-start'>
// 							<Text fontSize='lg' color='#fff' fontWeight='bold' mb='0px'>
// 								Total Orders
// 							</Text>
// 						</Flex>
//       </CardHeader>
//       <Box minH="260px" mt="auto" mb="0px" pr="15px" pl="15px" w="100%">
//         <BarChart
//           chartData={serializedData}
//           chartOptions={newOptions}
//         />
//       </Box>
//     </Card>
//   );
// }



// Chakra imports
import {
  Box,
  Flex,
  Text,
  border,
  useColorModeValue, 
} from '@chakra-ui/react';
import HeatMapChart from 'components/charts/HeatMapChart';
import React, { useMemo, useEffect } from "react";

// Custom components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';

import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useFilteredData } from 'utils/dataHooks';
import { transformDataTreeMap } from 'utils/dataFunctions';

import { motion } from "framer-motion";
const MotionFlex = motion(Flex);

export default function ColumnChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_insights_heatmap", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorChart = useColorModeValue('secondaryGray.500', 'white');
  const strokeColorChart = useColorModeValue('white', '#111c44');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  console.log("currentPeriodData:", currentPeriodData);

  const transformedData = transformDataTreeMap(currentPeriodData);

  console.log("transformedData:", transformedData);

  // Step 1: Define the order of days from Sunday to Saturday
  const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  // Step 2: Sort the transformedData array using the custom order
  transformedData.sort((a, b) => {
    // return dayOrder.indexOf(a.name) - dayOrder.indexOf(b.name);
    return dayOrder.indexOf(b.name) - dayOrder.indexOf(a.name);
  });

  // Step 2: Calculate min and max values from transformedData
  const allValues = transformedData.flatMap(day => day.data);
  const minValue = Math.min(...allValues);
  const maxValue = Math.max(...allValues);
  // const totalSum = allValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const totalSum = transformedData.reduce((sum, day) => {
    return sum + day.data.reduce((daySum, value) => daySum + value, 0);
  }, 0);

  // Step 3: Determine thresholds for three groups
  const range = maxValue - minValue;
  const groupSize = Math.round(range / 3);

  const lowThreshold = minValue + groupSize;
  const mediumThreshold = minValue + 2 * groupSize;

  console.log("totalSum:", totalSum);
  console.log("minValue:", minValue);
  console.log("maxValue:", maxValue);
  console.log("range:", range);
  console.log("groupSize:", groupSize);
  console.log("lowThreshold:", lowThreshold);
  console.log("mediumThreshold:", mediumThreshold);

  // Step 4: Create strings for each range
  const lowRangeString = `[${minValue}-${lowThreshold}]`;
  const mediumRangeString = `[${lowThreshold + 1}-${mediumThreshold}]`;
  const highRangeString = `[${mediumThreshold + 1}-${maxValue}]`;

  // const aggregatedData = useMemo(() => aggregateDataByDayAndHour(currentPeriodData, 'order_completed_time', columnsToSum, 'countd'), [currentPeriodData, columnsToSum]);

  // console.log("aggregatedData:", aggregatedData);

//   const columnChartData = useMemo(() => 
//     [
//     {
//       name: 'META Purchases Value',
//       data: aggregatedData.map(item => (item.tk_total_orders ?? 0) ),
//     },
//   ]
//   , [aggregatedData]);

//   console.log("columnChartData:", columnChartData);

// const columnChartCategories = useMemo(() => 
//   aggregatedData
//   .map(item => item.date)
//   , [aggregatedData]);

//   console.log("columnChartCategories:", columnChartCategories);

// const newOptions = {
//   ...columnChartOptionsOverview,
//   plotOptions: {
//     ...columnChartOptionsOverview.plotOptions,
//     bar: {
//       horizontal: true,
//       borderRadius: 5,
//       dataLabels: {
//         position: 'top', // This will position the data labels at the end of the bars
//       },
//     },
//   },
//   dataLabels: {
//     // ...columnChartOptionsOverview.dataLabels,
//     enabled: true,
//     style: {
//       colors: [textColorChart],
//     },
//     offsetX: 30,
//   },
//   xaxis: {
//     categories: columnChartCategories,
//     show: false,
//     min: 0, // Force y-axis to start at 0
//     // max: newmaxOrders,
//     labels: {
//       show: false,
//       style: {
//         colors: '#A3AED0',
//         fontSize: '14px',
//         fontWeight: '500',
//       },
//     },
//     axisBorder: {
//       show: false,
//     },
//     axisTicks: {
//       show: false,
//     },
//   },
//   yaxis: {
//     ...columnChartOptionsOverview.yaxis,
//     show: true,
//     color: 'black',
//     labels: {
//       show: true,
//       style: {
//         colors: textColorChart,
//         fontSize: '14px',
//       },
//     },
//   },
//   fill: {
//     colors: ['#00E5D1'], // Set the color of the bars to white
//   },
// };

//   // Chakra Color Mode
//   // const textColor = useColorModeValue('secondaryGray.900', 'white');

const newOptions = {
  chart: {
    fontFamily: 'Poppins',
    toolbar: {
      show: false,
    },
    // dropShadow: {
    //   enabled: true,
    //   top: 13,
    //   left: 0,
    //   blur: 10,
    //   opacity: 0.1,
    //   color: '#4318FF',
    // },
  },
  legend: {
    show: false
  },
  plotOptions: {
    heatmap: {
      distributed: true,
      enableShades: true,
      borderRadius: 10, // Make corners not rounded
      colorScale: {
        ranges: [
          {
            from: minValue,
            to: maxValue * 0.1,
            color: '#5158FF',
          },
          {
            from: maxValue * 0.1,
            to: maxValue * 0.3,
            color: '#3802D0',
          },
          {
            from: maxValue * 0.3,
            to: maxValue * 0.5,
            color: '#1C008C',
          },
          {
            from: maxValue * 0.5,
            to: maxValue * 0.6,
            color: '#FF9F81',
          },
          {
            from: maxValue * 0.6,
            to: maxValue * 0.8,
            color: '#FF7354',
          },
          {
            from: maxValue * 0.8,
            to: maxValue,
            color: '#FC4F29',
          }
        ]
      },
    }
  },
  colors: ["#A3ADFF", "#5158FF", "#3802D0", "#1C008C", "#13005D", "#FFCBAE", "#FF9F81", "#FF7354", "#FC4F29", "#F2330A"],
  xaxis: {
    categories: [...Array(24).keys()], // Generate an array from 0 to 23
    labels: {
      formatter: function (val) {
        return val; // Ensure the labels are displayed correctly
      },
      style: {
        // colors: '#A3AED0',
        colors: textColorChart,
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    title: {
      text: 'Time of Day',
      style: {
        color: textColorChart,
        fontSize: '12px',
        fontWeight: 'normal',
      },
    },
  },
  yaxis: {
    // show: true,
    // color: 'black',
    labels: {
      show: true,
      style: {
        // colors: '#A3AED0',
        colors: textColorChart,
        fontSize: '14px',
      },
    },
    title: {
      text: 'Day of the Week',
      style: {
        color: textColorChart,
        fontSize: '12px',
        fontWeight: '300',
      },
    },
  },
  dataLabels: {
    enabled: true,
    // formatter: function (val) {
    //   if (totalSum === 0) {
    //     return '0%';
    //   }
    //   const percentage = ((val / totalSum) * 100).toFixed(2); // Calculate percentage
    //   return `${percentage}%`; // Display percentage
    // },
  },
  stroke: {
    show: true,          // Ensure the stroke is enabled
    width: 2,            // Width of the stroke
    colors: [strokeColorChart], // Set the stroke color to white
  },
  tooltip: {
    theme: 'dark', // Default theme
    style: {
      fontSize: '14px',
      background: '#000', // Background color of the tooltip
      color: '#fff', // Font color of the tooltip
    },
  }
}

  return (
    <Card
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
      // {...rest}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
						<MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
							<Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
								Total Orders
							</Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
								by day of the week / time of day
              </Text>
						</MotionFlex>
      </CardHeader>
      <MotionFlex
        direction="column"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
        w="100%"
      >
      <Box minH="360px" mt="auto" mb="0px" pl="15px" w="100%">
        <HeatMapChart
          chartData={transformedData}
          chartOptions={newOptions}
        />
      </Box>
      </MotionFlex>
    </Card>
  );
}
