// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue, 
} from '@chakra-ui/react';
import TreeMapChart from 'components/charts/TreeMapChart';
import React, { useMemo, useEffect } from "react";

// Custom components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';

import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, sumColumns, aggregateDataByColumnV2} from 'utils/dataFunctions';

import { motion } from "framer-motion";
import { TrustedAdvisor } from 'aws-sdk';


const MotionFlex = motion(Flex);

export default function ColumnChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("tickets_insights_treemap", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const strokeColorChart = useColorModeValue('white', '#111c44');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  console.log("currentPeriodData:", currentPeriodData);

  const columnsToSum = useMemo(() => ['total_tickets'], []);

  const aggregatedData = useMemo(() => {
    const result = aggregateDataByColumnV2(currentPeriodData, 'event_name', columnsToSum, 'sum');
    
    if (!Array.isArray(result)) {
      console.error("Error: aggregatedData is not an array", result);
      return [];
    }
    
    // Filter out invalid data
    return result.filter(item => 
      item.event_name && 
      item.total_tickets != null && 
      item.total_tickets > 0
    );
  }, [currentPeriodData, columnsToSum]);

  console.log("aggregatedData (after validation):", aggregatedData);

  const treeMapData = useMemo(() => {
    if (!Array.isArray(aggregatedData)) {
      console.error("Error: aggregatedData is not an array", aggregatedData);
      return [];
    }

    return aggregatedData.map(item => {
      if (typeof item.event_name !== 'string' || typeof item.total_tickets !== 'number') {
        console.error("Error: Invalid data format in aggregatedData", item);
        return { x: '', y: 0 };
      }
      return {
        x: item.event_name,
        y: item.total_tickets
      };
    })
    .sort((a, b) => b.y - a.y); // Sort in descending order by value
  }, [aggregatedData]);

  console.log("treeMapData:", treeMapData);

  // const newOptions = {
  //   chart: {
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   legend: {
  //     show: false
  //   },
  //   plotOptions: {
  //     treemap: {
  //       distributed: true,
  //       enableShades: true,
  //       borderRadius: 0, // Make corners not rounded
  //     }
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     textAnchor: 'middle',
  //     distributed: false,
  //     style: {
  //       fontSize: '16px',
  //       colors: ['#fff']
  //     }
  //   },
  //   colors: ['#2840c2', '#2c46d3', '#3d55d7', '#4e64da', '#5e72de', '#6f81e1', '#8090e5', '#919fe8', '#a2aeec', '#b3bcef'], // Your specific palette
  //   stroke: {
  //     width: 1, // Size of the borders
  //     colors: ['#ffffff'], // Color of the borders
  //   },
  //   tooltip: {
  //     theme: 'dark', // Default theme
  //     style: {
  //       fontSize: '14px',
  //       background: '#000', // Background color of the tooltip
  //       color: '#fff', // Font color of the tooltip
  //     },
  //   }
  // };

  const newOptions = {
    chart: {
      fontFamily: 'Poppins',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      treemap: {
        borderRadius: 0, // Make corners not rounded
        dataLabels: {
          format:  "truncate"
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      style: {
        fontSize: '12px', // Set a fixed font size
        colors: ['#fff'],
      },
      formatter: function(val, opts) {
        const data = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex];
        const total = opts.w.config.series[opts.seriesIndex].data.reduce((acc, item) => acc + item.y, 0);
        const percentage = ((data.y / total) * 100).toFixed(1);
        // return `${data.x} (${percentage}%)`;
        
        // Define a threshold for minimum area size
        const minAreaSize = 100; // Adjust this value as needed
        if (data.y < minAreaSize) {
          return ''; // Return an empty string to hide the label
        }

        return `${data.x}`;
      },
      minFontSize: 20, // Set the minimum font size
      maxFontSize: 20, // Set the maximum font size
    },
    colors: ['#1B0068', '#25008D', '#3507B6', '#4B21F3', '#5451FF', '#8296FF', '#A8BBFF', '#D2D9FF', '#E6E8FF', '#FFFFFF'], // Your specific palette
    stroke: {
      width: 2, // Size of the borders
      colors: [strokeColorChart],
    },
    tooltip: {
      theme: 'dark', // Default theme
      style: {
        fontSize: '14px',
        background: '#000', // Background color of the tooltip
        color: '#fff', // Font color of the tooltip
      },
      y: {
        formatter: function(val, opts) {
          const total = opts.w.globals.seriesTotals.reduce((acc, total) => acc + total, 0);
          const percentage = ((val / total) * 100).toFixed(1);
          return `${val} (${percentage}%)`;
        }
      }
    },
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
      // {...rest}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
						<MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
							<Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
								Tickets Sold
							</Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
								by event name
              </Text>
						</MotionFlex>
      </CardHeader>
      <MotionFlex direction='column' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Box minH="260px" mt="auto" mb="0px" pl="15px" w="100%" h="100%">
          <TreeMapChart
            chartData={treeMapData}
            chartOptions={newOptions}
          />
        </Box>
      </MotionFlex>
    </Card>
  );
}
