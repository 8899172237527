// UsageDisplay.js
import React, { useEffect } from "react";
import { Flex, Text, Icon } from "@chakra-ui/react";
import { SiLightning } from "react-icons/si";
import { usePrompt } from "contexts/PromptContext"; // Assuming the context is in contexts/PromptContext
import { displayCredits } from "utils/creditsUsageApi"; // Import your new function

export default function UsageDisplay() {
    const { usageTotalTokens, setUsageTotalTokens } = usePrompt(); // Access usageTotalTokens from context
    const clientId = sessionStorage.getItem('company') || localStorage.getItem('company');
    const userId = sessionStorage.getItem('userID') || localStorage.getItem('userID');
    const sparkCredits = sessionStorage.getItem('sparkCredits') || localStorage.getItem('sparkCredits');
    const startDate = sessionStorage.getItem('startDate') || localStorage.getItem('startDate');

    console.log("sparkCredits:", sparkCredits);
    console.log("startDate:", startDate);

    useEffect(() => {
        const fetchUsageTokens = async () => {
        try {
            const { usageTotalTokens: updatedTokens } = await displayCredits(clientId, userId);
            const calculatedTokens = parseFloat(sparkCredits - (updatedTokens.toFixed(2) / 0.01));
            setUsageTotalTokens(calculatedTokens > 0 ? calculatedTokens : 0);
        } catch (error) {
            console.error("Error fetching usage tokens:", error);
        }
        };

        fetchUsageTokens();

        // Optionally, add a polling interval if you need regular updates
        const interval = setInterval(fetchUsageTokens, 60000); // Poll every 60 seconds
        return () => clearInterval(interval); // Clear the interval when component unmounts
    }, [clientId, userId, setUsageTotalTokens]);

    return (
        <Flex align="center">
        <Text fontSize="lg" fontWeight="bold" ml={2} mr={1} >
            {usageTotalTokens}
        </Text>
        <Icon as={SiLightning} color="yellow.500" w={5} h={5} mr={2} />
        </Flex>
    );
}
