// // LocationContext.js
// // React Imports
// import React, { createContext, useState, useContext } from 'react';

// const LocationContext = createContext();

// export const LocationProvider = ({ children }) => {
//   const [location, setLocation] = useState("Las Vegas");

//   return (
//     <LocationContext.Provider value={{ location, setLocation }}>
//       {children}
//     </LocationContext.Provider>
//   );
// };

// export const useLocation = () => useContext(LocationContext);


// LocationContext.js
import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { useAuth } from 'AuthContext';
// import { locationsList } from '../variables/locationsList';
import locationsList from '../variables/locationsList';

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const { company, availableLocations } = useAuth();

  // Get the locations for the current company, defaulting to an empty array if the company is not found
  const companyLocations = useMemo(() => {
    if (availableLocations?.includes('All')) {
      return locationsList[company] ? [...locationsList[company], 'All'] : []; // Show all locations if 'All' is in availableLocations
    }
    return (locationsList[company] ? [...locationsList[company], 'All'] : []).filter(location =>
      availableLocations?.includes(location)
    );
  }, [company, availableLocations, locationsList]);
  
  // Initially set location to an empty string
  const [location, setLocation] = useState("");
  const [localLocation, setLocalLocation] = useState("");
  const [defaultLocation, setDefaultLocation] = useState("");

  // Update the location once companyLocations is available
  useEffect(() => {
    if (companyLocations.length > 0) {
      setLocation(companyLocations[0]);
      setLocalLocation(companyLocations[0]);
      setDefaultLocation(companyLocations[0]);
    }
  }, [companyLocations]);

  return (
    <LocationContext.Provider value={{ location, setLocation, companyLocations, localLocation, setLocalLocation, defaultLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => useContext(LocationContext);