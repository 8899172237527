// creditsUsageApi.js
export async function displayCredits(clientId, userId ) {

  const startDate = sessionStorage.getItem('startDate') || localStorage.getItem('startDate');
  const endDate='2024-11-01';

  console.log("clientId:", clientId);
  console.log("userId:", userId);
  console.log("startDate:", startDate);
  console.log("endDate:", endDate);

  try {
    let usageTotalTokens = 0;  // Default to empty array if no previous conversation exists
    try {
      const usageResponse = await fetch(`https://xeuscloud-lightning-api.somt8tcq7b3bs.us-east-2.cs.amazonlightsail.com/sum_usage_total_tokens?client_id=${clientId}&user_id=${userId}&start_date=${startDate}&end_date=${endDate}`);
      // const usageResponse = await fetch(`http://localhost:8000/sum_usage_total_tokens?client_id=${clientId}&user_id=${userId}&start_date=${startDate}&end_date=${endDate}`);

      if (usageResponse.ok) {
        const usageJsonResponse = await usageResponse.json();
        console.log("Usage JSON Response:", usageJsonResponse);

        usageTotalTokens = usageJsonResponse.total_tokens_sum || 0;
        console.log("Total tokens used:", usageTotalTokens);

      } else if (usageResponse.status === 404) {
        console.warn("No usage data found within the specified date range, proceeding with usageTotalTokens set to 0.");
      } else {
        console.warn(`Failed to retrieve usage data. Status: ${usageResponse.status}`);
      }
    } catch (error) {
      console.warn("Error while retrieving usage data, proceeding with usageTotalTokens set to 0:", error.message);
    }
  
    console.log("Usage updated successfully!");

    return { usageTotalTokens };

  } catch (error) {
    console.error("Error during the API flow:", error.message);
    throw error;
  }
}
