const getCoordinatesFromZipcode = async (zipcode, city, state, country) => {
    const locationQuery = `${encodeURIComponent(zipcode)},${encodeURIComponent(city)},${encodeURIComponent(state)},${encodeURIComponent(country)}`;
    const url = `https://photon.komoot.io/api/?q=${locationQuery}&limit=1`;
    const response = await fetch(url);
    const data = await response.json();
    
    if (data.features.length > 0) {
        const { coordinates } = data.features[0].geometry;
        // console.log(`Found coordinates ${coordinates} for ${zipcode} in ${city}, ${state}, ${country}`);
        return { lat: coordinates[1], lng: coordinates[0] };
    } else {
        console.log(`No location found for zipcode: ${zipcode} in ${city}, ${state}, ${country}`);
    }
};

const getGeoJsonFromZipcode = async (zipcode, city, state, country) => {
    const locationQuery = `${encodeURIComponent(city)},${encodeURIComponent(state)},${encodeURIComponent(country)}`;
    const url = `https://nominatim.openstreetmap.org/search?postalcode=${encodeURIComponent(zipcode)}&q=${locationQuery}&format=json&polygon_geojson=1`;
    
    try {
        const response = await fetch(url);
        const data = await response.json();
        
        if (data.length > 0) {
            const geometry = data[0].geojson;
            const { lat, lon: lng } = data[0];
            console.log(`Found location with center coordinates (${lat}, ${lng})`);
            // If you want the polygon (multi-polygon) data
            if (geometry) {
                console.log('Polygon coordinates:', geometry);
                return { lat, lng, geometry };
            } else {
                // Fallback to center coordinates if no polygon
                return { lat, lng };
            }
        } else {
            console.log(`No location found for zipcode: ${zipcode} in ${city}, ${state}, ${country}`);
        }
    } catch (error) {
        console.error('Error fetching location data:', error);
    }
};

const getGeolocationByLocation = async (location) => {
    const response = await fetch(
        `https://photon.komoot.io/api/?q=${encodeURIComponent(location)}&limit=1`
    );
    const data = await response.json();
    
    if (data.features.length > 0) {
        const { coordinates } = data.features[0].geometry;
        return { lat: coordinates[1], lng: coordinates[0] };
    } else {
        console.log(`No location found for ${location}`);
        return null;
    }
};

const convertToGeoJSON = (dataArray) => {
    const features = dataArray.map((item, index) => ({
        type: 'Feature',
        id: index.toString(),
        properties: {
            zipcode: item.zipcode,
            city: item.city,
            state: item.state,
            tk_total_orders: item.tk_total_orders,
            tk_total_tickets: item.tk_total_tickets,
            tk_ticket_gross_revenues: item.tk_ticket_gross_revenues,
            tk_ticket_net_revenues: item.tk_ticket_net_revenues,
        },
        geometry: item.geometry
    }));

    return {
        type: 'FeatureCollection',
        features
    };
};

const stateGeoJSONMap = {
    "AL": "/al-zipcodes.geojson",
    "AK": "/ak-zipcodes.geojson",
    "AZ": "/az-zipcodes.geojson",
    "AR": "/ar-zipcodes.geojson",
    "CA": "/ca-zipcodes.geojson",
    "CO": "/co-zipcodes.geojson",
    "CT": "/ct-zipcodes.geojson",
    "DC": "/dc-zipcodes.geojson",
    "DE": "/de-zipcodes.geojson",
    "FL": "/fl-zipcodes.geojson",
    "GA": "/ga-zipcodes.geojson",
    "HI": "/hi-zipcodes.geojson",
    "ID": "/id-zipcodes.geojson",
    "IL": "/il-zipcodes.geojson",
    "IN": "/in-zipcodes.geojson",
    "IA": "/ia-zipcodes.geojson",
    "KS": "/ks-zipcodes.geojson",
    "KY": "/ky-zipcodes.geojson",
    "LA": "/la-zipcodes.geojson",
    "ME": "/me-zipcodes.geojson",
    "MD": "/md-zipcodes.geojson",
    "MA": "/ma-zipcodes.geojson",
    "MI": "/mi-zipcodes.geojson",
    "MN": "/mn-zipcodes.geojson",
    "MS": "/ms-zipcodes.geojson",
    "MO": "/mo-zipcodes.geojson",
    "MT": "/mt-zipcodes.geojson",
    "NE": "/ne-zipcodes.geojson",
    "NV": "/nv-zipcodes.geojson",
    "NH": "/nh-zipcodes.geojson",
    "NJ": "/nj-zipcodes.geojson",
    "NM": "/nm-zipcodes.geojson",
    "NY": "/ny-zipcodes.geojson",
    "NC": "/nc-zipcodes.geojson",
    "ND": "/nd-zipcodes.geojson",
    "OH": "/oh-zipcodes.geojson",
    "OK": "/ok-zipcodes.geojson",
    "OR": "/or-zipcodes.geojson",
    "PA": "/pa-zipcodes.geojson",
    "RI": "/ri-zipcodes.geojson",
    "SC": "/sc-zipcodes.geojson",
    "SD": "/sd-zipcodes.geojson",
    "TN": "/tn-zipcodes.geojson",
    "TX": "/tx-zipcodes.geojson",
    "UT": "/ut-zipcodes.geojson",
    "VT": "/vt-zipcodes.geojson",
    "VA": "/va-zipcodes.geojson",
    "WA": "/wa-zipcodes.geojson",
    "WV": "/wv-zipcodes.geojson",
    "WI": "/wi-zipcodes.geojson",
    "WY": "/wy-zipcodes.geojson"
};

export {
    getCoordinatesFromZipcode,
    getGeolocationByLocation,
    getGeoJsonFromZipcode,
    convertToGeoJSON,
    stateGeoJSONMap
}
