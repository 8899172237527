import a_bunch_of_stuff__new_york_city from "assets/img/cities/a_bunch_of_stuff__new_york_city.jpg";
import arcadia_earth__toronto from "assets/img/cities/arcadia_earth__toronto.jpg";
import beat_the_bomb__atlanta from "assets/img/cities/beat_the_bomb__atlanta.jpg";
import beat_the_bomb__brooklyn from "assets/img/cities/beat_the_bomb__brooklyn.jpg";
import beat_the_bomb__philadelphia from "assets/img/cities/beat_the_bomb__philadelphia.jpg";
import beat_the_bomb__washington from "assets/img/cities/beat_the_bomb__washington.jpg";
import illuminarium__las_vegas from "assets/img/cities/illuminarium__las_vegas.jpg";
import illuminarium__toronto from "assets/img/cities/illuminarium__toronto.jpg";
import sloomoo_institute__atlanta from "assets/img/cities/sloomoo_institute__atlanta.jpg";
import sloomoo_institute__chicago from "assets/img/cities/sloomoo_institute__chicago.jpg";
import sloomoo_institute__houston from "assets/img/cities/sloomoo_institute__houston.jpg";
import sloomoo_institute__los_angeles from "assets/img/cities/sloomoo_institute__los_angeles.jpg";
import sloomoo_institute__new_york_city from "assets/img/cities/sloomoo_institute__new_york_city.jpg";

import chicago from "assets/img/cities/chicago.jpg";
import los_angeles from "assets/img/cities/los-angeles.jpg";

const locationImages = {
    'illuminarium': {
        'las_vegas': illuminarium__las_vegas,
        'toronto': illuminarium__toronto,
        // 'chicago': chicago
    },
    'sloomoo_institute': {
        'atlanta': sloomoo_institute__atlanta,
        'chicago': sloomoo_institute__chicago,
        'houston': sloomoo_institute__houston,
        'new_york_city': sloomoo_institute__new_york_city,
        'los_angeles': sloomoo_institute__los_angeles,
    },
    'beat_the_bomb': {
        'atlanta': beat_the_bomb__atlanta,
        'brooklyn': beat_the_bomb__brooklyn,
        'philadelphia': beat_the_bomb__philadelphia,
        'washington': beat_the_bomb__washington,
    },
    'arcadia_earth': {
        'toronto': arcadia_earth__toronto
    },
    'a_bunch_of_stuff': {
        'a_bunch_of_stuff__new_york_city': a_bunch_of_stuff__new_york_city
    }
};

export default locationImages;
