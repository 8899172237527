// GoogleStackedBarChartViz.js
// React / Chakra Imports
import {
    Box,
    Flex,
    Text,
    useColorModeValue, 
  } from '@chakra-ui/react';
import React, { useMemo } from "react";
// Components
import StackedBarChart from 'components/charts/StackedBarChart';
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, aggregateDataByColumnV2 } from 'utils/dataFunctions';
import { formatNumber } from 'utils/dataFormatter';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

  export default function StackedBarChartViz() {
    const { location } = useLocation();
    const { dateRange } = useDateRange();
    const { currentPeriodData } = useFilteredData("google_campaigns", location, dateRange);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const shadow = useColorModeValue(
      '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
      '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
    );

    const columnsToSumGoogle = useMemo(() => ['gg_spend', 'gg_impressions', 'gg_clicks', 'gg_purchases', 'gg_purchases_value'], []);

    const aggregatedDataGoogle = useMemo(() => aggregateDataByColumnV2(currentPeriodData, 'gg_campaign_type', columnsToSumGoogle, 'sum'), [currentPeriodData, columnsToSumGoogle]);

    console.log("aggregatedDataGoogle:", aggregatedDataGoogle);

    const columnsToKeepGoogle = useMemo(() => ['gg_campaign_type', 'gg_spend', 'gg_impressions', 'gg_clicks', 'gg_purchases', 'gg_purchases_value'], []);
    const currentPeriodDataByColumnsGoogle = useMemo(() => filterColumns(aggregatedDataGoogle, columnsToKeepGoogle), [aggregatedDataGoogle, columnsToKeepGoogle]);

    console.log("currentPeriodDataByColumnsGoogle:", currentPeriodDataByColumnsGoogle);

    const stackedBarChartData = useMemo(() => 
      currentPeriodDataByColumnsGoogle
        .filter(item => item.gg_campaign_type && item.gg_campaign_type !== "null")
        .map(item => ({
          name: item.gg_campaign_type.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, c => c.toUpperCase()), // Format the name
          data: [
            item.gg_spend,
            item.gg_impressions,
            item.gg_clicks,
            item.gg_purchases,
            item.gg_purchases_value,
          ]
        }))
        .reverse()
    , [currentPeriodDataByColumnsGoogle]);

    const getChartData = useMemo(() => (stackedBarChartData) => {
      const labels = ['Ad Spend', 'Impressions', 'Clicks', 'Purchases', 'Purchase Value'];
      const campaignTypes = stackedBarChartData.map(item => item.name);

      // Summed values across all campaign types
      const summedValues = labels.map((label, index) =>
          stackedBarChartData.reduce((sum, item) => sum + item.data[index], 0)
      );

      return labels.map((label, index) => {
          const dataByCampaign = {};

          campaignTypes.forEach((campaignType) => {
              const campaignData = stackedBarChartData.find(item => item.name === campaignType)?.data[index] || 0;
              const percentage = Math.round((campaignData / summedValues[index]) * 100) || 0;

              dataByCampaign[campaignType] = campaignData;
              dataByCampaign[`${campaignType}Percentage`] = percentage;
          });

          return {
              label,
              ...dataByCampaign,
          };
      });
  }, []);

    const chartData = useMemo(() => getChartData(stackedBarChartData), [getChartData, stackedBarChartData]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div className="custom-tooltip" style={{ backgroundColor: "rgba(28, 30, 37, 0.9)", color: "white", padding: "10px", borderRadius: "5px" }}>
                    <b>{`${data.label}`}</b>
                    {Object.keys(data)
                      .filter((key) => key !== "label" && !key.includes('Percentage'))
                      .map((key, index) => (
                          <p key={key} style={{ 
                              color: colorPalette[index % colorPalette.length], 
                              fontSize: 14 
                          }}>
                              {`${key}: ${formatNumber(Math.round(data[key]))}`}
                          </p>
                      ))}
                </div>
            );
        }
        return null;
    };

    const colorPalette = [
      '#5158FF',  // Blue
      '#00E5D1',  // Green
      '#FFCBAE',  // Yellow
      '#A3ADFF', // Purple
      '#43487B',  // Neutral
    ];

    const bars = useMemo(() => 
      stackedBarChartData.map((item, index) => ({
        dataKey: item.name,
        name: item.name,
        labelDataKey: `${item.name}Percentage`,
        fill: colorPalette[index % colorPalette.length], // Assign color based on index
      }))
    , [stackedBarChartData]);

    return (
      <Card
        flexDirection="column"
        w="100%"
        boxShadow={shadow}
        // {...rest}
      >
        <CardHeader mt='10px' mb='10px' ps='22px'>
            <MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
                  Key Metrics
                </Text>
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                  by campaign type
                </Text>
            </MotionFlex>
        </CardHeader>
        <MotionFlex
          direction="column"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
          w="100%"
        >
          <Box minH="260px" mt="auto" mb="0px" pr="15px" pl="15px" w="100%">
            <StackedBarChart
              chartData={chartData}
              customTooltip={<CustomTooltip />}
              bars={bars}
            />
          </Box>
        </MotionFlex>
      </Card>
    );
  }
  