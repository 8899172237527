// google.jsx
// React / Chakra Imports
import React from "react";
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
// Components
import { GoogleSpend, GooglePurchasesValue, GoogleROAS, GoogleClicks, GoogleCTR, GoogleCPC } from "./components/google/GoogleHighLevelMetrics";
import GoogleLineChartViz from "./components/google/GoogleLineChartViz";
import GoogleStackedBarChartViz from "./components/google/GoogleStackedBarChartViz";
import GoogleTableViz from "./components/google/GoogleTableViz";
// Contexts
import { DateFormatProvider } from "contexts/DateFormatContext";

export default function Default() {
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Flex
      direction={{ base: "column", xl: "row" }}
      pt={{ base: "50px", md: "80px", xl: "80px" }}
      width="100%"
      mx="auto"
    >
      <Flex direction='column' width='stretch' >
        <Grid
          mb='20px'
          gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "repeat(6, 1fr)" }}
          gap='20px'
          display={{ base: "block", lg: "grid" }}
          width='100%'
        >
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 1 / 2 / 2" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <GoogleSpend />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 2 / 2 / 3" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <GooglePurchasesValue />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 3 / 2 / 4" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <GoogleROAS />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 4 / 2 / 5" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <GoogleClicks />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 5 / 2 / 6" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <GoogleCTR />
          </Flex>
          <Flex 
            gridArea={{ base: "auto", "2xl": "1 / 6 / 2 / 7" }}
            mt={{ base: "20px", lg: "0px" }}
          >
            <GoogleCPC />
          </Flex>
        </Grid>
        <Grid
          mb='20px' 
          gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "720fr 350fr" }}
          gap='20px'
          display={{ base: "block", lg: "grid" }}
          width='100%'
        >
          <Flex gridArea={{ base: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
            <GoogleLineChartViz width='100%'/>
          </Flex>
          <Flex gridArea={{ base: "2 / 1 / 3 / 3", "2xl": "1 / 2 / 2 / 3" }}>
            <GoogleStackedBarChartViz />
          </Flex>
        </Grid>
        <Grid
          mb='20px'
          gridTemplateColumns={{ base: "1fr" }}
          width='100%'
          boxShadow={shadow}
        >
          <Flex gridArea={{ base: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }} overflowX="scroll" overflow='auto'>
            <DateFormatProvider>
              <GoogleTableViz />
            </DateFormatProvider>
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
}