import React, { useState, useEffect } from 'react';
import { Box, Button, useColorModeValue } from '@chakra-ui/react';
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useDataV2 } from 'utils/dataHooks';
import { usePrompt } from 'contexts/PromptContext'; 
import SelectDataset from './DataConfiguration/SelectDataset';
import Filters from './DataConfiguration/Filters';

const viewPathMap = {
  'meta': 'meta',
  'google': 'google',
  'ticketing': 'ticketing',
};

const DataConfiguration = ({ onDataConfirmSelection, isRestartTriggered, isDataLoading, setIsDataLoading }) => { 
  const defaultDataset = ['meta'];
  
  const { localLocation, setLocalLocation, defaultLocation } = useLocation();
  const { localDateRange, setLocalDateRange, defaultDateRange } = useDateRange();
  const { setDatasetPrompt, selectedDataset, setSelectedDataset, selectedLocation, setSelectedLocation, selectedDateRange, setSelectedDateRange, confirmedDataset, setConfirmedDataset, confirmedLocation, setConfirmedLocation, confirmedDateRange, setConfirmedDateRange } = usePrompt();
  const [hasInitializedDefaultValues, setHasInitializedDefaultValues] = useState(false);

  const metaData = useDataV2('meta', selectedLocation, selectedDateRange);
  const googleData = useDataV2('google', selectedLocation, selectedDateRange);
  const ticketingData = useDataV2('ticketing', selectedLocation, selectedDateRange);

  const handleConfirmData = () => {
    setConfirmedDataset(selectedDataset);
    setConfirmedLocation(selectedLocation);
    setConfirmedDateRange(selectedDateRange);

    const combinedData = [];

    if (selectedDataset.includes('meta') && metaData.length > 0) {
      combinedData.push({ 'meta': metaData });
    }
    if (selectedDataset.includes('google') && googleData.length > 0) {
      combinedData.push({ 'google': googleData });
    }
    if (selectedDataset.includes('ticketing') && ticketingData.length > 0) {
      combinedData.push({ 'ticketing': ticketingData });
    }
    console.log('combined data: ', combinedData);

    const datasetPrompt = JSON.stringify(combinedData, null, 2); // Convert filtered data to JSON string
    setDatasetPrompt(datasetPrompt); // Set dataset prompt for initial use

    // if (onDataConfirmSelection) { // For setting the data confirmation message bubble chat
    //   onDataConfirmSelection(selectedDataset, selectedLocation, selectedDateRange);
    // }
  };

  useEffect(() => {
    if (isRestartTriggered) {
      setSelectedDataset(defaultDataset);
      setLocalLocation(defaultLocation);
      setSelectedLocation(defaultLocation);
      setLocalDateRange(defaultDateRange);
      setSelectedDateRange(defaultDateRange);
      setConfirmedDataset(defaultDataset);
      setConfirmedLocation(defaultLocation);
      setConfirmedDateRange(defaultDateRange);
    }
  }, [isRestartTriggered]);

  useEffect(() => {
    setIsDataLoading(metaData.length === 0 || googleData.length === 0 || ticketingData.length === 0);
    if (!hasInitializedDefaultValues && selectedLocation && selectedDateRange && metaData.length > 0 && googleData.length > 0 &&ticketingData.length > 0) {
      handleConfirmData();
      setHasInitializedDefaultValues(true);
    }
  }, [selectedLocation, selectedDateRange, handleConfirmData, metaData, googleData, ticketingData]);

  useEffect(() => {
    if (localLocation) {
      setSelectedLocation(localLocation);
    }
  }, [localLocation]);

  useEffect(() => {
    if (localDateRange) {
      setSelectedDateRange(localDateRange);
    }
  }, [localDateRange]);

  const handleDatasetSelection = (dataset) => {
    if (selectedDataset.includes(dataset)) {
      setSelectedDataset(selectedDataset.filter(item => item !== dataset));
    } else {
      setSelectedDataset([...selectedDataset, dataset]);
    }
  };

  const handleDateRangeSelection = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handleLocationSelection = (location) => {
    setSelectedLocation(location);
  };

  const sidebarBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const sidebarRadius = '15px';

  const arraysEqualAsSets = (arr1, arr2) => {
    return arr1.length === arr2.length && arr1.every(item => arr2.includes(item));
  };

  const isConfirmDisabled = 
    selectedDataset.length === 0 ||
    isDataLoading ||
    (arraysEqualAsSets(selectedDataset, confirmedDataset) && 
    selectedLocation === confirmedLocation && 
    JSON.stringify(selectedDateRange) === JSON.stringify(confirmedDateRange));

  return (
    <Box
      bg={sidebarBg}
      borderRadius={sidebarRadius}
      boxShadow={shadow}
      overflowX="hidden"
      fontSize="sm"
    >
      <Box p={4}>
        <SelectDataset
          viewPathMap={viewPathMap}
          selectedDataset={selectedDataset}
          handleDatasetSelection={handleDatasetSelection}
          fontSize="xs"
          multiple
        />

        <Filters
          dateFilterCallback={handleDateRangeSelection}
          locationFilterCallback={handleLocationSelection}
          fontSize="xs"
        />

        <Button
          mt={4}
          backgroundColor="#5158FF"
          color="white"
          _hover={{ backgroundColor: "#3d44cc" }}
          onClick={handleConfirmData}
          isDisabled={isConfirmDisabled}
          w="full"
          fontSize="xs"
        >
          {isDataLoading ? "Loading dataset..." : "Confirm Dataset"}
        </Button>
      </Box>
    </Box>
  );
};

export default DataConfiguration;
