// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue, 
} from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';
import React, { useMemo } from "react";

// Custom components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
import {
  columnChartOptionsOverview,
} from 'variables/charts';

import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useFilteredData } from 'utils/dataHooks';
import { aggregateDataByDate} from 'utils/dataFunctions';

// Motion
import { motion } from "framer-motion";
import { anatomy } from '@chakra-ui/theme-tools';

const MotionFlex = motion(Flex);

// Assume 'dayOfWeekMapping' is defined earlier in the code
const dayOfWeekMapping = {
  'Sunday': 6,
  'Monday': 0,
  'Tuesday': 1,
  'Wednesday': 2,
  'Thursday': 3,
  'Friday': 4,
  'Saturday': 5
};

// Function to get the day of the week from a date string
const getDayOfWeek = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { weekday: 'long' });
};

export default function ColumnChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const startDay = useMemo(() => getDayOfWeek(dateRange[0]), [dateRange]);
  const endDay = useMemo(() => getDayOfWeek(dateRange[1]), [dateRange]);
  const { currentPeriodData } = useFilteredData("tickets_insights_barchart_dow", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorChart = useColorModeValue('secondaryGray.500', 'white');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  console.log("currentPeriodData:", currentPeriodData);

  const customSortOrder = useMemo(() => {
    const startIndex = dayOfWeekMapping[startDay];
    const endIndex = dayOfWeekMapping[endDay];

    if (startIndex <= endIndex) {
      return Object.keys(dayOfWeekMapping).slice(startIndex, endIndex + 1);
    } else {
      return [
        ...Object.keys(dayOfWeekMapping).slice(startIndex),
        ...Object.keys(dayOfWeekMapping).slice(0, endIndex + 1),
      ];
    }
  }, [startDay, endDay]);

  // Step 2: Define the columns to sum
  const columnsToSum = useMemo(() => ['total_tickets'], []);

  const aggregatedData = useMemo(() => {
    const data = aggregateDataByDate(currentPeriodData, 'day_of_week', columnsToSum, 'sum');

    // return data.sort((a, b) => 
    //   customSortOrder.indexOf(a.date) - customSortOrder.indexOf(b.date)
    // );

    return data.sort((a, b) => 
      dayOfWeekMapping[a.date] - dayOfWeekMapping[b.date]
    );

  }, [currentPeriodData, columnsToSum]);

  console.log("aggregatedData:", aggregatedData);

  const columnChartData = useMemo(() => 
    [
    {
      name: 'Tickets Sold',
      data: aggregatedData.map(item => (item.total_tickets ?? 0) ),
    },
  ]
  , [aggregatedData]);

  console.log("columnChartData:", columnChartData);

  const columnChartCategories = useMemo(() => 
    aggregatedData
    .map(item => item.date)
    , [aggregatedData]);

  console.log("columnChartCategories:", columnChartCategories);

  const newOptions = {
    ...columnChartOptionsOverview,
    tooltip: {
      ...columnChartOptionsOverview.tooltip,
      y: {
        formatter: function (value, { seriesIndex, w }) {
          const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
          const percentage = ((value / total) * 100).toFixed(2);
          return `${value} (${percentage}%)`;
        }
      }
    },
    plotOptions: {
      ...columnChartOptionsOverview.plotOptions,
      bar: {
        horizontal: true,
        borderRadius: 5,
        dataLabels: {
          position: 'top', // This will position the data labels at the end of the bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [textColorChart],
        fontSize: '13px',
      },
      offsetX: 30,
    },
    xaxis: {
      categories: columnChartCategories,
      show: false,
      min: 0, // Force y-axis to start at 0
      // max: newmaxOrders,
      max: Math.max(...columnChartData[0].data) * 1.5,
      labels: {
        show: false,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      ...columnChartOptionsOverview.yaxis,
      show: true,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: textColorChart,
          fontSize: '14px',
        },
      },
    },
    fill: {
      colors: ['#00E5D1'], // Set the color of the bars to white
    },
  };

  // Chakra Color Mode
  // const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Card
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
      // {...rest}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
        <MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
            Tickets Sold
          </Text>
          <Text fontSize='sm' color='gray.400' fontWeight='normal'>
            by day of the week
          </Text>
        </MotionFlex>
      </CardHeader>
      <MotionFlex direction='column' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Box minH="260px" mt="auto" mb="0px" pl="15px" w="100%">
          <BarChart
            chartData={columnChartData}
            chartOptions={newOptions}
          />
        </Box>
      </MotionFlex>
    </Card>
  );
}


// import React, { useMemo } from "react";
// // Chakra imports
// import {
//   Box,
//   Flex,
//   Text,
//   useColorModeValue,
// } from '@chakra-ui/react';
// import BarChart from 'components/charts/BarChart';

// // Custom components
// import Card from 'components/card/Card';
// import CardHeader from 'components/card/CardHeader';
// import {
//   columnChartOptionsOverview,
// } from 'variables/charts';

// import { useLocation } from 'contexts/LocationContext';
// import { useDateRange } from 'contexts/DateRangeContext';
// import { useFilteredData } from 'utils/dataHooks';
// import { aggregateDataByDate } from 'utils/dataFunctions';

// // Motion
// import { motion } from "framer-motion";

// const MotionFlex = motion(Flex);

// export default function ColumnChartViz() {
//   const { location } = useLocation();
//   const { dateRange } = useDateRange();
//   const { currentPeriodData } = useFilteredData("tickets_insights_barchart_dow", location, dateRange);
  
//   const textColor = useColorModeValue('secondaryGray.900', 'white');
//   const textColorChart = useColorModeValue('secondaryGray.500', 'white');
//   const shadow = useColorModeValue(
//     '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
//     '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
//   );

//   console.log("currentPeriodData:", currentPeriodData);

//   const columnsToSum = useMemo(() => ['total_tickets'], []);

//   // Day of week mapping
//   const dayOfWeekMapping = {
//     'Sunday': 0,
//     'Monday': 1,
//     'Tuesday': 2,
//     'Wednesday': 3,
//     'Thursday': 4,
//     'Friday': 5,
//     'Saturday': 6
//   };

//   const aggregatedData = useMemo(() => {
//     if (!currentPeriodData) return [];

//     const data = aggregateDataByDate(currentPeriodData, 'day_of_week', columnsToSum, 'sum');
    
//     // Ensure the day_of_week exists and is valid before sorting
//     return data.filter(item => dayOfWeekMapping[item.day_of_week] !== undefined)
//                .sort((a, b) => dayOfWeekMapping[a.day_of_week] - dayOfWeekMapping[b.day_of_week]);
//   }, [currentPeriodData, columnsToSum]);

//   console.log("aggregatedData:", aggregatedData);

//   const columnChartData = useMemo(() => {
//     if (!aggregatedData || aggregatedData.length === 0) return [{ name: 'Tickets Sold', data: [] }];

//     return [
//       {
//         name: 'Tickets Sold',
//         data: aggregatedData.map(item => item.total_tickets || 0),
//       },
//     ];
//   }, [aggregatedData]);

//   console.log("columnChartData:", columnChartData);

//   const columnChartCategories = useMemo(() => {
//     if (!aggregatedData || aggregatedData.length === 0) return [];

//     return aggregatedData.map(item => item.day_of_week);
//   }, [aggregatedData]);

//   console.log("columnChartCategories:", columnChartCategories);

//   const newOptions = {
//     ...columnChartOptionsOverview,
//     tooltip: {
//       ...columnChartOptionsOverview.tooltip,
//       y: {
//         formatter: function (value, { seriesIndex, w }) {
//           const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
//           const percentage = total ? ((value / total) * 100).toFixed(2) : '0.00';
//           return `${value} (${percentage}%)`;
//         }
//       }
//     },
//     plotOptions: {
//       ...columnChartOptionsOverview.plotOptions,
//       bar: {
//         horizontal: true,
//         borderRadius: 5,
//         dataLabels: {
//           position: 'top', // This will position the data labels at the end of the bars
//         },
//       },
//     },
//     dataLabels: {
//       enabled: true,
//       style: {
//         colors: [textColorChart],
//         fontSize: '13px',
//       },
//       offsetX: 30,
//     },
//     xaxis: {
//       categories: columnChartCategories,
//       show: false,
//       min: 0, // Force y-axis to start at 0
//       max: Math.max(...(columnChartData[0]?.data || [0])) * 1.5,
//       labels: {
//         show: false,
//         style: {
//           colors: '#A3AED0',
//           fontSize: '14px',
//           fontWeight: '500',
//         },
//       },
//       axisBorder: {
//         show: false,
//       },
//       axisTicks: {
//         show: false,
//       },
//     },
//     yaxis: {
//       ...columnChartOptionsOverview.yaxis,
//       show: true,
//       color: 'black',
//       labels: {
//         show: true,
//         style: {
//           colors: textColorChart,
//           fontSize: '14px',
//         },
//       },
//     },
//     fill: {
//       colors: ['#00E5D1'], // Set the color of the bars to white
//     },
//   };

//   return (
//     <Card
//       flexDirection="column"
//       w="100%"
//       boxShadow={shadow}
//     >
//       <CardHeader mt='10px' mb='10px' ps='22px'>
//         <MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
//           <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
//             Tickets Sold
//           </Text>
//           <Text fontSize='sm' color='gray.400' fontWeight='normal'>
//             by day of the week
//           </Text>
//         </MotionFlex>
//       </CardHeader>
//       <MotionFlex direction='column' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
//         <Box minH="260px" mt="auto" mb="0px" pl="15px" w="100%">
//           <BarChart
//             chartData={columnChartData}
//             chartOptions={newOptions}
//           />
//         </Box>
//       </MotionFlex>
//     </Card>
//   );
// }
