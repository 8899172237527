// locationsList.js
const locationsList = {
    "illuminarium": [
        "Las Vegas", 
        "Toronto", 
        // "Chicago",
    ],
    "sloomoo_institute": [
        "Atlanta", 
        "Chicago", 
        "Houston", 
        "New York City", 
        "Los Angeles",
    ],
    "beat_the_bomb": [
        "Atlanta", 
        "Brooklyn", 
        "Washington",
    ],
    "arcadia_earth": [
        "Toronto",
    ],
    "a_bunch_of_stuff": [
        "New York City",
    ],
    "epic_waters": [
        "Texas",
    ],
};

// Check if module is available (Node.js environment) and export the variable
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = locationsList;
}
