import React from 'react';
import { Menu, MenuButton, MenuList, Button, Box, Text, Icon, useDisclosure } from '@chakra-ui/react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'assets/css/MiniCalendar.css';
import { MdChevronLeft, MdChevronRight, MdCalendarToday } from 'react-icons/md';
import { useDateRange } from 'contexts/DateRangeContext';

const LocalDateFilter = ({ callback = () => {}, fontSize = "sm" }) => {
  const { localDateRange, setLocalDateRange } = useDateRange();
  const { isOpen, onOpen, onClose } = useDisclosure(); // Get onClose function

  const onChange = (newRange) => {
    setLocalDateRange(newRange);
    callback();
    onClose(); // Close the menu after selection
  };

  return (
    <Box width="100%">
      <Menu isOpen={isOpen} onClose={onClose}>
        <MenuButton 
          as={Button} 
          backgroundColor="#5158FF" 
          color="white" 
          _hover={{ backgroundColor: "#3d44cc" }}
          width="100%"
          fontSize={fontSize}
          onClick={isOpen ? onClose : onOpen}
          leftIcon={<Icon as={MdCalendarToday} w={4} h={4} />}
        >
          {`${localDateRange[0].toLocaleDateString()} - ${localDateRange[1].toLocaleDateString()}`}
        </MenuButton>
        <MenuList>
          <Box padding="10px">
            <Calendar 
              onChange={onChange} 
              value={localDateRange} 
              selectRange={true}
              returnValue="range"
              view={"month"}
              tileContent={<Text color='brand.500'></Text>}
              prevLabel={<Icon as={MdChevronLeft} w='24px' h='24px' mt='4px' />}
              nextLabel={<Icon as={MdChevronRight} w='24px' h='24px' mt='4px' />}
            />
          </Box>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default LocalDateFilter;
