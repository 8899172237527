// MetricsCards.js
// React / Chakra Imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
// Components
import Card from 'components/card/Card';
// Data Functions
import { useFilteredData, useDataV2 } from 'utils/dataHooks';
import { filterColumns, sumColumns, useAggregatedData} from 'utils/dataFunctions';
import { formatNumber, calculateChange, renderChange } from 'utils/dataFormatter';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

// Meta Spend
export function MetaSpend({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'fb_spend'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'fb_spend', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'fb_spend', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Meta Spend
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'dollar')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Meta Purchases Value
export function MetaPurchasesValue({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view'];
  const columnsToSum = ['fb_purchases_value_7d_click', 'fb_purchases_value_1d_view'];

  const currentPeriodDataFiltered = sumColumns(filterColumns(currentPeriodData, columnsToKeep), columnsToSum, 'fb_purchases_value');
  const pastPeriodDataFiltered = sumColumns(filterColumns(pastPeriodData, columnsToKeep), columnsToSum, 'fb_purchases_value');

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'fb_purchases_value', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'fb_purchases_value', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Meta Purchases Value
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'dollar')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Meta ROAS
export function MetaROAS({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeepNum = ['period', 'date', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view'];
  const columnsToSumNum = ['fb_purchases_value_7d_click', 'fb_purchases_value_1d_view'];

  const currentPeriodDataFilteredNum = sumColumns(filterColumns(currentPeriodData, columnsToKeepNum), columnsToSumNum, 'fb_purchases_value');
  const pastPeriodDataFilteredNum = sumColumns(filterColumns(pastPeriodData, columnsToKeepNum), columnsToSumNum, 'fb_purchases_value');

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'fb_purchases_value', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'fb_purchases_value', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'fb_spend'];

  const currentPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);
  const pastPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'fb_spend', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'fb_spend', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = currentPeriodValueNum / currentPeriodValueDen
  const pastPeriodValue = pastPeriodValueNum / pastPeriodValueDen

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Meta ROAS
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'float')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'float')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Meta Impressions
export function MetaImpressions({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'fb_impressions'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'fb_impressions', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'fb_impressions', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Meta Impressions
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'integer')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Meta Clicks
export function MetaClicks({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'fb_clicks'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'fb_clicks', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'fb_clicks', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Meta Clicks
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'integer')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Meta CTR
export function MetaCTR({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeepNum = ['period', 'date', 'fb_clicks'];

  const currentPeriodDataFilteredNum = filterColumns(currentPeriodData, columnsToKeepNum);
  const pastPeriodDataFilteredNum = filterColumns(pastPeriodData, columnsToKeepNum);

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'fb_clicks', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'fb_clicks', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'fb_impressions'];

  const currentPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);
  const pastPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'fb_impressions', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'fb_impressions', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = (currentPeriodValueNum / currentPeriodValueDen) * 100
  const pastPeriodValue = (pastPeriodValueNum / pastPeriodValueDen) * 100

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Meta CTR
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'percent-2')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'percent-2')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Meta CPC
export function MetaCPC({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeepNum = ['period', 'date', 'fb_spend'];

  const currentPeriodDataFilteredNum = filterColumns(currentPeriodData, columnsToKeepNum);
  const pastPeriodDataFilteredNum = filterColumns(pastPeriodData, columnsToKeepNum);

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'fb_spend', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'fb_spend', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'fb_clicks'];

  const currentPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);
  const pastPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'fb_clicks', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'fb_clicks', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = currentPeriodValueNum / currentPeriodValueDen
  const pastPeriodValue = pastPeriodValueNum / pastPeriodValueDen

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Meta CPC
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'dollar')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google Spend
export function GoogleSpend({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'gg_spend'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'gg_spend', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'gg_spend', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Google Spend
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'dollar')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google Purchases Value
export function GooglePurchasesValue({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'gg_purchases_value'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'gg_purchases_value', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'gg_purchases_value', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Google Purchases Value
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'dollar')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google ROAS
export function GoogleROAS({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeepNum = ['period', 'date', 'gg_purchases_value'];

  const currentPeriodDataFilteredNum = filterColumns(currentPeriodData, columnsToKeepNum);
  const pastPeriodDataFilteredNum = filterColumns(pastPeriodData, columnsToKeepNum);

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'gg_purchases_value', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'gg_purchases_value', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'gg_spend'];

  const currentPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);
  const pastPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'gg_spend', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'gg_spend', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = currentPeriodValueNum / currentPeriodValueDen
  const pastPeriodValue = pastPeriodValueNum / pastPeriodValueDen

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Google ROAS
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'float')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'float')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google Impressions
export function GoogleImpressions({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'gg_impressions'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'gg_impressions', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'gg_impressions', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Google Impressions
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'integer')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google Clicks
export function GoogleClicks({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'gg_clicks'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'gg_clicks', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'gg_clicks', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Google Clicks
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'integer')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google CTR
export function GoogleCTR({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeepNum = ['period', 'date', 'gg_clicks'];

  const currentPeriodDataFilteredNum = filterColumns(currentPeriodData, columnsToKeepNum);
  const pastPeriodDataFilteredNum = filterColumns(pastPeriodData, columnsToKeepNum);

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'gg_clicks', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'gg_clicks', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'gg_impressions'];

  const currentPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);
  const pastPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'gg_impressions', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'gg_impressions', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = (currentPeriodValueNum / currentPeriodValueDen) * 100
  const pastPeriodValue = (pastPeriodValueNum / pastPeriodValueDen) * 100

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Google CTR
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'percent-2')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'percent-2')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Google CPC
export function GoogleCPC({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeepNum = ['period', 'date', 'gg_spend'];

  const currentPeriodDataFilteredNum = filterColumns(currentPeriodData, columnsToKeepNum);
  const pastPeriodDataFilteredNum = filterColumns(pastPeriodData, columnsToKeepNum);

  const currentPeriodDataAggregatedNum = useAggregatedData(currentPeriodDataFilteredNum, '', 'gg_spend', 'sum');
  const pastPeriodDataAggregatedNum = useAggregatedData(pastPeriodDataFilteredNum, '', 'gg_spend', 'sum');

  const currentPeriodValueNum = currentPeriodDataAggregatedNum[0].data[0]
  const pastPeriodValueNum = pastPeriodDataAggregatedNum[0].data[0]

  const columnsToKeepDen = ['period', 'date', 'gg_clicks'];

  const currentPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);
  const pastPeriodDataFilteredDen = filterColumns(currentPeriodData, columnsToKeepDen);

  const currentPeriodDataAggregatedDen = useAggregatedData(currentPeriodDataFilteredDen, '', 'gg_clicks', 'sum');
  const pastPeriodDataAggregatedDen = useAggregatedData(pastPeriodDataFilteredDen, '', 'gg_clicks', 'sum');

  const currentPeriodValueDen = currentPeriodDataAggregatedDen[0].data[0]
  const pastPeriodValueDen = pastPeriodDataAggregatedDen[0].data[0]

  const currentPeriodValue = currentPeriodValueNum / currentPeriodValueDen
  const pastPeriodValue = pastPeriodValueNum / pastPeriodValueDen

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Google CPC
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'dollar')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Ticket Gross Sales
export function TicketGrossSales({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'tk_ticket_gross_revenues'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Ticket Gross Sales
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'dollar')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Ticket Net Sales
export function TicketNetSales({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'tk_ticket_net_revenues'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_ticket_net_revenues', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_ticket_net_revenues', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Ticket Net Sales
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'dollar')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Total Tickets Sold
export function TotalTicketsSold({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'tk_total_tickets'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_tickets', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_tickets', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Total Tickets Sold
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'integer')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Total Orders
export function TotalOrders({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'tk_total_orders'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_orders', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_orders', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Total Orders
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'integer')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'integer')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Average Order Value
export function AvgOrderValue({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);
  
  const columnsToKeep = ['period', 'date', 'tk_ticket_gross_revenues', 'tk_total_orders'];

  // const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  // const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

    // Filter out tickets with $0 value
    const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep)
    .filter(item => item.tk_ticket_gross_revenues > 0);
    const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep)
    .filter(item => item.tk_ticket_gross_revenues > 0);

  const currentPeriodSalesAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');
  const pastPeriodSalesAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');

  const currentPeriodOrdersAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_orders', 'sum');
  const pastPeriodOrdersAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_orders', 'sum');

  const currentPeriodValue = currentPeriodSalesAggregated[0].data[0] / currentPeriodOrdersAggregated[0].data[0]
  const pastPeriodValue = pastPeriodSalesAggregated[0].data[0] / pastPeriodOrdersAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Avg. Order Value
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'dollar')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'dollar')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};

// Average Tickets per Order
export function AvgTicketsPerOrder({location, dateRange, pastDateRange}) {
  const currentPeriodData = useDataV2("overview", location, dateRange);
  const pastPeriodData = useDataV2("overview", location, pastDateRange);

  const columnsToKeep = ['period', 'date', 'tk_total_tickets', 'tk_total_orders'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodTicketsAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_tickets', 'sum');
  const pastPeriodTicketsAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_tickets', 'sum');

  const currentPeriodOrdersAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_orders', 'sum');
  const pastPeriodOrdersAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_orders', 'sum');

  const currentPeriodValue = currentPeriodTicketsAggregated[0].data[0] / currentPeriodOrdersAggregated[0].data[0]
  const pastPeriodValue = pastPeriodTicketsAggregated[0].data[0] / pastPeriodOrdersAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  return (
    <Card
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    minHeight="150px"
    w="100%"
    boxShadow={shadow}
  >
    <MotionFlex
      direction="column"
      px="10px"
      pt="5px"
      mb="15px"
      align="center"
      w="100%"
      initial={{ opacity: 0 }} // Initial state
      animate={{ opacity: 1 }} // Animated state
      transition={{ duration: 1 }} // Transition duration
    >
        <Text
          fontSize="sm"
          color="secondaryGray.600"
          fontWeight="700"
          mb="18px"
          mt="-18px"
        >
          Avg. Tickets per Order
        </Text>
        <Text fontSize="3xl" mt="-20px" color={textColor} fontWeight="700">
          {formatNumber(currentPeriodValue, 'float')}
        </Text>
          <HSeparator mt="5px" mb="0px"/>
      </MotionFlex>
      <MotionFlex
        direction="column"
        align="center"
        w="100%"
        h="5px"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
        <Text fontSize="sm" mt="-10px" color="gray.300" fontWeight="500">
          vs. {formatNumber(pastPeriodValue, 'float')}
        </Text>
        <Text fontSize="14px" mt="2px" color={textColor} fontWeight="700">
          {renderChange(valueChange)}
        </Text>
      </MotionFlex>
    </Card>
);
};