// PromptContext.js
// React Imports
import React, { createContext, useState, useContext } from 'react';
import { useLocation } from './LocationContext';
import { useDateRange } from './DateRangeContext';

// Create context
const PromptContext = createContext();

// Custom hook to use the PromptContext
export const usePrompt = () => useContext(PromptContext);

// Provider component
export const PromptProvider = ({ children }) => {
    const defaultDataset = ["meta"];
    const { localLocation } = useLocation();
    const { localDateRange } = useDateRange();

    const [audiencePrompt, setAudiencePrompt] = useState(null);
    const [userPrompt, setUserPrompt] = useState(null);
    const [systemPrompt, setSystemPrompt] = useState(null);
    const [sessionId, setSessionId] = useState(null); 
    const [datasetPrompt, setDatasetPrompt] = useState(null); 
    const [modelPrompt, setModelPrompt] = useState(null);
    const [levelOfDetailPrompt, setLevelOfDetailPrompt] = useState(null);
    const [usageTotalTokens, setUsageTotalTokens] = useState(0);
    const [selectedDataset, setSelectedDataset] = useState(defaultDataset);
    const [selectedLocation, setSelectedLocation] = useState(localLocation);
    const [selectedDateRange, setSelectedDateRange] = useState(localDateRange);
    const [confirmedDataset, setConfirmedDataset] = useState(defaultDataset);
    const [confirmedLocation, setConfirmedLocation] = useState(localLocation);
    const [confirmedDateRange, setConfirmedDateRange] = useState(localDateRange);

    return (
        <PromptContext.Provider value={{
            audiencePrompt,
            setAudiencePrompt,
            userPrompt,
            setUserPrompt,
            systemPrompt,
            setSystemPrompt,
            sessionId,
            setSessionId,
            datasetPrompt,
            setDatasetPrompt,
            modelPrompt,
            setModelPrompt,
            levelOfDetailPrompt,
            setLevelOfDetailPrompt,
            usageTotalTokens,
            setUsageTotalTokens,
            selectedDataset,
            setSelectedDataset,
            selectedLocation,
            setSelectedLocation,
            selectedDateRange,
            setSelectedDateRange,
            confirmedDataset,
            setConfirmedDataset,
            confirmedLocation,
            setConfirmedLocation,
            confirmedDateRange,
            setConfirmedDateRange
        }}>
            {children}
        </PromptContext.Provider>
    );
};
