// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Poppins", sans-serif;
}

option {
  color: black;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding-bottom: 0;
  max-width: 500px;
}

.popup {
  min-width: 120px;
  padding-bottom: 15px;
}

.popup p {
  margin-bottom: 0px;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap\");\n\nbody {\n  font-family: \"Poppins\", sans-serif;\n}\n\noption {\n  color: black;\n}\n\n.leaflet-popup-content-wrapper, .leaflet-popup-tip {\n  background-color: rgba(0, 0, 0, 0.8);\n  color: #fff;\n  padding-bottom: 0;\n  max-width: 500px;\n}\n\n.popup {\n  min-width: 120px;\n  padding-bottom: 15px;\n}\n\n.popup p {\n  margin-bottom: 0px;\n  margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
