// LocationFilter.js
// React / Chakra Imports
import React from "react";
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
// Icons
import { FaMapMarkerAlt } from 'react-icons/fa';
// Contexts
import { useLocation } from "contexts/LocationContext";

export default function LocationFilter() {
  const { location, setLocation, companyLocations } = useLocation();

  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  const bgBackground = useColorModeValue('white', 'navy.800');

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const handleLocationChange = (newLocation) => {
    setLocation(newLocation);
    onClose1(); // Close menu after selection
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      bg={menuBg}
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}>
    <Menu isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        align='center'
        justifyContent='center'
        ml="20px"
        mr="20px"
        _hover={bgHover}
        w='100%'
        h='37px'
        lineHeight='100%'
        onClick={onOpen1}
        borderRadius='10px'
        >
        <Flex align='center' justifyContent="center">
            <Icon as={FaMapMarkerAlt} color={iconColor} w='14px' h='24px' />
            <Text ml='8px' fontSize='sm' color={textColor}>
              {location}
            </Text>
          </Flex>
      </MenuButton>
      <MenuList
        w='150px'
        minW='unset'
        maxW='150px !important'
        border='transparent'
        backdropFilter='blur(63px)'
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius='20px'
        p='15px'>
        {companyLocations.map((loc, index) => {
          const isFirstItem = index === 0;
          const isLastItem = index === companyLocations.length - 1;
          const isOnlyItem = companyLocations.length === 1;
          const borderRadius = isOnlyItem
            ? "15px"
            : isFirstItem
            ? "15px 15px 0 0"
            : isLastItem
            ? "0 0 15px 15px"
            : "0";

          return (
            <MenuItem
              key={index}
              transition="0.2s linear"
              color={textColor}
              _hover={textHover}
              p="0"
              _active={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              onClick={() => handleLocationChange(loc)}
            >
              <Flex
                align="center"
                bg={bgBackground}
                p="10px"
                width="100%"
                borderRadius={borderRadius}
              >
                <Text fontSize="sm" fontWeight="400">
                  {loc}
                </Text>
              </Flex>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
    </Flex>
  );
}
