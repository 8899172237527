// index.js
// React Imports
import { Box, Flex } from '@chakra-ui/react';
// Icons
import { XeusCloudLogoCover } from 'components/icons/Icons';

export default function AuthIllustration(props) {
  const { children } = props;
  
  return (
    <Flex
      height="100vh"
      width="100vw"
      overflow="hidden"
      position="relative"
      bg="white"
    >
      <Flex
        justifyContent="center"
        alignItems="flex-start"
        width={{ base: '100%', md: '55%' }} // Adjust this if you want different widths for the columns
        height="100%"
        mt="100px"
      >
        {children}
      </Flex>
      <Box
        display={{ base: 'none', md: 'block' }}
        height="100%"
        width="45%" // Adjust this if you want different widths for the columns
        bgGradient="linear(to-b, xeusColors.200, xeusColors.100)"
        borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
        position="relative"
      >
        <Flex 
          justify="center"
          align="center"
          height="100%"
          width="100%"
          bgSize="cover"
          bgPosition="50%"
        >
          <XeusCloudLogoCover
            height="100px"
            width="550px"
            my="32px"
          />
        </Flex>
      </Box>
    </Flex>
  );
}
