// DateFormatDropdown.js
// React / Chakra Imports
import React from 'react';
import { Flex, Icon, Select, useColorModeValue } from '@chakra-ui/react';
// Icons
import { MdOutlineCalendarToday } from 'react-icons/md';
// Contexts
import { useDateFormat } from 'contexts/DateFormatContext';

const DateFormatDropdown = () => {
  const { dateFormat, setDateFormat } = useDateFormat();
  const handleChange = (event) => {
    setDateFormat(event.target.value);
  };


  const bg = useColorModeValue('background.100', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <Flex alignItems="center" bg={bg} p={2} borderRadius="md">
      <Icon as={MdOutlineCalendarToday} color={textColor} mr={2} />
      <Select value={dateFormat} onChange={handleChange} size="sm" variant="outline">
        <option value="day">Day</option>
        <option value="week">Week</option>
        <option value="month">Month</option>
      </Select>
    </Flex>
  );
};

export default DateFormatDropdown;

