// MetaColumnChartViz.js
// React / Chakra Imports
import {
  Box,
  Flex,
  Text,
  Tooltip,
  useColorModeValue, 
} from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';
import React, { useMemo } from "react";
// Components
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useMetricsMeta } from "contexts/MetricsMetaContext";
// Variables
import {
  columnChartOptionsOverview,
} from 'variables/charts';
// Data Functions
import { formatNumber } from "utils/dataFormatter";
import { useFilteredData } from 'utils/dataHooks';
import { aggregateDataByColumnV2, filterColumns} from 'utils/dataFunctions';
// Filters
import MetricsMetaDropdown from "components/filters/MetricsMetaDropdown";
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function ColumnChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { metrics } = useMetricsMeta();
  const { currentPeriodData } = useFilteredData("meta_campaigns", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorChart = useColorModeValue('secondaryGray.500', 'white');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const columnsToKeep = useMemo(() => ['fb_campaign_name', 'fb_spend', 'fb_impressions', 'fb_clicks', 'fb_purchases_7d_click', 'fb_purchases_1d_view', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view'], []);
  const columnsToSum = useMemo(() => ['fb_spend', 'fb_impressions', 'fb_clicks', 'fb_purchases_7d_click', 'fb_purchases_1d_view', 'fb_purchases_value_7d_click', 'fb_purchases_value_1d_view'], []);
  
  const currentPeriodDataByColumns = useMemo(() => filterColumns(currentPeriodData, columnsToKeep), [currentPeriodData, columnsToKeep]);
  const aggregatedData = useMemo(() => aggregateDataByColumnV2(currentPeriodDataByColumns, 'fb_campaign_name', columnsToSum, 'sum'), [currentPeriodDataByColumns, columnsToSum]);
  
  const calculatedData = useMemo(() => 
    aggregatedData
      .filter(item => item.fb_campaign_name !== "null")
      .map(item => ({
        ...item,
        fb_purchases: (item.fb_purchases_7d_click ?? 0) + (item.fb_purchases_1d_view ?? 0),
        fb_purchases_value: (item.fb_purchases_value_7d_click ?? 0) + (item.fb_purchases_value_1d_view ?? 0),
        fb_cpm: item.fb_impressions ? ((item.fb_spend / item.fb_impressions) * 1000) : 0,
        fb_ctr: item.fb_impressions ? ((item.fb_clicks / item.fb_impressions) * 100) : 0,
        fb_cpc: item.fb_clicks ? (item.fb_spend / item.fb_clicks) : 0,
        fb_roas: item.fb_spend ? ((item.fb_purchases_value_7d_click + item.fb_purchases_value_1d_view) / item.fb_spend) : 0,
      }))
  , [aggregatedData]);
  
  const filteredData = useMemo(() => {
    const metricMapping = {
      cpm: 'fb_cpm',
      ctr: 'fb_ctr',
      cpc: 'fb_cpc',
      roas: 'fb_roas',
      purchases: 'fb_purchases',
      purchases_value: 'fb_purchases_value'
    };
  
    const metricKey = metricMapping[metrics];

    if (!metricKey) return [];
  
    return calculatedData
      .filter(item => item[metricKey] > 0)
      .sort((a, b) => (b[metricKey] ?? 0) - (a[metricKey] ?? 0)); // Sort in descending order
  }, [calculatedData, metrics]);
  
  const columnChartData = useMemo(() => {
    const metricMapping = {
      cpm: 'fb_cpm',
      ctr: 'fb_ctr',
      cpc: 'fb_cpc',
      roas: 'fb_roas',
      purchases: 'fb_purchases',
      purchases_value: 'fb_purchases_value'
    };
  
    const metricKey = metricMapping[metrics];
    if (!metricKey) return [];
  
    return [
      {
        name: `Meta ${metrics.replace('_', ' ').toUpperCase()}`,
        data: filteredData.map(item => item[metricKey] ?? 0),
      }
    ];
  }, [filteredData, metrics]);

  console.log("columnChartData:", columnChartData);

  const columnChartCategories = useMemo(() => 
    filteredData
    .map(item => item.fb_campaign_name)
    , [filteredData]);

  console.log("columnChartCategories:", columnChartCategories);

  const formatValue = (val, metric) => {
    switch (metric) {
      case 'cpm':
        return formatNumber(val, 'dollar', false); // Format as dollar
      case 'ctr':
        return formatNumber(val, 'percent-2', false); // Format as dollar
      case 'cpc':
        return formatNumber(val, 'dollar', false); // Format as dollar
      case 'roas':
        return formatNumber(val, 'float-2', false); // Format as float
      case 'purchases':
        return formatNumber(val, 'int', false); // Format as dollar
      case 'purchases_value':
        return formatNumber(val, 'dollar', false); // Format as dollar
      default:
        return val;
    }
  };
  
  const dataLabelsOptions = useMemo(() => ({
    formatter: (val) => formatValue(val, metrics),
  }), [metrics]);

  const newOptions = {
    ...columnChartOptionsOverview,
    plotOptions: {
      ...columnChartOptionsOverview.plotOptions,
      bar: {
        horizontal: true,
        borderRadius: 5,
        dataLabels: {
          position: 'top', // This will position the data labels at the end of the bars
        },
        barHeight: columnChartData[0].data?.length > 5 ? '35px' : '80%'
      },
    },
    chart: {
      ...columnChartOptionsOverview.chart,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [textColorChart],
      },
      offsetX: 90,
      ...dataLabelsOptions,
    },
    xaxis: {
      categories: columnChartCategories,
      show: false,
      min: 0, // Force y-axis to start at 0
      max: Math.max(...columnChartData[0].data) * 1.7,
      labels: {
        show: false,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: textColorChart,
          fontSize: '14px',
        },
      },
    },
    fill: {
      colors: ['#00E5D1'],
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (val) => formatNumber(val, 'dollar', false),
      }
    }
  };

  const labelMapping = {
    'ctr': 'CTR',
    'cpm': 'CPM',
    'cpc': 'CPC',
    'roas': 'ROAS',
    'purchases': 'Purchases',
    'purchases_value': 'Purchases Value',
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
    >
      <MotionFlex direction='column' w='100%' overflowX={{ sm: "scroll", lg: "hidden" }}  initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Flex align='center' justify='space-between' w='100%' px='10px' mb='20px'>
          <CardHeader mt='10px' mb='10px' ps='22px'>
            <Flex direction='column' alignSelf='flex-start'>
              <Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
                Campaigns
              </Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
								by {labelMapping[metrics]}
              </Text>
            </Flex>
          </CardHeader>
          <MetricsMetaDropdown />
        </Flex>
        </MotionFlex>
      <MotionFlex
        direction="column"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
        w="100%"
      >
      <Box minH="260px" mt="auto" pt="15px" mb="0px" pl="15px" w="100%" maxH="260px" overflowY={columnChartData[0].data?.length > 5 ? 'auto' : 'visible'}>
        <BarChart
          chartData={columnChartData}
          chartOptions={newOptions}
          height={columnChartData[0].data?.length > 5 ? `${columnChartData[0].data?.length * 50}px` : '100%'}
        />
      </Box>
      </MotionFlex>
    </Card>
  );
}
