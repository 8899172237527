import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button, Box, Icon } from '@chakra-ui/react';
import { useLocation } from 'contexts/LocationContext';
import { MdLocationOn } from 'react-icons/md';

const LocalLocationFilter = ({ callback = () => {}, fontSize = "sm"  }) => {
  const { companyLocations, localLocation, setLocalLocation } = useLocation();

  const handleCitySelect = (city) => {
    setLocalLocation(city);
    callback();
  };

  return (
    <Box width="100%">
      <Menu>
        <MenuButton 
          as={Button} 
          backgroundColor="#5158FF" 
          color="white" 
          _hover={{ backgroundColor: "#3d44cc" }}
          width="100%"
          fontSize={fontSize}
          leftIcon={<Icon as={MdLocationOn} w={4} h={4} />}
        >
          {localLocation}
        </MenuButton>
        <MenuList>
          {companyLocations.map((city) => (
          <MenuItem
            key={city}
            fontSize={fontSize} 
            height="30px"
            bg={localLocation === city ? '#5158FF' : 'transparent'}
            onClick={() => handleCitySelect(city)}
          >
            {city}
          </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default LocalLocationFilter;
