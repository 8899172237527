// React Imports
import React, { useEffect, useState } from "react";
import {
  BarChart, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer,
  Bar,
  LabelList
} from 'recharts';
import { useColorModeValue } from '@chakra-ui/react';

const StackedBarChart = ({ chartData, customTooltip, bars }) => {
  const [data, setData] = useState(chartData);
  const [tooltip, setTooltip] = useState(customTooltip);
  const [barConfig, setBarConfig] = useState(bars);

  const labelColor = useColorModeValue("gray", "#A3AED0");

  useEffect(() => {
    setData(chartData);
    setTooltip(customTooltip);
    setBarConfig(bars);
  }, [chartData, customTooltip, bars]);

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        layout="vertical"
        width={600}
        height={400}
        data={data}
        margin={{ right: 30, left: 20 }}
        stackOffset="expand"
        barSize={30}
      >
        <XAxis type="number" hide />
        <YAxis
          type="category" 
          dataKey="label" 
          axisLine={false} 
          tickLine={false} 
          tick={{ fill: labelColor, fontSize: '12px' }}
        />
        <Tooltip content={tooltip} cursor={{ fill: 'none' }} />
        <Legend wrapperStyle={{ fontSize: "12px", fontWeight: 'bold' }} />
        {barConfig.map((bar) => (
          <Bar key={bar.dataKey} dataKey={bar.dataKey} name={bar.name} stackId='a' fill={bar.fill}>
            <LabelList dataKey={bar.labelDataKey} position="inside" fill="white" style={{ fontSize: 13, fontWeight: 'bold' }} formatter={(value) => (value >= 5 ? `${value}%` : '')} />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
