const locationDetails = [
    {
        "client_id": "illuminarium",
        "location_id": "Las Vegas",
        "Name": "Illuminarium",
        "Country": "United States",
        "State": "Nevada",
        "City": "Las Vegas",
        "Zipcode": "89102",
        "Address": "3246 W Desert Inn Rd, Las Vegas, NV 89102",
        "Latitude": 36.1299917,
        "Longitude": -115.1840316
    },
    {
        "client_id": "illuminarium",
        "location_id": "Toronto",
        "Name": "Illuminarium",
        "Country": "Canada",
        "State": "Ontario",
        "City": "Toronto",
        "Zipcode": "M5A 4R2",
        "Address": "28 Gristmill Lane, Toronto, ON M5A 4R2",
        "Latitude": 43.650060,
        "Longitude": -79.360540
    },
    {
        "client_id": "sloomoo_institute",
        "location_id": "Atlanta",
        "Name": "Sloomoo Institute",
        "Country": "United States",
        "State": "Georgia",
        "City": "Atlanta",
        "Zipcode": "30319",
        "Address": "Lower Level, 3637 Peachtree Rd NE Suite D, Atlanta, GA 30319",
        "Latitude": 33.857557,
        "Longitude": -84.348132
    },
    {
        "client_id": "sloomoo_institute",
        "location_id": "Chicago",
        "Name": "Sloomoo Institute",
        "Country": "United States",
        "State": "Illinois",
        "City": "Chicago",
        "Zipcode": "60610",
        "Address": "820 N Orleans St, Chicago, IL 60610",
        "Latitude": 41.8971363,
        "Longitude": -87.637429
    },
    {
        "client_id": "sloomoo_institute",
        "location_id": "Houston",
        "Name": "Sloomoo Institute",
        "Country": "United States",
        "State": "Texas",
        "City": "Houston",
        "Zipcode": "77024",
        "Address": "Marq-E Entertainment Center, 7620 Katy Fwy Suite 360, Houston, TX 77024",
        "Latitude": 29.785360,
        "Longitude": -95.464729
    },
    {
        "client_id": "sloomoo_institute",
        "location_id": "New York City",
        "Name": "Sloomoo Institute",
        "Country": "United States",
        "State": "New York",
        "City": "New York City",
        "Zipcode": "10013",
        "Address": "475 Broadway, New York, NY 10013",
        "Latitude": 40.7213839,
        "Longitude": -74.0003936
    },
    {
        "client_id": "sloomoo_institute",
        "location_id": "Los Angeles",
        "Name": "Sloomoo Institute",
        "Country": "United States",
        "State": "California",
        "City": "Los Angeles",
        "Zipcode": "90036",
        "Address": "157 S Fairfax Ave Ground Floor, Los Angeles, CA 90036",
        "Latitude": 34.072520,
        "Longitude": -118.361700
    },
    {
        "client_id": "beat_the_bomb",
        "location_id": "Atlanta",
        "Name": "Beat the Bomb",
        "Country": "United States",
        "State": "Georgia",
        "City": "Atlanta",
        "Zipcode": "30318",
        "Address": "1483 Chattahoochee Ave NW, Atlanta, GA 30318",
        "Latitude": 33.808922,
        "Longitude": -84.435631
    },
    {
        "client_id": "beat_the_bomb",
        "location_id": "Brooklyn",
        "Name": "Beat the Bomb",
        "Country": "United States",
        "State": "New York",
        "City": "Brooklyn",
        "Zipcode": "11201",
        "Address": "255 Water St, Brooklyn, NY 11201",
        "Latitude": 40.70323627980521,
        "Longitude": -73.98392553763598
    },
    {
        "client_id": "beat_the_bomb",
        "location_id": "Washington",
        "Name": "Beat the Bomb",
        "Country": "United States",
        "State": "Washington, DC",
        "City": "Washington, DC",
        "Zipcode": "20002",
        "Address": "2005 Hecht Ave NE, Washington, DC 20002",
        "Latitude": 38.9154706240805,
        "Longitude": -76.98317408794556
    },
    {
        "client_id": "beat_the_bomb",
        "location_id": "Philadelphia",
        "Name": "Beat the Bomb",
        "Country": "United States",
        "State": "Pennsylvania",
        "City": "Philadelphia",
        "Zipcode": "19107",
        "Address": "1218 Chestnut St, Philadelphia, PA 19107",
        "Latitude": 39.950400,
        "Longitude": -75.161150
    },
    {
        "client_id": "beat_the_bomb",
        "location_id": "Toronto",
        "Name": "Arcadia Earth",
        "Country": "Canada",
        "State": "Ontario",
        "City": "Toronto",
        "Zipcode": "M5V 0V2",
        "Address": "486 Front St W building C, Toronto, ON M5V 0V2",
        "Latitude": 43.641810,
        "Longitude": -79.397500
    },
    {
        "client_id": "a_bunch_of_stuff",
        "location_id": "New York City",
        "Name": "A Bunch of Stuff",
        "Country": "United States",
        "State": "New York",
        "City": "New York City",
        "Zipcode": "10001",
        "Address": "600 W 27th St Ground Floor, New York, NY 10001",
        "Latitude": 40.752250,
        "Longitude": -74.007430
    },
];

export default locationDetails;
