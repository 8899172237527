// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-button:hover {
    background: var(--chakra-colors-brand-400) !important;
}

.forgot-password:hover {
    text-decoration: underline;
    cursor: pointer;
}

.cancel-button {
    background-color: white !important;
    color: var(--chakra-colors-brand-400) !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Login.css"],"names":[],"mappings":"AAAA;IACI,qDAAqD;AACzD;;AAEA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,kCAAkC;IAClC,gDAAgD;AACpD","sourcesContent":[".login-button:hover {\n    background: var(--chakra-colors-brand-400) !important;\n}\n\n.forgot-password:hover {\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.cancel-button {\n    background-color: white !important;\n    color: var(--chakra-colors-brand-400) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
