const fieldTypes = {
    client: "string",
    location: "string",
    date: "date",
    month_name: "string",
    day_name: "string",
    fb_spend: "money",
    fb_impressions: "number",
    fb_clicks: "number",
    fb_link_clicks: "number",
    fb_landing_page_views: "number",
    fb_purchases: "number",
    fb_purchases_value: "money",
    gg_spend: "money",
    gg_impressions: "number",
    gg_clicks: "number",
    gg_purchases: "number",
    gg_purchases_value: "money",
    tk_total_orders: "number",
    tk_total_tickets: "number",
    tk_ticket_gross_revenues: "money",
    tk_ticket_net_revenues: "money",
};

export default fieldTypes;
