// OverviewolumnChartViz.js
// React / Chakra Imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue, 
} from '@chakra-ui/react';
import React, { useMemo } from "react";
// Components
import BarChart from 'components/charts/BarChart';
import Card from 'components/card/Card';
import CardHeader from 'components/card/CardHeader';
// Variables
import {
  columnChartOptionsOverview,
} from 'variables/charts';
// Contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
// Data Functions
import { useFilteredData } from 'utils/dataHooks';
import { aggregateDataByDate } from 'utils/dataFunctions';
// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

export default function ColumnChartViz() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData } = useFilteredData("overview", location, dateRange);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorChart = useColorModeValue('secondaryGray.500', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const columnsToSum = useMemo(() => ['tk_total_orders'], []);

  const aggregatedData = useMemo(() => aggregateDataByDate(currentPeriodData, 'day_of_week', columnsToSum, 'sum'), [currentPeriodData, columnsToSum]);

  const columnChartData = useMemo(() => 
    [
    {
      name: 'Total Orders',
      data: aggregatedData.map(item => (item.tk_total_orders ?? 0) ),
    },
  ]
  , [aggregatedData]);

const columnChartCategories = useMemo(() => 
  aggregatedData
  .map(item => item.date)
  , [aggregatedData]);

const newOptions = {
  ...columnChartOptionsOverview,
  tooltip: {
    ...columnChartOptionsOverview.tooltip,
    y: {
      formatter: function (value, { seriesIndex, w }) {
        const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
        const percentage = ((value / total) * 100).toFixed(2);
        return `${value} (${percentage}%)`;
      }
    }
  },
  plotOptions: {
    ...columnChartOptionsOverview.plotOptions,
    bar: {
      horizontal: true,
      borderRadius: 5,
      dataLabels: {
        position: 'top', // This will position the data labels at the end of the bars
      },
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      colors: [textColorChart],
      fontSize: '14px',
    },
    offsetX: 30,
  },
  xaxis: {
    categories: columnChartCategories,
    show: false,
    min: 0, // Force y-axis to start at 0
    // max: newmaxOrders,
    max: Math.max(...columnChartData[0].data) * 1.3,
    labels: {
      show: false,
      style: {
        colors: '#A3AED0',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    ...columnChartOptionsOverview.yaxis,
    show: true,
    color: 'black',
    labels: {
      show: true,
      style: {
        colors: textColorChart,
        fontSize: '14px',
      },
    },
  },
  fill: {
    colors: ['#00E5D1'],
  },
};

  return (
    <Card
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
    >
      <CardHeader mt='10px' mb='10px' ps='22px'>
						<MotionFlex direction='column' alignSelf='flex-start' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
							<Text fontSize='lg' color={textColor} fontWeight='bold' mb='0px'>
								Total Orders
							</Text>
              <Text fontSize='sm' color='gray.400' fontWeight='normal'>
									by day of the week
              </Text>
						</MotionFlex>
      </CardHeader>
      <MotionFlex
        direction="column"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
        w="100%"
      >
      <Box minH="260px" mt="auto" mb="0px" pl="15px" w="100%">
        <BarChart
          chartData={columnChartData}
          chartOptions={newOptions}
        />
      </Box>
      </MotionFlex>
    </Card>
  );
}
