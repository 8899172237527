import React from 'react';
import { ButtonGroup, Tooltip, IconButton, useColorModeValue } from '@chakra-ui/react';
import { FaChartLine, FaChartBar, FaTable } from 'react-icons/fa';

const VisualTypeButtons = ({ visualType, setVisualType }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <ButtonGroup isAttached mb={4} width="100%">
      <Tooltip label="Line Chart" aria-label="Line Chart Tooltip">
        <IconButton
          aria-label="Line Chart"
          icon={<FaChartLine />}
          backgroundColor={visualType === 'line' ? '#5158FF' : 'white.300'}
          color={visualType === 'line' ? 'white' : textColor}
          _hover={{ backgroundColor: visualType === 'line' ? '#3d44cc' : 'rgba(255, 255, 255, 0.1)' }}
          onClick={() => setVisualType('line')}
          flex="1"
        />
      </Tooltip>
      <Tooltip label="Bar Chart" aria-label="Bar Chart Tooltip">
        <IconButton
          aria-label="Bar Chart"
          icon={<FaChartBar />}
          backgroundColor={visualType === 'bar' ? '#5158FF' : 'white.300'}
          color={visualType === 'bar' ? 'white' : textColor}
          _hover={{ backgroundColor: visualType === 'bar' ? '#3d44cc' : 'rgba(255, 255, 255, 0.1)' }}
          onClick={() => setVisualType('bar')}
          flex="1"
        />
      </Tooltip>
      <Tooltip label="Table" aria-label="Table Tooltip">
          <IconButton
            aria-label="Table"
            icon={<FaTable />}
            backgroundColor={visualType === 'table' ? '#5158FF' : 'white.300'}
            color={visualType === 'table' ? 'white' : textColor}
            _hover={{ backgroundColor: visualType === 'table' ? '#3d44cc' : 'rgba(255, 255, 255, 0.1)' }}
            onClick={() => setVisualType('table')}
            flex="1"
          />
      </Tooltip>
    </ButtonGroup>
  );
};

export default VisualTypeButtons;
