// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';

// Custom components
import Card from 'components/card/Card';

import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { useFilteredData } from 'utils/dataHooks';
import { filterColumns, useAggregatedData} from 'utils/dataFunctions';
import { formatNumber, calculateChange, renderChange } from 'utils/dataFormatter';

// Motion
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

// Ticket Gross Sales
export function TicketGrossSales() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'tk_ticket_gross_revenues'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

    return (
      <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      boxShadow={shadow}
    >
      <MotionFlex
        direction="column"
        px="10px"
        pt="5px"
        mb="15px"
        align="center"
        w="100%"
        initial={{ opacity: 0 }} // Initial state
        animate={{ opacity: 1 }} // Animated state
        transition={{ duration: 1 }} // Transition duration
      >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            Ticket Gross Sales
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(currentPeriodValue, 'dollar')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
            {renderChange(valueChange)}
          </Text>
        </MotionFlex>
      </Card>
  );
};

// Ticket Net Sales
export function TicketNetSales() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'tk_ticket_net_revenues'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_ticket_net_revenues', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_ticket_net_revenues', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

    return (
      <Card
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        w="100%"
        boxShadow={shadow}
      >
        <MotionFlex
          direction="column"
          px="10px"
          pt="5px"
          mb="15px"
          align="center"
          w="100%"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            Ticket Net Sales
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(currentPeriodValue, 'dollar')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
            {renderChange(valueChange)}
          </Text>
        </MotionFlex>
      </Card>
  );
};

// Total Tickets Sold
export function TotalTicketsSold() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'tk_total_tickets'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_tickets', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_tickets', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

    return (
      <Card
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        w="100%"
        boxShadow={shadow}
      >
        <MotionFlex
          direction="column"
          px="10px"
          pt="5px"
          mb="15px"
          align="center"
          w="100%"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            Total Tickets Sold
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(currentPeriodValue, 'integer')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
            {renderChange(valueChange)}
          </Text>
        </MotionFlex>
      </Card>
  );
};

// Total Orders
export function TotalOrders() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'tk_total_orders'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodDataAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_orders', 'sum');
  const pastPeriodDataAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_orders', 'sum');

  const currentPeriodValue = currentPeriodDataAggregated[0].data[0]
  const pastPeriodValue = pastPeriodDataAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

    return (
      <Card
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        w="100%"
        boxShadow={shadow}
      >
        <MotionFlex
          direction="column"
          px="10px"
          pt="5px"
          mb="15px"
          align="center"
          w="100%"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            Total Orders
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(currentPeriodValue, 'integer')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
            {renderChange(valueChange)}
          </Text>
        </MotionFlex>
      </Card>
  );
};

// Average Order Value
export function AvgOrderValue() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'tk_ticket_gross_revenues', 'tk_total_orders'];

  // const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  // const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

    // Filter out tickets with $0 value
    const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep)
    .filter(item => item.tk_ticket_gross_revenues > 0);
    const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep)
    .filter(item => item.tk_ticket_gross_revenues > 0);

  const currentPeriodSalesAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');
  const pastPeriodSalesAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_ticket_gross_revenues', 'sum');

  const currentPeriodOrdersAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_orders', 'sum');
  const pastPeriodOrdersAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_orders', 'sum');

  const currentPeriodValue = currentPeriodSalesAggregated[0].data[0] / currentPeriodOrdersAggregated[0].data[0]
  const pastPeriodValue = pastPeriodSalesAggregated[0].data[0] / pastPeriodOrdersAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

    return (
      <Card
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        w="100%"
        boxShadow={shadow}
      >
        <MotionFlex
          direction="column"
          px="10px"
          pt="5px"
          mb="15px"
          align="center"
          w="100%"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            Avg. Order Value
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(currentPeriodValue, 'dollar')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
            {renderChange(valueChange)}
          </Text>
        </MotionFlex>
      </Card>
  );
};

// Average Tickets per Order
export function AvgTicketsPerOrder() {
  const { location } = useLocation();
  const { dateRange } = useDateRange();
  const { currentPeriodData, pastPeriodData } = useFilteredData("overview", location, dateRange);

  const columnsToKeep = ['period', 'date', 'tk_total_tickets', 'tk_total_orders'];

  const currentPeriodDataFiltered = filterColumns(currentPeriodData, columnsToKeep);
  const pastPeriodDataFiltered = filterColumns(pastPeriodData, columnsToKeep);

  const currentPeriodTicketsAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_tickets', 'sum');
  const pastPeriodTicketsAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_tickets', 'sum');

  const currentPeriodOrdersAggregated = useAggregatedData(currentPeriodDataFiltered, '', 'tk_total_orders', 'sum');
  const pastPeriodOrdersAggregated = useAggregatedData(pastPeriodDataFiltered, '', 'tk_total_orders', 'sum');

  const currentPeriodValue = currentPeriodTicketsAggregated[0].data[0] / currentPeriodOrdersAggregated[0].data[0]
  const pastPeriodValue = pastPeriodTicketsAggregated[0].data[0] / pastPeriodOrdersAggregated[0].data[0]

  const valueChange = calculateChange(currentPeriodValue, pastPeriodValue)

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

    return (
      <Card
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        w="100%"
        boxShadow={shadow}
      >
        <MotionFlex
          direction="column"
          px="10px"
          pt="5px"
          mb="15px"
          align="center"
          w="100%"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text
            fontSize="sm"
            color="secondaryGray.600"
            fontWeight="700"
            mb="5px"
          >
            Avg. Tickets per Order
          </Text>
          <Text fontSize="3xl" color={textColor} fontWeight="700">
            {formatNumber(currentPeriodValue, 'float')}
          </Text>
            <HSeparator mt="5px" mb="0px"/>
        </MotionFlex>
        <MotionFlex
          direction="column"
          align="center"
          w="100%"
          h="5px"
          initial={{ opacity: 0 }} // Initial state
          animate={{ opacity: 1 }} // Animated state
          transition={{ duration: 1 }} // Transition duration
        >
          <Text fontSize="sm" mt="-10px" color={textColor} fontWeight="700">
            {renderChange(valueChange)}
          </Text>
        </MotionFlex>
      </Card>
  );
};