// // // Chakra / React imports
// import React from "react";
// import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";

// // Custom components
// import { TicketGrossSales, TicketNetSales, TotalTicketsSold, TotalOrders, AvgOrderValue, AvgTicketsPerOrder } from "./components/insights/InsightsHighLevelMetrics";
// import TicketsInsightsTreeMapChartViz from "./components/insights/InsightsTreeMapChartViz";
// import TicketsInsightsColumnChartViz1 from "./components/insights/InsightsColumnChartViz1";
// import TicketsInsightsColumnChartViz2 from "./components/insights/InsightsColumnChartViz2";
// import TicketsInsightsHeatMapChartViz from "./components/insights/InsightsHeatMapChartViz";
// import AttendanceCalendar from "./components/attendance/AttendanceCalendarViz"

// export default function Default() {
//   // Chakra Color Mode
//   const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

//   return (
//     <Flex
//       direction={{ base: "column", xl: "row" }}
//       pt={{ base: "130px", md: "80px", xl: "80px" }}
//       width="100%" // Ensuring the container takes the ful/l widths
//       mx="auto" // Centering the container horizontally
//     >
//       <Flex direction='column' width='stretch' >
//         <Grid
//           mb='20px'
//           gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "1fr 1fr 1fr 2fr" }}
//           gap='20px'
//           display={{ base: "block", lg: "grid" }}
//           width='100%'
//         >
//           <Flex gridArea={{ base: "auto", "2xl": "1 / 1 / 2 / 2" }}>
//             <TicketGrossSales />
//           </Flex>
//           <Flex gridArea={{ base: "auto", "2xl": "1 / 2 / 2 / 3" }}>
//             <TicketNetSales />
//           </Flex>
//           <Flex gridArea={{ base: "auto", "2xl": "1 / 3 / 2 / 4" }}>
//             <TotalTicketsSold />
//           </Flex>
//           <Flex gridArea={{ base: "auto", "2xl": "1 / 4 / 2 / 5" }}>
//             <TotalOrders />
//           </Flex>
//         </Grid>
//         <Grid
//           mb='20px'
//           gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "2fr 1fr" }}
//           gap='20px'
//           display={{ base: "block", lg: "grid" }}
//           width='100%'
//         >
//           <Flex gridArea={{ base: "1 / 1 / 2 / 4", "2xl": "1 / 1 / 2 / 2" }} width='100%'>
//             <AttendanceCalendar width='100%'/>
//           </Flex>
//           <Flex gridArea={{ base: "2 / 1 / 3 / 4", "2xl": "1 / 2 / 2 / 3" }} width='100%'>
//             <TicketsInsightsColumnChartViz1 width='100%'/>
//           </Flex>
//           {/* <Flex gridArea={{ base: "3 / 1 / 4 / 4", "2xl": "1 / 3 / 2 / 4" }} width='100%'>
//             <TicketsInsightsColumnChartViz2 width='100%'/>
//           </Flex> */}
//         </Grid>
//         {/* <Grid
//           mb='20px'
//           gridTemplateColumns={{ base: "1fr" }}
//           width='100%'
//         >
//           <Flex gridArea={{ base: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }}>
//               <AttendanceCalendar
//                 width='100%'/>
//           </Flex>
//         </Grid> */}
//       </Flex>
//     </Flex>
//   );
// }

// // Chakra / React imports
// import React from "react";
// import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";

// // Custom components
// import { TicketGrossSales, TicketNetSales, TotalTicketsSold, TotalOrders } from "./components/insights/InsightsHighLevelMetrics";
// import TicketsInsightsColumnChartViz1 from "./components/insights/InsightsColumnChartViz1";
// import AttendanceCalendar from "./components/attendance/AttendanceCalendarViz";

// export default function Default() {
//   // Chakra Color Mode
//   const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

//   return (
//     <Flex
//       direction={{ base: "column", xl: "row" }}
//       pt={{ base: "130px", md: "80px", xl: "80px" }}
//       width="100%" // Ensuring the container takes the full width
//       mx="auto" // Centering the container horizontally
//     >
//       <Flex direction='column' width='stretch'>
//         <Grid
//           mb='20px'
//           gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "6.5fr 3.5fr" }}
//           gap='20px'
//           width='100%'
//           display={{ base: "block", lg: "grid" }}
//         >
//           <Grid
//             mb='20px'
//             gridTemplateColumns={{ base: "repeat(2, 1fr)", "2xl": "1fr 1fr 1fr" }}
//             gap='20px'
//             gridArea="1 / 1 / 2 / 2"
//             display={{ base: "block", lg: "grid" }}
//           >
//             <Flex>
//               <TicketGrossSales />
//             </Flex>
//             <Flex>
//               <TicketNetSales />
//             </Flex>
//             <Flex>
//               <TotalTicketsSold />
//             </Flex>
//           </Grid>

//           <Flex gridArea="2 / 1 / 3 / 2">
//             <AttendanceCalendar width='100%' />
//           </Flex>

//           <Flex gridArea="1 / 2 / 2 / 3">
//             <TotalOrders height="100%"/>
//           </Flex>

//           <Grid
//             mb='20px'
//             gridTemplateColumns={{ base: "repeat(2, 1fr)",  xl: "1fr"}}
//             gap='20px'
//             gridArea="2 / 2 / 3 / 3"
//             display={{ base: "block", lg: "grid" }}
//           >
//             <Flex>
//               <TicketsInsightsColumnChartViz1 width='100%' />
//             </Flex>
//             <Flex>
//               <TicketsInsightsColumnChartViz1 width='100%' /> {/* Duplicate for placeholder */}
//             </Flex>
//           </Grid>
//         </Grid>
//       </Flex>
//     </Flex>
//   );
// }

// Chakra / React imports
import React from "react";
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { ThisYearAttendees, ThisMonthAttendees, ThisWeekAttendees, AverageDays } from "./components/attendance/AttendanceHighLevelMetrics";
import AttendanceColumnChartViz from "./components/attendance/AttendanceColumnChartViz";
import AttendanceDonutChartViz from "./components/attendance/AttendanceDonutChartViz";
import AttendanceCalendar from "./components/attendance/AttendanceCalendarViz";

export default function Default() {
  
  return (
    <Flex
      direction={{ base: "column", xl: "row" }}
      pt={{ base: "50px", md: "80px", xl: "80px" }}
      width="100%" // Ensuring the container takes the full width
      mx="auto" // Centering the container horizontally
    >
      <Grid
        templateColumns={{ base: "1fr", xl: "7fr 3fr" }}
        gap="20px"
        width="100%"
      >
        <Flex direction="column" width="100%" gap="20px">
          <Grid
            templateColumns={{ base: "1fr", xl: "repeat(3, 1fr)" }}
            gap="20px"
            width="100%"
          >
            <ThisYearAttendees />
            <ThisMonthAttendees />
            <ThisWeekAttendees />
          </Grid>
          <Grid width="100%" height="100%">
            <AttendanceCalendar height="100%" width="100%" />
          </Grid>
        </Flex>

        <Flex direction="column" width="100%" gap="20px">
          <Grid width="100%">
            <AverageDays />
          </Grid>
          <Grid
            templateColumns={{ base: "1fr", xl: "1fr" }}
            gap="20px"
            width="100%"
          >
            <AttendanceDonutChartViz />
            <AttendanceColumnChartViz />
          </Grid>
        </Flex>
      </Grid>
    </Flex>
  );
}
