import { Box, List, ListItem, Text, useColorModeValue } from "@chakra-ui/react";

const SummarySection = ({ title, data, bgGradient }) => {
  const textColor = useColorModeValue("navy.700", "white");
  const backgroundColor = useColorModeValue('white', '#1F2A3F');

  return (
    <Box
      px={10}
      py={3}
      bg="gray.800"
      borderRadius="md"
      mb={4}
      whiteSpace="pre-wrap"
      border="1px solid"
      borderColor="#3D65FD"
      bgColor={backgroundColor}
      bgGradient={bgGradient}
    >
      <Text color={textColor} mt={2} mb={3} fontSize="20px" fontWeight="bold">
        {title}
      </Text>
      {data ? (
        <List color={textColor} mt={2} mb={2} spacing={2}>
          {data.split('\n').map((sentence, index) => (
            <ListItem key={index} display="flex" flexDirection="row" gap={3}>
              <Box as="span" fontSize="lg" color={textColor}>•</Box>
              {sentence}
            </ListItem>
          ))}
        </List>
      ) : <i>No data available</i>}
    </Box>
  );
};

export default SummarySection;
