// Brand.js
// Chakra Imports
import { Flex } from '@chakra-ui/react';
// Icons
import { XeusCloudLogo } from 'components/icons/Icons';
// Components
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand(props) {
  const { mini, hovered } = props;

  return (
    <Flex alignItems="center" flexDirection="column">
      <XeusCloudLogo
        display={
          mini === false
            ? 'block'
            : mini === true && hovered === true
            ? 'block'
            : 'none'
        }
      />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
