import React, { useState } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Select,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";

import { SearchBar } from "./components/Search";

import { summaryCards } from "variables/summaryCards";
import LocationCard from "./components/LocationCard";
import LocationDetails from "./components/LocationDetails";

export default function Default() {
  const [nameOrder, setNameOrder] = useState("a_to_z");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const company = sessionStorage.getItem('company') || localStorage.getItem('company');
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const renderSummary = () => {
    const allSummaries = Object.values(summaryCards[company]).flat();

    const groupedByLocation = allSummaries.reduce((acc, summary) => {
      const location = summary.locationFilter.toLowerCase();
      
      if (!acc[location]) {
        acc[location] = [];
      }
      
      acc[location].push(summary);
      
      return acc;
    }, {});

    const filteredAndSortedLocations = Object.keys(groupedByLocation)
      .filter((key) => key.toLowerCase().includes(searchQuery.toLowerCase()))
      .sort((a, b) => {
        if (nameOrder === "a_to_z") {
          return a.localeCompare(b);
        } else {
          return b.localeCompare(a);
        }
    });

    if (selectedLocation) {
      const groupedByTabKey = groupedByLocation[selectedLocation.toLowerCase()].reduce((acc, currentItem) => {
        const { tabKey } = currentItem;
      
        if (!acc[tabKey]) {
          acc[tabKey] = [];
        }
      
        acc[tabKey].push(currentItem);
      
        return acc;
      }, {});
      return (
        <LocationDetails
          data={groupedByTabKey}
          setSelectedLocation={setSelectedLocation}
        />
      )
    }

    return (
      <>
        <Flex w='100%' mb='25px'>
          <SearchBar searchQuery={searchQuery} onSearchChange={setSearchQuery} />
          <Select
            fontSize='sm'
            variant='main'
            h='44px'
            maxh='44px'
            me='20px'
            value={nameOrder}
            onChange={(e) => setNameOrder(e.target.value)}
          >
            <option value='a_to_z'>A to Z</option>
            <option value='z_to_a'>Z to A</option>
          </Select>
        </Flex>
        <Text mt='25px' mb='25px' color={textColor} fontSize='2xl' fontWeight='700'>
          {Object.keys(groupedByLocation).length} Results
        </Text>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' ms='20px'>
          {
            filteredAndSortedLocations.map((key, index) => {
              const currentLocationData = groupedByLocation[key];
              
              return (
                <LocationCard
                  key={index}
                  locationFilter={currentLocationData[0].locationFilter}
                  setSelectedLocation={setSelectedLocation}
                />
              );
            })
          }
        </SimpleGrid>
      </>
    );
  };

  return (
    <Box pt={{ base: "100px", md: "80px", xl: "80px" }}>
      {renderSummary()}
    </Box>
  );
}