import {
  Text, Box
} from '@chakra-ui/react';
import LocalDateFilter from "../LocalDateFilter";
import LocalLocationFilter from "../LocalLocationFilter";

const Filters = ({ dateFilterCallback, locationFilterCallback, fontSize }) => {
  return (
    <>
      <Text fontSize="l" mb={4}>Filters</Text>
      <Box mb={4}>
        <LocalDateFilter callback={dateFilterCallback} fontSize={fontSize} />
      </Box>
      <Box mb={4}>
        <LocalLocationFilter callback={locationFilterCallback} fontSize={fontSize} />
      </Box>
    </>
  );
}

export default Filters;
