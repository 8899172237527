// Import necessary dependencies
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  VStack,
  Button,
  Text,
  Grid,
  Divider,
  useColorModeValue,
  FormControl,
  FormLabel,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  Flex
} from '@chakra-ui/react';
import { useFilteredData } from 'utils/dataHooks';
// Import contexts
import { useLocation } from 'contexts/LocationContext';
import { useDateRange } from 'contexts/DateRangeContext';
import { DateFormatProvider } from "contexts/DateFormatContext";
import { FaHashtag, FaMoneyBillWave, FaCalendarAlt, FaFont, FaChevronDown } from 'react-icons/fa';
import fieldTypes from '../../../variables/fieldTypes';
import LineChart from './components/LightningLineChart';
import BarChart from './components/LightningBarChart';
import LightningTable from './components/LightningTable';
import VisualTypeButtons from './components/VisualTypeButtons';
import SelectDataset from './components/DataConfiguration/SelectDataset';
import Filters from './components/DataConfiguration/Filters';

const viewPathMap = {
  'meta': 'meta',
  'google': 'google',
  'ticketing': 'ticketing',
};

const FilterPage = () => {
  const [selectedDataset, setSelectedDataset] = useState(''); // Default dataset
  const [fields, setFields] = useState([]);
  const [selectedTableFields, setSelectedTableFields] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [visualType, setVisualType] = useState('');
  const [XAxisField, setXAxisField] = useState('');
  const [YAxisField, setYAxisField] = useState('');

  const { localLocation } = useLocation(); // Get location from context
  const { localDateRange } = useDateRange(); // Get date range from context

  const withPastPeriodData = false;
  const { filteredData: metaData } = useFilteredData('meta', localLocation, localDateRange, withPastPeriodData); // Use the selected dataset
  const { filteredData: googleData } = useFilteredData('google', localLocation, localDateRange, withPastPeriodData); // Use the selected dataset
  const { filteredData: ticketingData } = useFilteredData('ticketing', localLocation, localDateRange, withPastPeriodData); // Use the selected dataset
  const dataMap = {
    'meta': metaData,
    'google': googleData,
    'ticketing': ticketingData,
    '': []
  };
  const filteredData = dataMap[selectedDataset];
  
  const rawAllData = [...metaData, ...googleData, ...ticketingData];
  const groupedData = {};
  const mergeData = (existingData, newData) => {
    return { ...existingData, ...newData };
  };
  rawAllData.forEach((data) => {
    const date = data.date;
    if (groupedData[date]) {
      groupedData[date] = mergeData(groupedData[date], data);
    } else {
      groupedData[date] = data;
    }
  });
  const allData = Object.values(groupedData);

  const excludedFieldsTable = useMemo(() => ['period','client', 'month', 'day', 'date', 'day_name', 'month_name'], []);
  const excludedFieldsChart = useMemo(() => ['period','client', 'location'], []);

  useEffect(() => {
    if (filteredData.length > 0) {
      const uniqueFields = Object.keys(filteredData[0]);
      setFields(uniqueFields);
    }
  }, [filteredData]);

  useEffect(() => {
    handleFilterData();
  }, [XAxisField, YAxisField, selectedTableFields]);

  // Handle dataset selection
  const handleDatasetSelection = (dataset) => {
    setSelectedDataset(dataset);
    setXAxisField('');
    setYAxisField('');
    if (visualType !== 'table') {
      setDisplayData([]);
    }
  };

  const handleTableFieldSelection = (field) => {
    setSelectedTableFields((prevSelected) => {
      const updatedFields = prevSelected.includes(field)
        ? prevSelected.filter((f) => f !== field)
        : [...prevSelected, field];
    
      if (!updatedFields.includes("date")) {
        updatedFields.push("date");
      }
    
      return updatedFields;
    });
  };

  // Filter data based on selected fields
  const handleFilterData = () => {
    if (visualType !== 'table' && XAxisField && YAxisField) {
      const filtered = filteredData.map((item) => {
        const obj = {};
        if (item[XAxisField] !== undefined) {
          obj[XAxisField] = item[XAxisField];
        }
        if (item[YAxisField] !== undefined) {
          obj[YAxisField] = item[YAxisField];
        }
        return obj;
      });
      setDisplayData(filtered);
    }

    else if (visualType === 'table' && selectedTableFields.length > 0) {
      const filtered = allData.map((item) =>
        selectedTableFields.reduce((obj, key) => {
          if (item[key] !== undefined) {
            obj[key] = item[key];
          }
          return obj;
        }, {})
      );
      setDisplayData(filtered);
    }
  };

  // Sidebar styling
  const sidebarBg = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const sidebarRadius = '20px';

  const getDisplayDataButtonIsDisabled = () => {
    if (!visualType || !selectedDataset) {
      return true;
    }
  
    if (visualType !== 'table' && (!XAxisField || !YAxisField)) {
      return true;
    }
  
    if (visualType === 'table' && selectedTableFields.length > 0) {
      return false;
    }
  
    return false;
  };

  const fieldIcons = {
    'string': <FaFont />,
    'number': <FaHashtag />,
    'money': <FaMoneyBillWave />,
    'date': <FaCalendarAlt />,
  };

  const handleVisualTypeChange = (newVisualType) => {
    if (visualType === 'table' || newVisualType === 'table') {
      setSelectedTableFields([]);
      setXAxisField('');
      setYAxisField('');
      setDisplayData([]);
    }
    setVisualType(newVisualType);
  };

  return (
    <Grid
      display={{ base: "block", md: "grid" }}
      gridTemplateColumns={{ base: "1fr 3fr", md: "1fr 3fr" }}
      gap={{ base: "20px", xl: "20px" }}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      h="calc(100vh - 80px)"
    >
      {/* Sidebar for selection and filters */}
      <Box
        bg={sidebarBg}
        borderRadius={sidebarRadius}
        boxShadow={shadow}
        overflowX="hidden"
      >
        <Box p={8}>
          <Text fontSize="l" mb={4}>Select Visual Type</Text>
          <VisualTypeButtons visualType={visualType} setVisualType={handleVisualTypeChange} />
          <SelectDataset
            viewPathMap={viewPathMap}
            selectedDataset={selectedDataset}
            handleDatasetSelection={handleDatasetSelection}
          />

          <Divider my={4} />

          {/* Middle Section: Field Selection */}
          <Flex flexDirection="row" justifyContent="space-between" alignItems="baseline" mb={4}>
            <Text fontSize="l">Select Fields</Text>
            {visualType === 'table' && <Text fontSize="xs" style={{ fontStyle: 'italic' }} cursor="pointer" color='#00E5D1' onClick={() => setSelectedTableFields([])}>Reset</Text>}
          </Flex>
          {visualType === '' && <Text fontSize='sm' style={{ fontStyle: 'italic' }} color='gray'>Select a visual type</Text>}
          {selectedDataset === '' && <Text fontSize='sm' style={{ fontStyle: 'italic' }} color='gray'>Select a dataset</Text>}
          {visualType === 'table' && (
            <VStack align="start" mb={4}>
              {fields.filter((field) => !excludedFieldsTable.includes(field)).map((field) => (
                <Checkbox
                  key={field}
                  isChecked={selectedTableFields.includes(field)}
                  onChange={() => handleTableFieldSelection(field)}
                  sx={{
                    '& .chakra-checkbox__control': {
                      borderColor: 'gray.400',
                      _checked: {
                        bg: '#00E5D1',
                        borderColor: '#00E5D1',
                        color: 'black',
                      },
                    },
                    '&:hover .chakra-checkbox__control': {
                      borderColor: '#00E5D1',
                    },
                  }}
                >
                  <Text fontSize="sm">
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {fieldIcons[fieldTypes[field]] && (
                        <span style={{ marginRight: '8px' }}>{fieldIcons[fieldTypes[field]]}</span>
                      )}
                      {field}
                    </span>
                  </Text>
                </Checkbox>
              ))}
            </VStack>
          )}
          {(visualType === 'line' || visualType === 'bar') && selectedDataset !== '' && (
            <Box>
              <FormControl mb={4}>
                <FormLabel>X Axis</FormLabel>
                <Menu>
                <MenuButton
                  as={Button}
                  variant="outline"
                  width="100%"
                  fontSize="xs"
                  rightIcon={<Icon as={FaChevronDown} />}
                >
                  {XAxisField ? (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {fieldIcons[fieldTypes[XAxisField]] && (
                        <span style={{ marginRight: '8px' }}>{fieldIcons[fieldTypes[XAxisField]]}</span>
                      )}
                      {XAxisField}
                    </span>
                  ) : (
                    "Select X Axis"
                  )}
                </MenuButton>
                  <MenuList>
                  {fields.filter((field) => !excludedFieldsChart.includes(field)).map((field) => (
                    <MenuItem 
                      key={field} 
                      onClick={() => setXAxisField(field)} 
                      fontSize="sm" 
                      height="30px" 
                      bg={field === XAxisField ? '#5158FF' : 'transparent'}
                      isDisabled={fieldTypes[field] === 'number' || fieldTypes[field] === 'money'}
                    >
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {fieldIcons[fieldTypes[field]] && (
                          <span style={{ marginRight: '8px' }}>{fieldIcons[fieldTypes[field]]}</span>
                        )}
                        {field}
                      </span>
                    </MenuItem>
                  ))}
                  </MenuList>
                </Menu>
              </FormControl>

              <FormControl mb={4}>
                <FormLabel>Y Axis</FormLabel>
                <Menu>
                <MenuButton
                  as={Button}
                  variant="outline"
                  width="100%"
                  fontSize="xs"
                  rightIcon={<Icon as={FaChevronDown} />}
                >
                  {YAxisField ? (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {fieldIcons[fieldTypes[YAxisField]] && (
                        <span style={{ marginRight: '8px' }}>{fieldIcons[fieldTypes[YAxisField]]}</span>
                      )}
                      {YAxisField}
                    </span>
                  ) : (
                    "Select Y Axis"
                  )}
                </MenuButton>
                <MenuList>
                {fields.filter((field) => !excludedFieldsChart.includes(field)).map((field) => (
                  <MenuItem 
                    key={field} 
                    onClick={() => setYAxisField(field)} 
                    fontSize="sm" 
                    height="30px" 
                    isDisabled={fieldTypes[field] !== 'number' && fieldTypes[field] !== 'money'}
                    bg={field === YAxisField ? '#5158FF' : 'transparent'}
                  >
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {fieldIcons[fieldTypes[field]] && (
                        <span style={{ marginRight: '8px' }}>{fieldIcons[fieldTypes[field]]}</span>
                      )}
                      {field}
                    </span>
                  </MenuItem>
                ))}
                </MenuList>
                </Menu>
              </FormControl>
            </Box>
          )}

          <Divider my={4} />

          {/* Bottom Section: Date and Location Filters */}
          <Filters dateFilterCallback={() => setDisplayData([])} locationFilterCallback={() => setDisplayData([])} />

          <Divider my={4} />

          <Box mb={4}>
            <Button
              backgroundColor='#5158FF'
              color='white'
              _hover={{ backgroundColor: "#3d44cc" }}
              onClick={handleFilterData}
              isDisabled={getDisplayDataButtonIsDisabled()}
              w="full"
              fontSize="sm"
            >
              Display Data
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Main content to display filtered data */}
      <Box flex="1" overflow='scroll' pb={5}>
        {displayData.length > 0 ? (
          visualType === "table" ? (
            <DateFormatProvider>
              <LightningTable selectedFields={selectedTableFields} displayData={displayData} dataset={selectedDataset} />
            </DateFormatProvider>
          ) : visualType === "line" && XAxisField && YAxisField ? (
            <LineChart
              XAxisField={XAxisField}
              YAxisField={YAxisField}
              displayData={displayData}
            />
          ) : (
            <BarChart
              XAxisField={XAxisField}
              YAxisField={YAxisField}
              displayData={displayData}
            />
          )
        ) : (
          <Text>No data to display. Click "Display Data".</Text>
        )}
      </Box>
    </Grid>
  );
};

export default FilterPage;