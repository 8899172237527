import {
  ButtonGroup,
  IconButton,
  Text,
  useColorModeValue,
  Tooltip
} from '@chakra-ui/react';
import { FaMeta, FaTicket } from "react-icons/fa6";
import { SiGoogleads } from "react-icons/si";
import React from 'react';
import { capitalizeWords } from 'utils/dataFormatter';

const iconMapping = {
  ticketing: FaTicket,
  meta: FaMeta,
  google: SiGoogleads,
};

const SelectDataset = ({ viewPathMap, selectedDataset, handleDatasetSelection, fontSize = "sm", multiple = false }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <>
      <Text fontSize="l" mb={4}>Data</Text>
      <ButtonGroup isAttached mb={4} width="100%">
        {Object.keys(viewPathMap).map((dataset) => (
          <Tooltip
            key={dataset}
            label={capitalizeWords(dataset.replace(/_/g, ' '))}
            aria-label={capitalizeWords(dataset.replace(/_/g, ' '))}
          >
            <IconButton
              aria-label={dataset}
              icon={React.createElement(iconMapping[dataset] || FaTicket)}
              backgroundColor={
                (multiple ? selectedDataset.includes(dataset) : selectedDataset === dataset)
                  ? '#5158FF'
                  : 'white.300'
              }
              color={
                (multiple ? selectedDataset.includes(dataset) : selectedDataset === dataset)
                  ? 'white'
                  : textColor
              }
              _hover={{
                backgroundColor:
                  (multiple ? selectedDataset.includes(dataset) : selectedDataset === dataset)
                    ? '#3d44cc'
                    : 'rgba(255, 255, 255, 0.1)',
              }}
              onClick={() => handleDatasetSelection(dataset)}
              flex="1"
              fontSize={fontSize}
            />
          </Tooltip>
        ))}
      </ButtonGroup>
    </>
  );
};

export default SelectDataset;
